import { Fragment, useEffect, useState } from "react";
import { useUser } from "../../hooks/UserContext";
import useScrollTop from "../../hooks/useScrollTop";
import useTitle from "../../hooks/useTitle";
import { GetAnagraficaAtleti, GetAnagraficaProgramma, GetGare } from "../../rest/api";
import Caricamento from "../common/Caricamento";
import Modal from 'react-bootstrap/Modal';
import { useNavigate, useParams } from "react-router";
import TabellaGestioneProgrammi from "./TabellaGestioneProgrammi";
import TabellaGestioneTempi from "./TabellaGestioneTempi";
import FormProgramma from "./FormProgramma";
import FormTempo from "./FormTempo";


export default function GestioneProgrammiPage() {
    const [atleti, setAtleti] = useState();
    const [programma, setProgramma] = useState();
    const [gare, setGare] = useState();
    const [idprogSelezionato, setIdprogSelezionato] = useState(-1);
    const [idtempoSelezionato, setIdtempoSelezionato] = useState(-1);
    const [counterModifiche, setCounterModifiche] = useState(0);
    const [showProgramma, setShowProgramma] = useState(false);
    const [showTempo, setShowTempo] = useState(false);
    const params = useParams();
    const navigate = useNavigate();

    useTitle('Gestione Programma')
    useScrollTop();
    const [user] = useUser();

    useEffect(() => {
        let doUpdate = true;
        GetAnagraficaProgramma(params.idevento)
            .then(result => {
                if (doUpdate) {
                    setProgramma(result.data);
                    console.log(result.data);
                }
            })
        return () => doUpdate = false;
    }, [counterModifiche])

    useEffect(() => {
        let doUpdate = true;
        GetAnagraficaAtleti()
            .then(result => {
                if (doUpdate)
                    setAtleti(result.data);
            })
        return () => doUpdate = false;
    }, [])

    useEffect(() => {
        let doUpdate = true;
        GetGare()
            .then(result => {
                if (doUpdate)
                    setGare(result.data);
            })
        return () => doUpdate = false;
    }, [])

    const selectProgramma = a => {
        console.log(a)
        console.log('seleziono programma ' + a);
        setIdprogSelezionato(a);
        setIdtempoSelezionato(-1);
        setShowProgramma(true);
    };

    const selectTempo = a => {
        console.log(a)
        console.log('seleziono tempo ' + a);
        setIdprogSelezionato(-1);
        setIdtempoSelezionato(a);
        setShowTempo(true);
    };

    const aggiornaProgramma = (idprogramma, azione) => {
        if (azione === 'new') {
            setIdprogSelezionato(idprogramma);
        }
        else if (azione === 'delete') {
            setShowProgramma(false);
            setIdprogSelezionato(-1);
        }
        setCounterModifiche(val => val + 1);
        setIdprogSelezionato(-1);
        setShowProgramma(false);
        console.log(idprogramma)
        console.log(azione.toUpperCase());
    };

    const aggiornaTempo = (idtempo, azione) => {
        //if (azione === 'new') {
        //    setIdtempoSelezionato(idtempo);
        //}
        //else if (azione === 'delete') {
            setShowTempo(false);
            setIdtempoSelezionato(-1);
        //}
        setCounterModifiche(val => val + 1);
        setIdprogSelezionato(-1);
        setShowProgramma(false);
        console.log(idtempo)
        console.log(azione.toUpperCase());
    };

    console.log('cerco programma con id ' + idprogSelezionato);
    const programmaSelezionato = programma?.find(x => x.idprogramma === idprogSelezionato);

    console.log('cerco tempo con id ' + idtempoSelezionato);
    const tempoSelezionatoConProg = programma && programma.find(x => x.iscrizioni.some(y => y.idtempo === idtempoSelezionato));
    let tempo;
    if (tempoSelezionatoConProg !== undefined) {
        tempo = tempoSelezionatoConProg.iscrizioni.find(x => x.idtempo === idtempoSelezionato);
        console.log(tempo);
        tempo['individuale'] = tempoSelezionatoConProg.individuale;
        tempo['idgara'] = tempoSelezionatoConProg.idgara;
        tempo['idprogramma'] = tempoSelezionatoConProg.idprogramma;
    }

    console.log(programma)
    const iscrizioni_totali = programma?.reduce((partialSum, a) => partialSum + a.iscrizioni.length, 0) ?? 0;
    return (
        <Fragment>
            {!programma && <Caricamento messaggio="Caricamento programma in corso" />}
            {programma && atleti && gare && (
                <Fragment>
                    <h2 className="text-center mb-4">Programma gare</h2>
                    <button className="btn mb-3 d-block btn-lg btn-dark mx-auto" onClick={() => {
                        setIdprogSelezionato(-1);
                        setShowProgramma(true);
                    }}>Nuovo programma</button>
                    {programma.length > 0 && (
                        <TabellaGestioneProgrammi dati={programma} onSelectProgramma={selectProgramma} onUpdateProgramma={aggiornaProgramma} />
                    )}
                    {programma.length === 0 && (
                        <div className="text-center my-5">
                            <h3>Nessun programma da mostrare</h3>
                        </div>
                    )}
                    <Modal
                        show={showProgramma}
                        onHide={() => setShowProgramma(false)}
                        scrollable
                        backdrop="static"
                        keyboard={false}
                        size="lg"
                    >
                        <Modal.Header closeButton closeVariant='white' style={{ backgroundColor: 'rgba(0,0,0,0.85)', color: 'white' }}>
                            <Modal.Title className="w-100 text-center">
                                {idprogSelezionato > 0 ? 'Modifica Programma ' + idprogSelezionato : `Creazione Programma`}
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <FormProgramma
                                dati={programmaSelezionato}
                                maxdata={programma?.length > 0 ? programma[programma.length - 1].data : null}
                                gare={gare}
                                onUpdateProgramma={aggiornaProgramma}
                            />
                        </Modal.Body>
                    </Modal>

                    {programma.length > 0 && (
                        <Fragment>
                            <h2 className="text-center mb-4 mt-5">Iscrizioni</h2>
                            <button className="btn mb-3 d-block mx-auto btn-lg btn-dark" onClick={() => {
                                setIdtempoSelezionato(-1);
                                setShowTempo(true);
                            }}>Nuovo tempo</button>
                            {iscrizioni_totali === 0 && (
                                <div className="text-center my-5">
                                    <h3>Nessun tempo da mostrare</h3>
                                </div>
                            )}
                            {iscrizioni_totali > 0 && <TabellaGestioneTempi dati={programma} onSelectTempo={selectTempo} />}
                            <Modal
                                show={showTempo}
                                onHide={() => setShowTempo(false)}
                                scrollable
                                backdrop="static"
                                keyboard={false}
                                size="lg"
                            >
                                <Modal.Header closeButton closeVariant='white' style={{ backgroundColor: 'rgba(0,0,0,0.85)', color: 'white' }}>
                                    <Modal.Title className="w-100 text-center">
                                        {idtempoSelezionato > 0 ? 'Modifica Tempo ' + idtempoSelezionato : `Creazione Tempo`}
                                    </Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <FormTempo
                                        dati={tempo}
                                        programmi={programma}
                                        atleti={atleti}
                                        onUpdateTempo={aggiornaTempo}
                                    />
                                </Modal.Body>
                            </Modal>
                        </Fragment>
                    )}

                </Fragment>
            )
            }
        </Fragment>
    );
}
