import { Routes, Route, Navigate } from 'react-router-dom';
import Layout from './components/common/Layout';
import HomePage from './components/aquareal/HomePage';
import 'bootstrap/dist/css/bootstrap.min.css';
import './css/App.css';
import LoginPage from './components/login/LoginPage';
import SquadraMasterPage from './components/master_squadra/SquadraMasterPage';
import EventiMasterPage from './components/master_eventi/EventiMasterPage';
import AtletaMasterPage from './components/master_atleta/AtletaMasterPage';
import RecordAtletaMaster from './components/master_atleta/RecordAtletaMaster';
import TempiAtletaMaster from './components/master_atleta/TempiAtletaMaster';
import EventoMasterPage from './components/master_evento/EventoMasterPage';
import RisultatiEventoMaster from './components/master_evento/RisultatiEventoMaster';
import RecordSocietariMaster from './components/master_squadra/RecordSocietariMaster';
import ProgressioneAtletaMaster from './components/master_atleta/ProgressioneAtletaMaster';
import PuntiMaxAtletaMaster from './components/master_atleta/PuntiMaxAtletaMaster';
import CollaboratoriPage from './components/aquareal/CollaboratoriPage';
import LavoraConNoiPage from './components/aquareal/LavoraConNoiPage';
import ContattiPage from './components/aquareal/ContattiPage';
import AdminPage from './components/master_admin/AdminPage';
import GestioneAtletiPage from './components/master_admin/GestioneAtletiPage';
import GestioneEventiPage from './components/master_admin/GestioneEventiPage';
import GestioneProgrammiPage from './components/master_admin/GestioneProgrammiPage';
import GestionePiscinePage from './components/master_admin/GestionePiscinePage';
import ArticoloPage from './components/articolo/ArticoloPage';
import ArticoliPage from './components/articoli/ArticoliPage';
import CalcolatricePage from './components/aquareal/CalcolatricePage';
import TrofeiPage from './components/aquareal/TrofeiPage';
import GestioneTrofeiPage from './components/master_admin/GestioneTrofeiPage';
import GestioneArticoliPage from './components/master_admin/GestioneArticoliPage';
import FormArticolo from './components/master_admin/FormArticolo';
import RicercaPage from './components/ricerca/RicercaPage';
import Medagliere from './components/master_atleta/Medagliere';
import Programma from './components/master_evento/Programma';
import NotFoundPage from './components/aquareal/NotFoundPage';
import Piscina from './components/master_evento/Piscina';
import Edizioni from './components/master_evento/Edizioni';
import Iscrizioni from './components/master_evento/Iscrizioni2';

function Test() {
  return <div>Io sono test</div>
}

export default function RoutesSito() {
  return (
    <Routes>
      <Route path="/" element={<Layout />}>
        <Route path="" element={<HomePage />} />
        <Route path="collaboratori" element={<CollaboratoriPage />} />
        <Route path="lavoro" element={<LavoraConNoiPage />} />
        <Route path="contatti" element={<ContattiPage />} />
        <Route path="test" element={<Test />} />
        <Route path="articoli" element={<ArticoliPage />} />
        <Route path="cerca" element={<RicercaPage />} />
        <Route path="articoli/:idarticolo" element={<ArticoloPage />} />
        <Route path="master/login" element={<LoginPage />} />
        <Route path="master/squadra" element={<SquadraMasterPage />} />
        <Route path="master/recordsocietari" element={<RecordSocietariMaster />} />
        <Route path="master/eventi" element={<EventiMasterPage />} />
        <Route path="master/calcolatrice" element={<CalcolatricePage />} />
        <Route path="master/trofei" element={<TrofeiPage />} />
        <Route path="master/gestione" element={<AdminPage />}>
          <Route path="" element={<Navigate replace to="atleti" />} />
          <Route path="atleti" element={<GestioneAtletiPage />} />
          <Route path="eventi" element={<GestioneEventiPage />} />
          <Route path="programmi/:idevento" element={<GestioneProgrammiPage />} />
          <Route path="piscine" element={<GestionePiscinePage />} />
          <Route path="trofei" element={<GestioneTrofeiPage />} />
          <Route path="articoli" element={<GestioneArticoliPage />} />
          <Route path="articoli/:idarticolo" element={<FormArticolo />} />
        </Route>
        <Route path="master/eventi/:idevento" element={<EventoMasterPage />}>
          <Route path="" element={<Navigate replace to="programma" />} />
          <Route path="programma" element={<Programma />} />
          <Route path="iscrizioni" element={<Iscrizioni />} />
          <Route path="risultati" element={<RisultatiEventoMaster />} />
          <Route path="piscina" element={<Piscina />} />
          <Route path="edizioni" element={<Edizioni />} />
        </Route>
        <Route path="master/atleti/:idatleta" element={<AtletaMasterPage />}>
          <Route path="" element={<Navigate replace to="record" />} />
          <Route path="record" element={<RecordAtletaMaster />} />
          <Route path="puntimax" element={<PuntiMaxAtletaMaster />} />
          <Route path="tempi" element={<TempiAtletaMaster />} />
          <Route path="progressione" element={<ProgressioneAtletaMaster />} />
          <Route path="medagliere" element={<Medagliere />} />
        </Route>
        <Route path="*" element={<NotFoundPage />} />
      </Route>
    </Routes>
  );
}
