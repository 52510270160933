import { colorGara, printData, printDataTime } from "../../utility";
import { BsFillSquareFill, BsFillCaretDownFill, BsFillCaretUpFill } from 'react-icons/bs';
import { SpostaProgramma } from "../../rest/api";
import IconaSessione from "../common/IconaSessione";

export default function TabellaGestioneProgrammi({ dati, onSelectProgramma, onUpdateProgramma }) {

    const sposta_programma = (idprogramma, direzione) => {
        const info = {
            idprogramma: idprogramma,
            spostamento: direzione
        }
        SpostaProgramma(info)
            .then(resp => {
                console.log('programma spostato')
                onUpdateProgramma(idprogramma, 'edit');
            })
    }

    let lastData = dati[0].data;

    return (
        <table className="w-100 shadow" style={{ fontSize: '1.1rem', lineHeight: '1.2' }}>
            <thead>
                <tr className="fw-bold">
                    <th className="py-1 text-end" style={{ width: '4rem' }}>ID</th>
                    <th className="text-center py-1 d-none d-sm-table-cell" style={{ width: '9rem' }}>DATA</th>
                    <th className="pe-2 py-1 text-end" style={{ width: '15rem' }}>GARA</th>
                    <th className="text-center py-1 d-none d-xl-table-cell" style={{ width: '5rem' }}>TEMPI</th>
                    <th className="text-center py-1 d-none d-lg-table-cell" style={{ width: '10rem' }}>CREAZIONE</th>
                    <th className="text-center py-1 d-none d-md-table-cell" style={{ width: '10rem' }}>MODIFICA</th>
                    <th className="text-end py-1 pe-2 d-none d-md-table-cell">NOTE</th>
                    <th className="py-1 ps-2" style={{ width: '2.2rem' }}></th>
                    <th className="py-1" style={{ width: '1.8rem' }}></th>
                    <th className="py-1" style={{ width: '1.8rem' }}></th>
                </tr>
            </thead>
            <tbody>
                {
                    dati.map(x => {
                        const cambioData = x.data !== lastData;
                        lastData = x.data;
                        return (
                            <tr key={x.idprogramma} style={{borderTop: cambioData ? '1px solid':''}}>
                                <td className="py-1 text-end">
                                    {x.idprogramma}
                                </td>
                                <td className="text-center py-1">
                                    {/* Compensa il top -1px dell'icona */}
                                    <div className="position-relative" style={{ top: '1px' }}>
                                        {printData(x.data)}
                                        <span className="d-none d-lg-inline">
                                            <IconaSessione sessione={x.sessione} />
                                        </span>
                                    </div>
                                </td>
                                <td className="text-end py-1 pe-2 d-none d-sm-table-cell">
                                    {x.gara} <span className="d-none d-lg-inline"><BsFillSquareFill style={{ color: colorGara(x.gara) }} /></span>
                                </td>
                                <td className="text-center py-1 d-none d-xl-table-cell">
                                    {x.iscrizioni.length}
                                </td>
                                <td className="text-center py-1 d-none d-lg-table-cell">
                                    {printDataTime(x.creazione_programma, 2)}
                                </td>
                                <td className="text-center py-1 d-none d-md-table-cell">
                                    {printDataTime(x.modifica_programma, 2)}
                                </td>
                                <td className="text-end py-1 pe-2 d-none d-md-table-cell">
                                    {x.note_programma ?? '-'}
                                </td>
                                <td className="text-center py-1 ps-2">
                                    {x.ordine > 1 && (
                                        <button
                                            onClick={() => sposta_programma(x.idprogramma, 'su')}
                                            className="btn btn-success px-1 py-0 w-100">
                                            <BsFillCaretUpFill />
                                        </button>
                                    )}
                                </td>
                                <td className="text-center py-1">

                                    {x.ordine < dati.length && (
                                        <button
                                            onClick={() => sposta_programma(x.idprogramma, 'giu')}
                                            className="btn btn-success px-1 py-0 w-100">
                                            <BsFillCaretDownFill />
                                        </button>
                                    )}
                                </td>
                                <td className="text-center py-1 px-1">
                                    <button
                                        onClick={() => onSelectProgramma(x.idprogramma)}
                                        className="btn btn-primary px-2 py-0 w-100">
                                        ...
                                    </button>
                                </td>
                            </tr>
                        )
                    })
                }
            </tbody>
        </table>
    )

}
