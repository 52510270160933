import 'bootstrap/dist/css/bootstrap.min.css';
import './css/App.css';
import RoutesSito from './RoutesSito.js';
import { UserProvider } from './hooks/UserContext';

function App() {
  return (
    <UserProvider>
      <RoutesSito />
    </UserProvider>
  );
}

export default App;
