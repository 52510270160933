import { Fragment, useState } from "react";
import { AddTempoMaster, DeleteTempoMaster, EditTempoMaster } from "../../rest/api";
import { printData, printTempo } from "../../utility";


export default function FormTempo({ dati, atleti, programmi, onUpdateTempo }) {

    console.log(dati);
    if (!dati || dati.idtempo < 0) {
        dati = {
            idtempo: -1,
            idprogramma: -1,
            idatleta1: -1,
            idatleta2: -1,
            idatleta3: -1,
            idatleta4: -1,
            tempo: 0,
            frazione1: 0,
            frazione2: 0,
            frazione3: 0,
            frazione4: 0,
            categoria: '',
            posizione: 0,
            punti: 0,
            note: ''
        }
    }
    console.log(dati)
    console.log('================================');
    console.log(Math.floor(dati.tempo / 60));
    console.log(Math.trunc(dati.tempo - 60 * Math.floor(dati.tempo / 60)));
    console.log(Math.round((dati.tempo - Math.trunc(dati.tempo)) * 100));

    const [idprogramma, setIdprogramma] = useState(dati.idprogramma);
    const [idtempo, setIdtempo] = useState(dati.idtempo);
    const [idatleta1, setIdatleta1] = useState(dati.idatleta1);
    const [idatleta2, setIdatleta2] = useState(dati.idatleta2);
    const [idatleta3, setIdatleta3] = useState(dati.idatleta3);
    const [idatleta4, setIdatleta4] = useState(dati.idatleta4);
    const [minuti, setMinuti] = useState(Math.floor(dati.tempo / 60));
    const [secondi, setSecondi] = useState(Math.trunc(dati.tempo - 60 * Math.floor(dati.tempo / 60)));
    const [centesimi, setCentesimi] = useState(Math.round((dati.tempo - Math.trunc(dati.tempo)) * 100));
    const [frazione1, setFrazione1] = useState(dati.frazione1);
    const [frazione2, setFrazione2] = useState(dati.frazione2);
    const [frazione3, setFrazione3] = useState(dati.frazione3);
    const [frazione4, setFrazione4] = useState(dati.frazione4);
    const [categoria, setCategoria] = useState(dati.categoria);
    const [posizione, setPosizione] = useState(dati.posizione);
    const [punti, setPunti] = useState(dati.punti ?? 0);
    const [note, setNote] = useState(dati.note ?? '');

    const [isLoading, setIsLoading] = useState(false);
    const [messaggio, setMessaggio] = useState();
    const [errore, setErrore] = useState();

    const individuale = programmi.find(x => x.idprogramma === idprogramma)?.individuale ?? true;
    const tempo = Math.round((60 * minuti + secondi + centesimi / 100) * 100) / 100;

    const editTempo = e => {
        e.preventDefault();

        if (individuale && idatleta1 < 0) {
            setErrore('Nessuna atleta selezionato');
            return;
        }
        if (idprogramma < 0) {
            setErrore('Nessun programma selezionato');
            return;
        }
        if (!individuale) {
            if (idatleta1 < 0) {
                setErrore('Componente 1 della staffetta non selezionato');
                return;
            }
            if (idatleta2 < 0) {
                setErrore('Componente 2 della staffetta non selezionato');
                return;
            }
            if (idatleta3 < 0) {
                setErrore('Componente 3 della staffetta non selezionato');
                return;
            }
            if (idatleta4 < 0) {
                setErrore('Componente 4 della staffetta non selezionato');
                return;
            }
            if (categoria === '') {
                setErrore('Categoria della staffetta non inserita');
                return;
            }
        }


        const tempo_update = {
            idtempo: idtempo,
            idprogramma: idprogramma,
            idatleta1: idatleta1 > 0 ? idatleta1 : null,
            idatleta2: idatleta2 > 0 && !individuale ? idatleta2 : null,
            idatleta3: idatleta3 > 0 && !individuale ? idatleta3 : null,
            idatleta4: idatleta4 > 0 && !individuale ? idatleta4 : null,
            tempo: tempo > 0 ? tempo : null,
            frazione1: frazione1 > 0 && !individuale ? frazione1 : null,
            frazione2: frazione2 > 0 && !individuale ? frazione2 : null,
            frazione3: frazione3 > 0 && !individuale ? frazione3 : null,
            frazione4: frazione4 > 0 && !individuale ? frazione4 : null,
            categoria: categoria !== '' && !individuale ? categoria : null,
            posizione: posizione > 0 ? posizione : null,
            punti: punti > 0 ? punti : null,
            note: note !== '' ? note : null
        }

        console.log('TEMPO DA SALVARE')
        console.log(tempo_update);
        // return;
        setErrore(null);
        setMessaggio(null);
        setIsLoading(true);
        if (idtempo > 0) {
            EditTempoMaster(tempo_update)
                .then(resp => {
                    setMessaggio('Tempo modificato correttamente');
                    onUpdateTempo(idtempo, 'edit');
                })
                .catch(err => setErrore(err))
                .finally(() => setIsLoading(false));
        } else {
            AddTempoMaster(tempo_update)
                .then(resp => {
                    setIdtempo(resp.data.idtempo);
                    setMessaggio('Tempo creato correttamente');
                    onUpdateTempo(resp.data.idtempo, 'new');
                })
                .catch(err => setErrore(err))
                .finally(() => setIsLoading(false));
        }
    }

    const deleteTempo = e => {
        e.preventDefault();
        const atleta_deleted = {
            idtempo: idtempo
        }
        console.log(atleta_deleted)
        DeleteTempoMaster(atleta_deleted)
            .then(resp => {
                console.log('Tempo eliminato')
                onUpdateTempo(atleta_deleted, 'delete');
            })
            .catch(err => setErrore(err))
    }



    return (
        <Fragment>
            {messaggio && (
                <div className="alert alert-success mb-1 py-1 shadow-sm fw-bold" role="alert" style={{ fontSize: '1rem' }}>
                    {messaggio}
                </div>
            )
            }
            {errore && (
                <div className="alert alert-danger mb-1 py-1 shadow-sm fw-bold" role="alert" style={{ fontSize: '1rem' }}>
                    {errore}
                </div>
            )
            }
            <form onSubmit={editTempo}>
                <div className="mb-3">
                    <label className="w-100 mb-0" style={{ fontSize: '1rem' }}>Programma</label>
                    <select value={idprogramma} onChange={e => setIdprogramma(parseInt(e.target.value))} className="form-select form-select-lg">
                        <option value={-1} disabled={true}>Nessuna selezione</option>
                        {programmi.map(x => (
                            <option key={x.idprogramma} value={x.idprogramma}>{x.gara} ({printData(x.data)})</option>
                        ))}
                    </select>
                </div>
                <div className="row">
                    <div className="col-lg mb-3">
                        <label className="w-100 mb-0" style={{ fontSize: '1rem' }}>Atleta{!individuale ? ' 1' : ''}</label>
                        <select value={idatleta1} onChange={e => setIdatleta1(parseInt(e.target.value))} className="form-select form-select-lg">
                            <option value={-1} disabled={true}>Nessuna selezione</option>
                            <option disabled={true}>Atleti associati</option>
                            {atleti.filter(x => x.associato).map(x => (
                                <option key={x.idatleta} value={x.idatleta}>{x.cognome.toUpperCase()} {x.nome}</option>
                            ))}
                            <option disabled={true}>Atleti non associati</option>
                            {atleti.filter(x => !x.associato).map(x => (
                                <option key={x.idatleta} value={x.idatleta}>{x.cognome.toUpperCase()} {x.nome}</option>
                            ))}
                        </select>
                    </div>
                    {!individuale && (
                        <div className="col-lg-3 mb-3">
                            <label htmlFor={'frazione1'} className="w-100 mb-0" style={{ fontSize: '1rem' }}>Frazione 1</label>
                            <input type="number" value={frazione1} onChange={e => setFrazione1(parseFloat(e.target.value))} className="form-control form-control-lg" placeholder="Inserisci la frazione" name={'frazione1'} id={'frazione1'} min="0" step="any" />
                        </div>
                    )}
                </div>
                {!individuale && (
                    <Fragment>
                        <div className="row">
                            <div className="col-lg mb-3">
                                <label className="w-100 mb-0" style={{ fontSize: '1rem' }}>Atleta 2</label>
                                <select value={idatleta2} onChange={e => setIdatleta2(parseInt(e.target.value))} className="form-select form-select-lg">
                                    <option value={-1} disabled={true}>Nessuna selezione</option>
                                    <option disabled={true}>Atleti associati</option>
                                    {atleti.filter(x => x.associato).map(x => (
                                        <option key={x.idatleta} value={x.idatleta}>{x.cognome.toUpperCase()} {x.nome}</option>
                                    ))}
                                    <option disabled={true}>Atleti non associati</option>
                                    {atleti.filter(x => !x.associato).map(x => (
                                        <option key={x.idatleta} value={x.idatleta}>{x.cognome.toUpperCase()} {x.nome}</option>
                                    ))}
                                </select>
                            </div>
                            <div className="col-lg-3 mb-3">
                                <label htmlFor={'frazione2'} className="w-100 mb-0" style={{ fontSize: '1rem' }}>Frazione 1</label>
                                <input type="number" value={frazione2} onChange={e => setFrazione2(parseFloat(e.target.value))} className="form-control form-control-lg" placeholder="Inserisci la frazione" name={'frazione2'} id={'frazione2'} min="0" step="any" />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg mb-3">
                                <label className="w-100 mb-0" style={{ fontSize: '1rem' }}>Atleta 3</label>
                                <select value={idatleta3} onChange={e => setIdatleta3(parseInt(e.target.value))} className="form-select form-select-lg">
                                    <option value={-1} disabled={true}>Nessuna selezione</option>
                                    <option disabled={true}>Atleti associati</option>
                                    {atleti.filter(x => x.associato).map(x => (
                                        <option key={x.idatleta} value={x.idatleta}>{x.cognome.toUpperCase()} {x.nome}</option>
                                    ))}
                                    <option disabled={true}>Atleti non associati</option>
                                    {atleti.filter(x => !x.associato).map(x => (
                                        <option key={x.idatleta} value={x.idatleta}>{x.cognome.toUpperCase()} {x.nome}</option>
                                    ))}
                                </select>
                            </div>
                            <div className="col-lg-3 mb-3">
                                <label htmlFor={'frazione3'} className="w-100 mb-0" style={{ fontSize: '1rem' }}>Frazione 3</label>
                                <input type="number" value={frazione3} onChange={e => setFrazione3(parseFloat(e.target.value))} className="form-control form-control-lg" placeholder="Inserisci la frazione" name={'frazione3'} id={'frazione3'} min="0" step="any" />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg mb-3">
                                <label className="w-100 mb-0" style={{ fontSize: '1rem' }}>Atleta 4</label>
                                <select value={idatleta4} onChange={e => setIdatleta4(parseInt(e.target.value))} className="form-select form-select-lg">
                                    <option value={-1} disabled={true}>Nessuna selezione</option>
                                    <option disabled={true}>Atleti associati</option>
                                    {atleti.filter(x => x.associato).map(x => (
                                        <option key={x.idatleta} value={x.idatleta}>{x.cognome.toUpperCase()} {x.nome}</option>
                                    ))}
                                    <option disabled={true}>Atleti non associati</option>
                                    {atleti.filter(x => !x.associato).map(x => (
                                        <option key={x.idatleta} value={x.idatleta}>{x.cognome.toUpperCase()} {x.nome}</option>
                                    ))}
                                </select>
                            </div>
                            <div className="col-lg-3 mb-3">
                                <label htmlFor={'frazione4'} className="w-100 mb-0" style={{ fontSize: '1rem' }}>Frazione 4</label>
                                <input type="number" value={frazione4} onChange={e => setFrazione4(parseFloat(e.target.value))} className="form-control form-control-lg" placeholder="Inserisci la frazione" name={'frazione4'} id={'frazione4'} min="0" step="any" />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg mb-3">
                                <label htmlFor="punti" className="w-100 mb-0" style={{ fontSize: '1rem' }}>Punti</label>
                                <input type="number" value={punti} onChange={e => setPunti(parseInt(e.target.value))} className="form-control form-control-lg" placeholder="Inserisci i punti" name="punti" id="punti" min="0" />
                            </div>
                            <div className="col-lg mb-3">
                                <label htmlFor="categoria" className="w-100 mb-0" style={{ fontSize: '1rem' }}>Categoria</label>
                                <input type="text" value={categoria} onChange={e => setCategoria(e.target.value)} className="form-control form-control-lg" placeholder="Inserisci la categoria" name="categoria" id="categoria" />
                            </div>
                        </div>
                    </Fragment>
                )}

                <div className="row">
                    <div className="col-lg mb-3">
                        <label className="w-100 mb-0" style={{ fontSize: '1rem' }}>Tempo</label>
                        <input type="number" value={minuti} onChange={e => setMinuti(parseInt(e.target.value))} className="form-control form-control-lg d-inline-block text-end" placeholder="mm" name="minuti" id="minuti" style={{ width: '4.6rem' }} step="1" min="0" /> mm
                        <input type="number" value={secondi} onChange={e => setSecondi(parseInt(e.target.value))} className="form-control form-control-lg d-inline-block ms-1 text-end" placeholder="ss" name="secondi" id="secondi" style={{ width: '4.6rem' }} step="1" min="0" /> ss
                        <input type="number" value={centesimi} onChange={e => setCentesimi(parseInt(e.target.value))} className="form-control form-control-lg d-inline-block ms-1 text-end" placeholder="cc" name="centesimi" id="centesimi" style={{ width: '4.6rem' }} step="1" min="0" /> cc
                    </div>
                    <div className="col-lg mb-3">
                        <label htmlFor="posizione" className="w-100 mb-0" style={{ fontSize: '1rem' }}>Posizione</label>
                        <input type="number" value={posizione} onChange={e => setPosizione(parseInt(e.target.value))} className="form-control form-control-lg" placeholder="Inserisci la posizione" name="posizione" id="posizione" min="0" />
                    </div>
                </div>

                <div className="form-group mb-3">
                    <label htmlFor="note" style={{ fontSize: '1rem' }}>Note</label>
                    <textarea rows="3" value={note} onChange={e => setNote(e.target.value)} className="form-control form-control-lg" name="note" id="note"></textarea>
                </div>
                <div className="d-flex">
                    <button type="submit" disabled={isLoading} className="btn btn-lg btn-success w-100" style={{ borderRadius: '0' }}>
                        Salva{tempo > 0 ? ' ' + printTempo(tempo, false) : ''}
                    </button>
                    {idtempo > 0 && (
                        <button onClick={deleteTempo} disabled={isLoading} className="btn btn-lg btn-danger w-100" style={{ borderRadius: '0' }}>
                            Elimina
                        </button>
                    )}
                </div>
            </form>
        </Fragment>
    );
}
