import { Fragment, useEffect, useState } from "react";
import { Outlet, useNavigate, useParams } from "react-router-dom";
import useScrollTop from "../../hooks/useScrollTop";
import useTitle from "../../hooks/useTitle";
import { GetEventoMaster } from "../../rest/api";
import { colorCircuito, printData } from "../../utility";
import Copertina from "../common/Copertina";
import MenuEvento from "./MenuEvento";


export default function EventoMasterPage() {
    let param = useParams();
    const navigate = useNavigate();
    const [evento, setEvento] = useState();
    useScrollTop();
    useTitle(evento ? evento.evento : null);

    useEffect(() => {
        let doUpdate = true;
        GetEventoMaster(param.idevento)
            .then(result => {
                if (doUpdate) 
                    setEvento(result.data);
            })
            .catch(err => navigate('/master/eventi'))
        return () => doUpdate = false;
    }, [param.idevento])

    // useEffect(() => {
    //     console.log(window.location.hash);
    //     if (window.location.hash.endsWith('/master/eventi/' + param.idevento) && evento) {
    //         if (evento.risultati > 0)
    //             navigate('/master/eventi/' + param.idevento + '/risultati');
    //         else
    //             navigate('/master/eventi/' + param.idevento + '/programma');
    //     }
    // }, [window.location.hash, param.idevento, evento])


    console.log(evento)

    if (!evento)
        return null;

    let periodo = '';
    if (evento.inizio === evento.fine)
        periodo = printData(evento.inizio);
    else
        periodo = 'Dal ' + printData(evento.inizio) + ' al ' + printData(evento.fine);

    return (
        <Fragment>
            <Copertina
                titolo={evento.evento + ' ' + evento.stagione.replace('-', '/')}
                sottotitolo={`Piscina ${evento.piscina} | Vasca ${evento.vasca}m\n${periodo}`}
                immagine={
                    <span className="badge mb-3 shadow d-none d-sm-inline-block" style={{ fontSize: '1.4rem', border: '1px solid black', backgroundColor: colorCircuito(evento.circuito) }}>
                        {evento.circuito.toUpperCase()}
                    </span>
                }
                sfondo="sfondo-eventi.jpg"
            />
            <MenuEvento idevento={param.idevento} />
            <div className="container-lg" style={{ marginTop: 'max(8%, 3rem)', marginBottom: 'max(8%, 3rem)' }}>
                <div className="row">
                    <div className="col">
                        <Outlet />
                    </div>
                </div>
            </div>
        </Fragment>
    );
}
