import { useEffect } from "react"
import { Outlet } from "react-router-dom"
import { useUser } from "../../hooks/UserContext";
import { CheckToken } from "../../rest/api";
import Footer from "./Footer"
import MenuPrincipale2 from "./MenuPrincipale2"


export default function Layout() {
    const [user, setUser] = useUser();

    useEffect(() => {
        const login_esistente = localStorage.getItem('login_data');
        console.log(login_esistente)
        if (login_esistente) {
            const dati_login = JSON.parse(login_esistente);
            console.log('DATI LOGIN')
            console.log(dati_login)
            let doUpdate = true;
            CheckToken()
                .then(risposta => {
                    if (doUpdate) {
                        if (risposta.data) {
                            console.log('Token era valido!')
                            setUser(dati_login);
                        }
                        else {
                            localStorage.removeItem('login_data');
                            setUser(null);
                        }
                    }
                }).catch(() => {
                    if (doUpdate) {
                        localStorage.removeItem('login_data');
                        setUser(null);
                    }
                });
        }
    }, [])

    return (
        <div>
            <MenuPrincipale2 />
            <div className="content">
                <Outlet />
            </div>
            <Footer />
        </div>
    )
}