import { Fragment, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { GetRecordAtletaMaster } from "../../rest/api";
import Caricamento from "../common/Caricamento";
import ContentRecord from "../master_squadra/ContentRecord";
import { BsQuestionCircleFill } from 'react-icons/bs';
import useScrollTop from "../../hooks/useScrollTop";
import LegendaRecord from '../common/LegendaRecord';

export default function RecordAtletaMaster() {
    let param = useParams();
    const [record, setRecord] = useState();
    useScrollTop();

    useEffect(() => {
        let doUpdate = true;
        GetRecordAtletaMaster(param.idatleta)
            .then(result => {
                if (doUpdate)
                    setRecord(result.data)
            })
        return () => doUpdate = false;
    }, [param.idatleta])

    console.log(record)

    return (
        <Fragment>
            <div className="text-center mb-4">
                <h2>Record personali</h2>
                <p>
                    La tabella riassume i migliori tempi dell'atleta per ogni gara. I record contrassegnati con <BsQuestionCircleFill /> sono stati realizzati nella prima frazione di una staffetta. 
                </p>
                <LegendaRecord tipo={"stagionale"} />
            </div>
            {!record && <Caricamento messaggio="Caricamento dei record in corso" />}
            {record && <ContentRecord dati={record} />}
        </Fragment>
    )
}
