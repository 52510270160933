import { Fragment, useEffect, useState } from "react";
import { useUser } from "../../hooks/UserContext";
import useScrollTop from "../../hooks/useScrollTop";
import useTitle from "../../hooks/useTitle";
import { GetCalcolatrice, GetGare } from "../../rest/api";
import { printTempo } from "../../utility";
import Caricamento from "../common/Caricamento";
import Copertina from "../common/Copertina";



export default function CalcolatricePage() {
    const [punti, setPunti] = useState(0);
    const [minuti, setMinuti] = useState(0);
    const [secondi, setSecondi] = useState(0);
    const [centesimi, setCentesimi] = useState(0);
    const [idgara, setIdGara] = useState(-1);
    const [vasca, setVasca] = useState('');
    const [sesso, setSesso] = useState('');
    const [categoria, setCategoria] = useState('');

    const [tempoPerPunti, setTempoPerPunti] = useState();
    const [tempoAltraVasca, setTempoAltraVasca] = useState();
    const [puntiPerTempo, setPuntiPerTempo] = useState();

    const [isLoading, setIsLoading] = useState(false);
    const [messaggio, setMessaggio] = useState();
    const [errore, setErrore] = useState();
    const [gare, setGare] = useState();
    useTitle('Calcolatrice')
    useScrollTop();
    const [user] = useUser();

    useEffect(() => {
        let doUpdate = true;
        GetGare()
            .then(result => {
                if (doUpdate)
                    setGare(result.data.filter(x => x.individuale && x.nome.indexOf('combinata') === -1));
            })
        return () => doUpdate = false;
    }, [])

    const tempo = Math.round((60 * minuti + secondi + centesimi / 100) * 100) / 100;

    const resetRisultati = () => {
        setTempoAltraVasca(null);
        setPuntiPerTempo(null);
        setTempoPerPunti(null);
    }

    const calcola = () => {
        const dati = {
            idgara: idgara > 0 ? idgara : null,
            sesso: sesso !== '' ? sesso : null,
            vasca: vasca !== '' ? vasca : null,
            tempo: tempo > 0 ? tempo : null,
            categoria: categoria !== '' ? categoria : null,
            punti: punti > 0 ? punti : null,
        }
        if (!dati.idgara) {
            setErrore('Nessuna gara selezionata');
            return;
        }
        if (!dati.sesso) {
            setErrore('Nessun sesso selezionato');
            return;
        }
        if (!dati.vasca) {
            setErrore('Nessuna vasca selezionata');
            return;
        }
        if (!dati.categoria) {
            setErrore('Nessuna categoria selezionata');
            return;
        }
        if (!dati.tempo && !dati.punti) {
            setErrore('Immettere un tempo');
            return;
        }
        console.log(dati);

        setErrore(null);
        setMessaggio(null);
        setIsLoading(true);
        setTempoAltraVasca(null);
        setPuntiPerTempo(null);
        setTempoPerPunti(null);
        GetCalcolatrice(dati)
            .then(resp => {
                console.log(resp);
                setTempoPerPunti(resp.data.tempo_trovato);
                setTempoAltraVasca(resp.data.tempo_trovato_altra_vasca);
                setPuntiPerTempo(resp.data.punteggio_trovato);
            })
            .catch(err => setErrore(err))
            .finally(() => setIsLoading(false));
    };

    return (
        <Fragment>
            <Copertina
                titolo="Calcolatrice"
                sottotitolo="La calcolatrice consente di calcolare il punteggio per un determinato tempo, il tempo per un determinato punteggio o per convertire un tempo tra vasca lunga e viceversa"
                // immagine="logo-bianco2.png"
                sfondo="cover-calcolatrice.jpg"
            />

            <div className="container" style={{ marginTop: 'max(8%, 3rem)', marginBottom: 'max(8%, 3rem)' }}>
                <div className="row">
                    <div className="col">
                        <div className="pannello p-4 p-lg-5 mx-auto" style={{ maxWidth: '900px' }}>
                            {!gare && <Caricamento messaggio="Preparazione dati" />}
                            {gare && (
                                <Fragment>
                                    <h1 className='text-center mb-5'>Calcolatrice FIN</h1>
                                    {messaggio && (
                                        <div className="alert alert-success mb-1 py-1 shadow-sm fw-bold" role="alert" style={{ fontSize: '1rem' }}>
                                            {messaggio}
                                        </div>
                                    )
                                    }
                                    {errore && (
                                        <div className="alert alert-danger mb-1 py-1 shadow-sm fw-bold" role="alert" style={{ fontSize: '1rem' }}>
                                            {errore}
                                        </div>
                                    )
                                    }
                                    <div className="mb-2">
                                        <select value={idgara} onChange={e => {
                                            setIdGara(parseInt(e.target.value));
                                            resetRisultati();
                                        }}
                                            className="form-select form-select-lg">
                                            <option value={-1} disabled={true}>Scegli una gara</option>
                                            {gare.map(x => (
                                                <option key={x.idgara} value={x.idgara}>{x.nome}</option>
                                            ))}
                                        </select>
                                    </div>
                                    <div className="mb-2">
                                        <select value={sesso} onChange={e => {
                                            setSesso(e.target.value);
                                            resetRisultati();
                                        }} className="form-select form-select-lg">
                                            <option value='' disabled={true}>Scegli il sesso</option>
                                            <option value='M'>Maschio</option>
                                            <option value='F'>Femmina</option>
                                        </select>
                                    </div>
                                    <div className="mb-2">
                                        <select value={vasca} onChange={e => {
                                            setVasca(parseInt(e.target.value));
                                            resetRisultati();
                                        }} className="form-select form-select-lg">
                                            <option value='' disabled={true}>Scegli una vasca</option>
                                            <option value='25'>25m</option>
                                            <option value='50'>50m</option>
                                        </select>
                                    </div>
                                    <div className="mb-2">
                                        <select value={categoria} onChange={e => {
                                            setCategoria(e.target.value);
                                            resetRisultati();
                                        }} className="form-select form-select-lg">
                                            <option value='' disabled={true}>Scegli una categoria</option>
                                            <option value='M20'>M20</option>
                                            <option value='M25'>M25</option>
                                            <option value='M30'>M30</option>
                                            <option value='M35'>M35</option>
                                            <option value='M40'>M40</option>
                                            <option value='M45'>M45</option>
                                            <option value='M50'>M50</option>
                                            <option value='M55'>M55</option>
                                            <option value='M60'>M60</option>
                                            <option value='M65'>M65</option>
                                            <option value='M70'>M70</option>
                                            <option value='M75'>M75</option>
                                            <option value='M80'>M80</option>
                                            <option value='M85'>M85</option>
                                            <option value='M90'>M90</option>
                                            <option value='M95'>M95</option>
                                            <option value='M100'>M100</option>
                                        </select>
                                    </div>
                                    <div className="row">
                                        <div className="col-lg text-center">
                                            <div>
                                                Tempo (mm:ss:cc)
                                                <div className="mt-1">
                                                    <input type="number" value={minuti} onChange={e => {
                                                        setMinuti(parseInt(e.target.value));
                                                        resetRisultati();
                                                    }} className="form-control form-control-lg d-inline-block text-end" placeholder="mm" name="minuti" id="minuti" style={{ width: '5rem' }} step="1" min="0" />
                                                    <input type="number" value={secondi} onChange={e => {
                                                        setSecondi(parseInt(e.target.value));
                                                        resetRisultati();
                                                    }} className="form-control form-control-lg d-inline-block ms-1 text-end" placeholder="ss" name="secondi" id="secondi" style={{ width: '5rem' }} step="1" min="0" />
                                                    <input type="number" value={centesimi} onChange={e => {
                                                        setCentesimi(parseInt(e.target.value));
                                                        resetRisultati();
                                                    }} className="form-control form-control-lg d-inline-block ms-1 text-end" placeholder="cc" name="centesimi" id="centesimi" style={{ width: '5rem' }} step="1" min="0" />
                                                </div>
                                                {puntiPerTempo > 0 && (
                                                    <p className="mt-2 text-center fw-bold">
                                                        Il tempo di {printTempo(tempo)} corrisponde a {puntiPerTempo} punti 
                                                        {/* In vasca {vasca === 25 ? 'lunga' : 'corta'} corrisponde a {printTempo(tempoAltraVasca)} */}
                                                    </p>
                                                )}
                                            </div>
                                        </div>
                                        {/* <div className="col-lg">
                                            <div>
                                                Punti
                                                <input type="number" value={punti} onChange={e => {
                                                    setPunti(parseInt(e.target.value));
                                                    resetRisultati();
                                                }} className="form-control form-control-lg mt-1" placeholder="Inserisci i punti" name="punti" id="punti" min="0" />
                                                {tempoPerPunti && (
                                                    <p className="mt-2 text-center fw-bold">
                                                        {punti} punti corrispondo a un tempo di {printTempo(tempoPerPunti)}
                                                    </p>
                                                )}
                                            </div>
                                        </div> */}
                                    </div>
                                    <button onClick={calcola} disabled={isLoading} className="btn btn-lg btn-success w-100 mt-4">
                                        Calcola
                                    </button>
                                </Fragment>
                            )}

                        </div>
                    </div>

                </div>
            </div>
        </Fragment>
    );
}
