import { Fragment } from "react";
import { Link } from "react-router-dom";
import { printData } from "../../utility";


export default function GridAtleti({ dati, onSelect }) {

    console.log(dati)


    return (
        <div className="row row-cols-1 row-cols-sm-2 row-cols-lg-3 row-cols-xl-4 g-4">
            {dati.map(x => {
                return (
                    <div key={x.idatleta} className="col">
                        <div className="card shadow position-relative h-100">
                            <span className="badge bg-dark position-absolute" style={{ top: '5px', right: '5px', zIndex: '1' }}>
                                {x.categoria}
                            </span>
                            <div className="zoom position-relative">
                                <img
                                    onError={({ currentTarget }) => {
                                        currentTarget.onerror = null; // prevents looping
                                        currentTarget.src = process.env.PUBLIC_URL + '/assets/atleti/atleta0.jpg';
                                    }}
                                    src={process.env.PUBLIC_URL + '/assets/atleti/atleta' + x.idatleta + '.jpg'}
                                    loading="lazy"
                                    className="card-img-top"
                                    alt="..." />
                                <div className="position-absolute py-1 text-center w-100" style={{ bottom: '0', left: '0', color: 'white', backgroundColor: 'rgba(0,0,0,0.8)' }}>
                                    {x.cognome.toUpperCase()} {x.nome}
                                </div>
                            </div>
                            <div className="card-body text-center py-2">
                                {/* <h5 className="card-title fw-bold">{x.cognome.toUpperCase()} {x.nome}</h5> */}
                                <p className="card-text" style={{ fontSize: '1rem' }}>
                                    {x.idatleta !== 3 && (
                                        <Fragment>
                                            {x.stagioni > 0 ? `${x.stagioni} stagion${x.stagioni !== 1 ? 'i' : 'e'}` : 'Prima stagione'}
                                            {x.gare > 0 && (
                                                <Fragment>
                                                    <br />
                                                    Prima gara il {printData(x.prima_gara)}<br />
                                                    {x.eventi} event{x.eventi !== 1 ? 'i' : 'o'} | {x.gare} gar{x.gare !== 1 ? 'e' : 'a'}<br />
                                                    {(x.ori + x.argenti + x.bronzi) > 0 &&
                                                        (
                                                            <Fragment>
                                                                {x.ori} or{x.ori !== 1 ? 'i' : 'o'} | {x.argenti} argent{x.argenti !== 1 ? 'i' : 'o'} | {x.bronzi} bronz{x.bronzi !== 1 ? 'i' : 'o'}<br />
                                                            </Fragment>
                                                        )
                                                    }
                                                    {x.max_punti ? <span>Punteggio massimo: {x.max_punti}</span> : null}
                                                </Fragment>
                                            )}
                                        </Fragment>
                                    )}
                                    {x.idatleta === 3 && 'COACH' }
                                </p>
                            </div>
                            {x.stagioni > 0 && x.idatleta !== 3 && (
                                <div className="card-footer p-0 d-flex">
                                    <Link to={`/master/atleti/${x.idatleta}`} className="w-100">
                                        <button className="btn btn-dark w-100" style={{ borderRadius: '0' }}>
                                            Scheda
                                        </button>
                                    </Link>
                                    <button onClick={() => onSelect(x)} className="btn btn-warning w-100" style={{ borderRadius: '0' }}>
                                        Record
                                    </button>
                                </div>
                            )}
                        </div>
                    </div>
                )
            })}
        </div>
    );
}
