import { Fragment, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import useScrollTop from "../../hooks/useScrollTop";
import { GetMedagliereAtleta } from "../../rest/api";
import Caricamento from "../common/Caricamento";
import ContentMedagliere from "./ContentMedagliere";


export default function Medagliere() {
    let param = useParams();
    const [medaglie, setMedaglie] = useState();
    useScrollTop();

    useEffect(() => {
        let doUpdate = true;
        GetMedagliereAtleta(param.idatleta)
            .then(result => {
                if (doUpdate)
                    setMedaglie(result.data);
            })
        return () => doUpdate = false;
    }, [param.idatleta])

    console.log(medaglie)

    return (
        <Fragment>
            <div className="text-center mb-4">
                <h2>Medagliere</h2>
                <p className="mb-5">
                    Il medagliere descrive in dettaglio le medaglie ottenute raggruppandole per circuito. Sotto al numero di medaglie sono elencate le gare dove sono state vinte, ordinandole in modo decrescente per numero di successi.
                </p>
            </div>
            {!medaglie && <Caricamento messaggio="Caricamento delle medaglie in corso" />}
            {medaglie && (
                <div className="mx-auto"  style={{ maxWidth: '900px', marginTop: 'max(8%, 3rem)', marginBottom: 'max(8%, 3rem)' }}>
                    <ContentMedagliere dati={medaglie} />
                </div>
            )}
        </Fragment>
    );
}
