import { Fragment, useEffect, useState } from "react";
import { BsFillSquareFill } from "react-icons/bs";
import { Link, useParams } from "react-router-dom";
import useScrollTop from "../../hooks/useScrollTop";
import { GetEdizionEvento } from "../../rest/api";
import { colorCircuito, colorGara, iconaGara, nomiGiorni, printData } from "../../utility";
import Caricamento from "../common/Caricamento";
import IconaSessione from "../common/IconaSessione";


export default function Edizioni() {
    let param = useParams();
    const [eventi, setEventi] = useState();
    useScrollTop();

    useEffect(() => {
        let doUpdate = true;
        GetEdizionEvento(param.idevento)
            .then(result => {
                if (doUpdate) {
                    setEventi(result.data);
                }
            })
        return () => doUpdate = false;
    }, [param.idevento])

    console.log(eventi)

    if (!eventi)
        return <Caricamento messaggio="Caricamento eventi in corso" />

    const has_tempi = eventi.tempi !== null;

    if (eventi.length === 0) {
        return (
            <Fragment>
                <div className="text-center">
                    <h2>Edizioni precedenti</h2>
                    <p className="mb-5">
                        Non ci sono edizioni dell'evento nelle stagioni passate.
                    </p>
                </div>
            </Fragment>
        )
    }
    return (
        <Fragment>
            <div className="text-center">
                <h2>Edizioni precedenti</h2>
                <p className="mb-5">
                    Edizioni delle altre stagioni dell'evento.
                    {has_tempi === true ? ' Nella tabella sono indicate anche il numero di gare a cui hai partecipato.' : ''}
                </p>
            </div>
            <table className="w-100 shadow mx-auto mt-5" style={{ fontSize: '1.1rem', lineHeight: '1.2', maxWidth: '600px' }}>
                <thead>
                    <tr className="fw-bold">
                        <th className="text-center py-1" style={{ width: '8rem' }}>STAGIONE</th>
                        <th className="py-1 d-none d-sm-table-cell" style={{ width: '3.3rem' }}></th>
                        <th className="ps-2 py-1">PISCINA</th>
                        <th className="text-center py-1" style={{ width: '5rem' }}>VASCA</th>
                        {has_tempi && <th className="text-center py-1 d-none d-md-table-cell" style={{ width: '6rem' }}>TUE GARE</th>}
                    </tr>
                </thead>
                <tbody>
                    {eventi.map(x => {
                        return (
                            <tr key={x.idevento}>
                                <td className="text-center py-1">
                                    {x.stagione.replace('-', '/')}
                                </td>
                                <td className="text-end py-1 d-none d-sm-table-cell">
                                    <span className="badge w-100 shadow" style={{ backgroundColor: colorCircuito(x.circuito), fontWeight: 'normal' }}>
                                        {x.circuito.slice(0, 4).toUpperCase()}
                                    </span>
                                </td>
                                <td className="ps-2">
                                    {x.visibile ?
                                        <Link to={`/master/eventi/${x.idevento}`}>{x.piscina}</Link> :
                                        x.piscina
                                    }
                                </td>
                                <td className="text-center py-1">
                                    {x.vasca}m
                                </td>
                                {has_tempi && (
                                    <td className="text-center d-none d-md-table-cell">
                                        {x.tempi > 0 ? x.tempi : '-'}
                                    </td>
                                )}
                            </tr>
                        )
                    })}
                </tbody>
            </table>
        </Fragment>
    )

}
