export function printData(data, year_digits = 4) {
    data = new Date(data);
    let anno_str = data.getFullYear();
    if (year_digits === 2)
        anno_str = anno_str.toString().substr(-2);
    return ("0" + data.getDate()).slice(-2) + '/' + ("0" + (data.getMonth() + 1)).slice(-2) + '/' + anno_str
}

export function printDataTime(data, year_digits = 4) {
    if (data === null || data === undefined)
        return '-';
    data = new Date(data);
    const oggi = new Date();
    const ieri = new Date();
    ieri.setDate(ieri.getDate() - 1);
    let dataStr;
    if (data.getDay() === oggi.getDay() && data.getMonth() === oggi.getMonth() && data.getFullYear() === oggi.getFullYear())
        dataStr = 'Oggi';
    else if (data.getDay() === ieri.getDay() && data.getMonth() === ieri.getMonth() && data.getFullYear() === ieri.getFullYear())
        dataStr = 'Ieri';
    else {
        let anno_str = data.getFullYear()
        if (year_digits === 2)
            anno_str = anno_str.toString().substr(-2);
        dataStr = ("0" + data.getDate()).slice(-2) + '/' + ("0" + (data.getMonth() + 1)).slice(-2) + '/' + anno_str;
    }

    let ore = data.getHours();
    let min = data.getMinutes();
    let sec = data.getSeconds();
    return dataStr + ' ' + ("0" + ore).slice(-2) + ':' + ("0" + min).slice(-2) + ':' + ("0" + sec).slice(-2);
}

export function printTempo(tempoOriginario, withZeri = true, withSegno = false) {
    let tempo = Math.abs(tempoOriginario);
    tempo = Math.round(tempo * 100) / 100;
    const centesimi = Math.round((tempo % 1) * 100);

    tempo = tempo - centesimi / 100;

    const minuti = Math.floor(tempo / 60);
    const secondi = Math.round(tempo - 60 * minuti);

    let risultato = '';
    if (minuti > 0 || withZeri)
        risultato = ("0" + minuti).slice(-2) + "'";
    risultato = risultato + ("0" + secondi).slice(-2) + '"' + ("0" + centesimi).slice(-2);
    if (withSegno)
        risultato = (tempoOriginario > 0 ? '+' : tempoOriginario < 0 ? '-' : '') + risultato;

    // if(tempoOriginario===-3.99) alert('minuti ' + minuti + '\nsecondi '+ secondi + '\ncentesimi '+ centesimi)
    return risultato;
}

export function getStagioneCorrente(separatore = '-') {
    const oggi = new Date();
    const anno = oggi.getFullYear();
    const mese = oggi.getMonth() + 1;
    let anno_inizio = '';
    if (mese > 9)
        anno_inizio = anno;
    else
        anno_inizio = anno - 1;
    return anno_inizio + separatore + (anno_inizio + 1);
}

export function colorGara(gara) {
    gara = gara.toLowerCase();
    if (gara.includes('stile libero'))
        return 'forestgreen';
    if (gara.includes('rana'))
        return 'red';
    if (gara.includes('dorso'))
        return 'blue';
    if (gara.includes('farfalla') || gara.includes('delfino'))
        return 'fuchsia';
    return 'gold';
}

export function colorCircuito(circuito) {
    circuito = circuito.toLowerCase();
    if (circuito.includes('fina'))
        return 'goldenrod';
    if (circuito.includes('fin'))
        return 'blue';
    if (circuito.includes('uisp'))
        return 'forestgreen';
    if (circuito.includes('len'))
        return 'darkcyan';
    if (circuito.includes('confsport'))
        return 'fuchsia';
}

export function iconaGara(gara) {
    gara = gara.toLowerCase();
    if (gara.includes('stile libero'))
        return 'stilelibero.png';
    if (gara.includes('rana'))
        return 'rana.png';
    if (gara.includes('dorso'))
        return 'dorso.png';
    if (gara.includes('farfalla') || gara.includes('delfino'))
        return 'farfalla.png';
    return 'misti.png';
}

export const nomiGiorni = ['Lunedì', 'Martedì', 'Mercoledì', 'Giovedì', 'Venerdì', 'Sabato', 'Domenica'];

