import { iconaGara, nomiGiorni, printData, printTempo } from "../../utility";
import { Link } from "react-router-dom";
import { Fragment } from "react";
import { BsFillSquareFill } from 'react-icons/bs';
import IconaSessione from "../common/IconaSessione";

export default function TabellaRisultatiEventoMaster({ risultati, recordSocietari }) {


    return (
        risultati.map(r => {
            return (
                <div key={r.idprogramma}>
                    <h4 className="mt-5 fw-bold text-center">
                        <img src={process.env.PUBLIC_URL + '/assets/' + iconaGara(r.gara)} className="img-fluid mx-auto d-block d-sm-inline" style={{ height: '2.4rem', position: 'relative', top: '-2px' }} alt="" />
                        <span className="mx-1">
                            {r.gara} - {nomiGiorni[new Date(r.data_gara).getDay()].toLowerCase()} {printData(r.data_gara, 2)}
                            <img
                                src={process.env.PUBLIC_URL + '/assets/' + (r.sessione === 'M' ? 'sole' : 'luna') + '.png'}
                                className="img-fluid position-relative ms-2"
                                style={{ "width": "1.8rem", top: '-3px' }}
                                alt=''
                            />
                        </span>
                    </h4>
                    <table className="w-100 shadow" style={{ fontSize: '1.1rem', lineHeight: '1.2', maxWidth: '900px', margin: '0 auto' }}>
                        <thead>
                            <tr className="fw-bold">
                                <th className="ps-2 py-1" style={{ minWidth: '12rem' }}>{r.individuale ? 'ATLETA' : 'ATLETI'}</th>
                                <th className="text-center py-1 d-none d-md-table-cell" style={{ width: '6rem' }}>CAT</th>
                                <th className="text-end py-1 pe-2" style={{ width: '12rem' }}>TEMPO</th>
                                <th className="text-end py-1 pe-2" style={{ width: '7rem' }}>DISTACCO</th>
                                <th className="text-center py-1 d-none d-sm-table-cell" style={{ width: '5rem' }}>POS</th>
                                <th className="text-center py-1 d-none d-sm-table-cell" style={{ width: '5rem' }}>PUNTI</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                r.tempi.map(x => {

                                    const num_atleti = r.individuale ? 1 : 4;
                                    let elem_atleti = [];
                                    let indice = 0;
                                    for (let i = 1; i <= num_atleti; i++) {
                                        let atletaX = x['cognome' + i].toUpperCase() + ' ' + x['nome' + i] + (!r.individuale && x['frazione' + i] > 0 ? ' (' + printTempo(x['frazione' + i], false) + ')' : '');
                                        if (x['associato' + i] && x['idatleta' + i] !== 3)
                                            atletaX = <Link to={`/master/atleti/${x['idatleta' + i]}`}>{atletaX}</Link>;
                                        elem_atleti[indice] = (
                                            <Fragment>
                                                <BsFillSquareFill className="me-1 position-relative d-none d-sm-inline" style={{ top: '-2px', color: x['sesso' + i] === 'M' ? 'blue' : 'deeppink' }} />
                                                {atletaX}
                                            </Fragment>
                                        );
                                        if (i < num_atleti) {
                                            indice++;
                                            elem_atleti[indice] = <br />
                                        }
                                        indice++;
                                    }

                                    let elem_distacchi = [];
                                    indice = 0;
                                    for (let i = 1; i <= num_atleti; i++) {
                                        let atletaX;
                                        if (x['associato' + i] && x['idatleta' + i] !== 3) {
                                            if (x['distacco' + i])
                                                atletaX = printTempo(x['distacco' + i], false, true);
                                            else
                                                atletaX = '1a volta';
                                        }
                                        else
                                            atletaX = '-';

                                        elem_distacchi[i] = <Fragment>{atletaX}{i < num_atleti ? <br /> : null}</Fragment>;
                                    }

                                    const is_record = r.individuale && x.distacco1 < 0 && x.associato1 && x.quante1 > 0;
                                    const is_societario = recordSocietari && recordSocietari.findIndex(t => t.idtempo === x.idtempo) !== -1;
                                    return (
                                        <tr key={x.idtempo} className={is_record && is_societario ? 'row-societario-stagionale' : (is_record ? 'row-active' : (is_societario ? 'row-societario' : ''))}>
                                            <td className="ps-2 py-1" style={{ lineHeight: (!r.individuale ? '1.4' : '') }}>
                                                {
                                                    elem_atleti.map((e, idx3) => <span key={idx3}>{e}</span>)
                                                }
                                            </td>
                                            <td className="text-center px-2 py-1 d-none d-md-table-cell">
                                                {x.categoria.replace('-', '/')}
                                            </td>
                                            <td className="text-end py-1 pe-2">
                                                {printTempo(x.tempo, false)}
                                            </td>
                                            <td className="text-end py-1 pe-2" style={{ lineHeight: (!r.individuale ? '1.4' : '') }}>
                                                {
                                                    elem_distacchi.map((e, idx3) => <span key={idx3}>{e}</span>)
                                                }
                                            </td>
                                            <td className="text-center py-1 d-none d-sm-table-cell">
                                                {x.posizione === 1 && (<img src={process.env.PUBLIC_URL + '/assets/medal1.gif'} className="img-fluid mx-2" style={{ height: '1.3rem' }} alt="" />)}
                                                {x.posizione === 2 && (<img src={process.env.PUBLIC_URL + '/assets/medal2.gif'} className="img-fluid mx-2" style={{ height: '1.3rem' }} alt="" />)}
                                                {x.posizione === 3 && (<img src={process.env.PUBLIC_URL + '/assets/medal3.gif'} className="img-fluid mx-2" style={{ height: '1.3rem' }} alt="" />)}
                                                {x.posizione > 3 && x.posizione}
                                                {!x.posizione && '-'}
                                            </td>
                                            <td className="text-center py-1 d-none d-sm-table-cell">
                                                {x.punti ?? '-'}
                                            </td>
                                        </tr>
                                    )
                                })
                            }
                        </tbody>
                    </table>
                </div>
            )
        })
    );
}
