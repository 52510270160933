import { useEffect, useState } from 'react';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { GetRecordSocietariMaster } from '../../rest/api';
import TabellaRecordAtleta from './TabellaRecordAtleta';

export default function ContentRecord({ dati, recordSocietari }) {
    const [record, setRecord] = useState();

    useEffect(() => {
        let doUpdate = true;
        GetRecordSocietariMaster()
            .then(result => {
                if (doUpdate)
                    setRecord(result.data);
            })
        return () => doUpdate = false;
    }, [])

    if (!dati)
        return null;
    console.log(dati)

    const record25 = dati.record.filter(x => x.vasca === 25)
    const record50 = dati.record.filter(x => x.vasca === 50)

    return (
        <Tabs
            defaultActiveKey="v25"
            className="mb-3"
            fill
            variant="pills"
        >
            <Tab eventKey="v25" title="Vasca 25m">
                <TabellaRecordAtleta dati={record25} recordSocietari={record} showMore={false} />
            </Tab>
            <Tab eventKey="v50" title="Vasca 50m">
                <TabellaRecordAtleta dati={record50} recordSocietari={record} showMore={false} />
            </Tab>
        </Tabs>
    );
}
