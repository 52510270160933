import { Fragment, useEffect, useState } from "react";
import { useUser } from "../../hooks/UserContext";
import useScrollTop from "../../hooks/useScrollTop";
import useTitle from "../../hooks/useTitle";
import { GetAnagraficaPiscine } from "../../rest/api";
import Caricamento from "../common/Caricamento";
import Modal from 'react-bootstrap/Modal';
import FormPiscina from "./FormPiscina";
import TabellaGestionePiscine from "./TabellaGestionePiscine";


export default function GestionePiscinePage() {
    const [piscine, setPiscine] = useState();
    const [idpiscinaSelezionata, setIdpiscinaSelezionata] = useState(-1);
    const [counterModifiche, setCounterModifiche] = useState(0);
    const [show, setShow] = useState(false);

    useTitle('Gestione Piscine')
    useScrollTop();
    const [user] = useUser();

    useEffect(() => {
        let doUpdate = true;
        GetAnagraficaPiscine()
            .then(result => {
                if (doUpdate)
                    setPiscine(result.data);
            })
        return () => doUpdate = false;
    }, [counterModifiche])

    const selectPiscina = a => {
        console.log(a)
        console.log('seleziono piscina ' + a);
        setIdpiscinaSelezionata(a);
        setShow(true);
    };

    const aggiornaPiscine = (idpiscina, azione) => {
        if (azione === 'new') {
            setIdpiscinaSelezionata(idpiscina);
        }
        else if (azione === 'delete') {
            setShow(false);
            setIdpiscinaSelezionata(-1);
        }
        setCounterModifiche(val => val + 1);
        console.log(idpiscina)
        console.log(azione.toUpperCase());
    };

    const piscinaSelezionata = piscine?.find(x => x.idpiscina === idpiscinaSelezionata);

    console.log(piscine)
    return (
        <Fragment>
            {!piscine && <Caricamento messaggio="Caricamento piscine in corso" />}
            {piscine && (
                <Fragment>
                    {/* <p className="text-center mb-4">
                        Le foto profilo degli atleti devono essere nel rapporto di forma 1.41
                    </p> */}
                    <button className="btn mb-5 w-100 btn-lg btn-dark" onClick={() => {
                        setIdpiscinaSelezionata(-1);
                        setShow(true);
                    }}>Nuova piscina</button>

                    <h2 className="text-center mb-4">Piscine</h2>
                    <TabellaGestionePiscine dati={piscine} onSelectPiscina={selectPiscina} />

                    <Modal
                        show={show}
                        onHide={() => setShow(false)}
                        scrollable
                        backdrop="static"
                        keyboard={false}
                        size="lg"
                    >
                        <Modal.Header closeButton closeVariant='white' style={{ backgroundColor: 'rgba(0,0,0,0.85)', color: 'white' }}>
                            <Modal.Title className="w-100 text-center">
                                {piscinaSelezionata ? `Modifica ${piscinaSelezionata.nome} ` + idpiscinaSelezionata : `Creazione Piscina`}
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <FormPiscina dati={piscinaSelezionata} onUpdatePiscina={aggiornaPiscine} />
                        </Modal.Body>
                    </Modal>
                </Fragment>
            )
            }
        </Fragment>
    );
}
