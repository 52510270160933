import { Fragment, useState } from "react";
import { Link } from "react-router-dom";
import { colorCircuito, getStagioneCorrente, printData } from "../../utility";
import { BsSearch, BsFillDashCircleFill, BsCheckCircleFill } from 'react-icons/bs';
import Riepilogo from "../common/Riepilogo";

const stagione_corrente = getStagioneCorrente();

export default function TabellaEventiMaster({ eventi, stagione }) {
    const [selezionato, setSelezionato] = useState(null);


    return (
        <Fragment>

            <table className="w-100 shadow" style={{ fontSize: '1.1rem', lineHeight: '1.2' }}>
                <thead>
                    <tr className="fw-bold">
                        <th className="text-end py-1" style={{ width: '3.3rem' }}> </th>
                        <th className="py-1 px-2">EVENTO</th>
                        <th className="text-center py-1 d-none d-lg-table-cell" style={{ width: '8rem' }}>INIZIO</th>
                        <th className="py-1" style={{ maxWidth: '12rem' }}>PISCINA</th>
                        <th className="py-1 text-center d-none d-sm-table-cell" style={{ width: '5rem' }}>VASCA</th>
                        <th className="text-center py-1 d-none d-sm-table-cell" style={{ width: '8rem' }}>MEDAGLIE</th>
                        <th className="text-center py-1 d-none d-lg-table-cell" style={{ width: '7rem' }}>GARE</th>
                        <th className="text-center py-1 d-none d-md-table-cell" style={{ width: '4.5rem' }}>ATLETI</th>
                        <th className="py-1 d-table-cell d-lg-none" style={{ width: '2rem' }}></th>
                    </tr>
                </thead>
                <tbody>
                    {
                        eventi.map(x => (
                            <tr key={x.idevento}>
                                <td className="text-end py-1 ps-1">
                                    <span className="badge w-100 shadow" style={{ backgroundColor: colorCircuito(x.circuito), fontWeight: 'normal' }}>
                                        {x.circuito.slice(0, 4).toUpperCase()}
                                    </span>
                                </td>
                                <td className="py-1 px-2">
                                    {stagione_corrente === stagione && x.iscrizioni_aperte !== undefined && (
                                        <Fragment>
                                            {
                                                x.iscrizioni_aperte ?
                                                    <BsCheckCircleFill className="me-1" style={{color: 'forestgreen', cursor: 'help'}} title="Le iscrizioni sono aperte" />:
                                                    <BsFillDashCircleFill className="me-1" style={{color: 'crimson', cursor: 'help'}} title="Le iscrizioni sono chiuse" />
                                            }
                                        </Fragment>
                                    )}
                                    {x.has_programma ?
                                        <Link to={`/master/eventi/${x.idevento}`}>{x.evento}</Link> :
                                        x.evento
                                    }
                                </td>
                                <td className="text-center py-1 d-none d-lg-table-cell">
                                    {printData(x.inizio)}
                                </td>
                                <td className="py-1">
                                    {x.piscina}
                                </td>
                                <td className="py-1 text-center d-none d-sm-table-cell">
                                    {x.vasca}m
                                </td>
                                <td className="text-center py-1 d-none d-sm-flex">
                                    <span className="d-flex" style={{ width: '2.5rem', marginRight: '4px' }}>
                                        {x.ori > 0 && (
                                            <Fragment>
                                                <img src={process.env.PUBLIC_URL + '/assets/medal1.gif'} className="img-fluid" style={{ width: '1rem' }} alt="" />
                                                <span className="text-end w-100">{x.ori}</span>
                                            </Fragment>
                                        )}
                                    </span>
                                    <span className="d-flex" style={{ width: '2.5rem', marginRight: '4px' }}>
                                        {x.argenti > 0 && (
                                            <Fragment>
                                                <img src={process.env.PUBLIC_URL + '/assets/medal2.gif'} className="img-fluid" style={{ width: '1rem' }} alt="" />
                                                <span className="text-end w-100">{x.argenti}</span>
                                            </Fragment>
                                        )}
                                    </span>
                                    <span className="d-flex" style={{ width: '2.5rem' }}>
                                        {x.bronzi > 0 && (
                                            <Fragment>
                                                <img src={process.env.PUBLIC_URL + '/assets/medal3.gif'} className="img-fluid" style={{ width: '1rem' }} alt="" />
                                                <span className="text-end w-100">{x.bronzi}</span>
                                            </Fragment>
                                        )}
                                    </span>
                                </td>
                                <td className="text-center py-1 d-none d-lg-table-cell">
                                    <span className="d-inline-block text-end" style={{ width: '2rem' }}>
                                        {x.iscrizioni_individuali > 0 ? x.iscrizioni_individuali : '-'}
                                    </span>
                                    <span className="d-inline-block" style={{ marginLeft: '3px', marginRight: '3px' }}>
                                        |
                                    </span>
                                    <span className="d-inline-block text-start" style={{ width: '2rem' }}>
                                        {x.staffette > 0 ? x.staffette : '-'}
                                    </span>
                                </td>
                                <td className="text-center py-1 d-none d-md-table-cell">
                                    {x.atleti_individuali > 0 ? x.atleti_individuali : '-'}
                                </td>
                                <td className="text-center py-1 px-1 d-table-cell d-lg-none">
                                    <button onClick={() => setSelezionato(x)} className="btn btn-dark px-1 py-0">
                                        <BsSearch />
                                    </button>
                                </td>
                            </tr>
                        ))
                    }
                </tbody>
            </table>
            {selezionato && <Riepilogo dati={
                {
                    evento: selezionato.evento,
                    piscina: selezionato.piscina,
                    circuito: selezionato.circuito,
                    scadenza: printData(selezionato.scadenza),
                    iscrizioni: selezionato.iscrizioni_individuali + ' | ' + selezionato.staffette,
                    partecipanti: selezionato.atleti_individuali,
                    medaglie: selezionato.ori + ' | ' + selezionato.argenti + ' | ' + selezionato.bronzi,
                    inizio: printData(selezionato.inizio),
                    fine: printData(selezionato.fine)
                }
            } titolo={'Evento'} onClose={() => setSelezionato(null)} />}

        </Fragment >
    );
}
