import { Fragment } from "react";
import { useUser } from "../../hooks/UserContext";
import useScrollTop from "../../hooks/useScrollTop";
import useTitle from "../../hooks/useTitle";
import Copertina from "../common/Copertina";

export default function NotFoundPage() {
    useTitle('404')
    useScrollTop();

    return (
        <Fragment>
            <Copertina
                titolo="404 Not Found"
                sottotitolo="La pagina non è stata trovata"
                // immagine="logo-bianco2.png"
                sfondo="cover-help.jpg"
            />

            
        </Fragment>
    );
}
