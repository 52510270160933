import { colorGara, printDataTime, printTempo } from "../../utility";
import { BsFillSquareFill } from 'react-icons/bs';
import { Fragment } from "react";
import { Link } from "react-router-dom";

export default function TabellaGestioneTempi({ dati, onSelectTempo }) {

    console.log('TEMPI:');
    console.log(dati)
    dati.sort((a, b) => a.ordine_gara - b.ordine_gara);
    return (
        <table className="w-100 shadow" style={{ fontSize: '1.1rem', lineHeight: '1.2' }}>
            <thead>
                <tr className="fw-bold">
                    <th className="py-1 text-end" style={{ width: '4rem' }}>ID</th>
                    <th className="ps-2 py-1">ATLETA</th>
                    <th className="py-1 text-center" style={{width: '2rem'}}>CAT</th>
                    <th className="text-end py-1 pe-2 d-none d-sm-table-cell" style={{ width: '15rem' }}>GARA</th>
                    <th className="text-end py-1 pe-2 d-none d-sm-table-cell" style={{ width: '7rem' }}>TEMPO</th>
                    <th className="text-center py-1 d-none d-sm-table-cell" style={{ width: '4rem' }}>PUNTI</th>
                    <th className="text-center py-1 d-none d-lg-table-cell" style={{ width: '10rem' }}>CREAZIONE</th>
                    <th className="text-center py-1 d-none d-md-table-cell" style={{ width: '10rem' }}>MODIFICA</th>
                    <th className="text-end py-1 pe-2 d-none d-md-table-cell">NOTE</th>
                    <th className="text-center py-1" style={{ width: '2.5rem' }}></th>
                </tr>
            </thead>
            <tbody>
                {
                    dati.map(prog => {
                        const gare_in_programma = prog.iscrizioni;
                        return gare_in_programma.map(x => {

                            const num_atleti = prog.individuale ? 1 : 4;
                            let elem_atleti = [];
                            let indice = 0;
                            for (let i = 1; i <= num_atleti; i++) {
                                let atletaX = x['cognome' + i].toUpperCase() + ' ' + x['nome' + i];
                                atletaX = (
                                    <Fragment>
                                        <BsFillSquareFill className="me-1 position-relative" style={{ top: '-2px', color: x['sesso' + i] === 'M' ? 'blue' : 'deeppink' }} />
                                        <Link to={`/master/atleti/${x['idatleta' + i]}`}>
                                            {atletaX}
                                        </Link>
                                    </Fragment>
                                );
                                elem_atleti[indice] = atletaX;
                                if (i < num_atleti) {
                                    indice++;
                                    elem_atleti[indice] = <br />
                                }
                                indice++;
                            }

                            return (
                                <tr key={x.idtempo}>
                                    <td className="py-1 text-end">
                                        {x.idtempo}
                                    </td>
                                    <td className="ps-2 py-1">
                                        {
                                            elem_atleti.map((e, idx3) => <span key={idx3}>{e}</span>)
                                        }
                                    </td>
                                    <td className="text-center py-1" style={{whiteSpace: 'break-spaces'}}>
                                        {String(x.categoria).replace('-','\n')}
                                    </td>
                                    <td className="text-end py-1 pe-2 d-none d-sm-table-cell">
                                        {prog.gara} <span className="d-none d-lg-inline"><BsFillSquareFill style={{ color: colorGara(prog.gara) }} /></span>
                                    </td>
                                    <td className="text-end py-1 pe-2 d-none d-sm-table-cell" style={{ backgroundColor: x.tempo > 0 ? 'lime' : 'orange' }}>
                                        {printTempo(x.tempo, false)}
                                    </td>
                                    <td className="text-center py-1 d-none d-sm-table-cell">
                                        {x.punti}
                                    </td>
                                    <td className="text-center py-1 d-none d-lg-table-cell">
                                        {printDataTime(x.creazione_tempo, 2)}
                                    </td>
                                    <td className="text-center py-1 d-none d-md-table-cell">
                                        {printDataTime(x.modifica_tempo, 2)}
                                    </td>
                                    <td className="text-end py-1 pe-2 d-none d-md-table-cell">
                                        {x.note ?? '-'}
                                    </td>
                                    <td className="text-center py-1">
                                        <button
                                            onClick={() => onSelectTempo(x.idtempo)}
                                            className="btn btn-primary px-2 py-0">
                                            ...
                                        </button>
                                    </td>
                                </tr>
                            )
                        })
                    })
                }
            </tbody>
        </table>
    )

}
