import { Fragment } from "react";
import useScrollTop from "../../hooks/useScrollTop";
import useTitle from "../../hooks/useTitle";
import Copertina from "../common/Copertina";
import FormLogin from "./FormLogin";

export default function LoginPage() {
    useTitle('Login')
    useScrollTop();


    return (
        <Fragment>
            <Copertina
                titolo="Area Riservata"
                sottotitolo="Area riservata agli atleti della squadra master ASD Aquareal"
                // immagine="cover-login.jpg"
                sfondo="cover-login.jpg"
            />
            <div style={{ marginTop: 'max(8%, 3rem)', marginBottom: 'max(8%, 3rem)' }}>
                <div className="mx-auto" style={{ maxWidth: '500px' }}>
                    <FormLogin />
                </div>
            </div>
        </Fragment>
    );
}
