import { Fragment, useState } from "react";
import { DoLoginMaster } from "../../rest/api";
import Alert from 'react-bootstrap/Alert';
import { useNavigate } from "react-router";
import { useUser } from "../../hooks/UserContext";

export default function FormLogin() {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [errore, setErrore] = useState('');
    const navigate = useNavigate();
    const [isLoading, setIsloading] = useState(false);
    const [user, setUser] = useUser();

    const DoLogin = e => {
        console.log('faccio login')
        setIsloading(true);
        let doUpdate = true;
        DoLoginMaster({
            email: email,
            password: password
        }).then(result => {
            if (doUpdate) {
                console.log(result)
                setUser(result.data);
                localStorage.setItem('login_data', JSON.stringify(result.data))
                navigate(`/master/atleti/${result.data.idatleta}`)
            }
        }).catch(error => {
            if (doUpdate) {
                console.warn(error)
                setErrore(error.messaggio)
                setIsloading(false)
            }
        })
        e.preventDefault();
        return () => doUpdate = false;
    }

    return (
        <Fragment>
            {errore && (
                <div className="mb-3">
                    <Alert variant="danger" onClose={() => setErrore('')} className="fw-bold" dismissible>
                        {errore}
                    </Alert>
                </div>
            )}
            <form onSubmit={DoLogin} className="shadow mx-auto p-3 p-sm-5 p-lg-5" style={{ backgroundColor: 'rgba(255,255,255,0.7)' }}>
                <div className="mb-3">
                    <label htmlFor="email" className="form-label">Email</label>
                    <input id="email" value={email} onChange={e => setEmail(e.target.value)} type="email" className="form-control form-control-lg" required autoFocus />
                </div>
                <div className="mb-3">
                    <label htmlFor="password" className="form-label">Password</label>
                    <input id="password" value={password} onChange={e => setPassword(e.target.value)} type="password" className="form-control form-control-lg" required />
                </div>
                <button type="submit" disabled={isLoading} className="btn btn-lg btn-dark w-100 mt-3">Entra</button>
            </form>
        </Fragment>
    );
}
