import { Fragment, useEffect, useState } from "react";
import useScrollTop from "../../hooks/useScrollTop";
import useTitle from "../../hooks/useTitle";
import {  GetTrofei } from "../../rest/api";
import Copertina from "../common/Copertina";
import Caricamento from "../common/Caricamento";
import GridTrofei from "./GridTrofei";

export default function TrofeiPage() {
    const [trofei, setTrofei] = useState();
    useTitle('Trofei Master')
    useScrollTop();

    useEffect(() => {
        let doUpdate = true;
        GetTrofei()
            .then(result => {
                if (doUpdate)
                    setTrofei(result.data)
            })
        return () => doUpdate = false;
    }, [])

    return (

        <Fragment>
            <Copertina
                titolo="Trofei Master"
                sottotitolo="Trofei conquistati dalla squadra Master nelle competizioni a livello regionale e nazionale"
                // immagine="cover-login.jpg"
                sfondo="cover-trofei.jpg"
            />
            {!trofei && <Caricamento messaggio="Caricamento dei trofei in corso" />}
            {trofei && (
                <div className='container' style={{ marginTop: 'max(8%, 3rem)', marginBottom: 'max(8%, 3rem)' }}>
                    <GridTrofei dati={trofei} />
                </div>
            )}            
        </Fragment>
    );
}
