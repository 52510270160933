import { Fragment } from "react";
import { Outlet } from "react-router";
import { useUser } from "../../hooks/UserContext";
import useScrollTop from "../../hooks/useScrollTop";
import Caricamento from "../common/Caricamento";
import Copertina from "../common/Copertina";
import MenuAdmin from "./MenuAdmin";


export default function AdminPage() {
    useScrollTop();
    const [user] = useUser();

    const isAdmin = user && user.idatleta === 1;

    if(!isAdmin) 
        return <Caricamento messaggio="Verifica utente in corso" />;

    return (
        <Fragment>
            <Copertina
                titolo="Amministratore Aquareal"
                sottotitolo="Area di gestione"
                immagine="logo-bianco2.png"
                sfondo="pool.jpg"
            />
            <MenuAdmin />
            <div className="container-lg my-0 my-md-5 py-5">
                <div className="row">
                    <div className="col">
                        <Outlet />
                    </div>
                </div>
            </div>
        </Fragment>
    );
}
