import { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { useUser } from "../../hooks/UserContext";
import '../../css/menu-secondario.css';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { BsFillGeoAltFill, BsFillPeopleFill, BsCalendar3, BsFillBookmarksFill, BsFillStarFill } from 'react-icons/bs';

export default function MenuEvento({ idevento }) {
    const [expanded, setExpanded] = useState(false);
    const [user, setUser] = useUser();

    const location = useLocation();
    const [url, setUrl] = useState(null);
    
    useEffect(() => {
        setUrl(location.pathname);
    }, [location]);

    const closeNavbar = () => {
        setExpanded(false);
    };

    return (
        <Navbar collapseOnSelect expand="lg" expanded={expanded} bg="light" variant="light" sticky="top" className="menu-secondario shadow py-0" style={{ lineHeight: '1.8', fontSize: '1.1rem' }}>
            <Container>
                <Navbar.Brand className="ps-0 py-2 py-lg-0">Menù Evento</Navbar.Brand>
                <Navbar.Toggle onClick={() => setExpanded(expanded ? false : "expanded")} />
                <Navbar.Collapse id="responsive-navbar-nav">
                    <Nav className="ms-auto pb-3 pb-lg-0">
                        <Nav.Link as={Link} onClick={closeNavbar} to={`/master/eventi/${idevento}/programma`} className={(url?.endsWith('programma') ? "subnav-attivo py-1" : 'py-1')}>Programma</Nav.Link>
                        <Nav.Link as={Link} onClick={closeNavbar} to={`/master/eventi/${idevento}/iscrizioni`} className={(url?.endsWith('iscrizioni') ? "subnav-attivo py-1" : 'py-1')}>Iscrizioni</Nav.Link>
                        <Nav.Link as={Link} onClick={closeNavbar} to={`/master/eventi/${idevento}/risultati`} className={(url?.endsWith('risultati') ? "subnav-attivo py-1" : 'py-1')}>Risultati</Nav.Link>
                        <Nav.Link as={Link} onClick={closeNavbar} to={`/master/eventi/${idevento}/piscina`} className={(url?.endsWith('piscina') ? "subnav-attivo py-1" : 'py-1')}>Piscina</Nav.Link>
                        <Nav.Link as={Link} onClick={closeNavbar} to={`/master/eventi/${idevento}/edizioni`} className={(url?.endsWith('edizioni') ? "subnav-attivo py-1" : 'py-1')}>Edizioni</Nav.Link>
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    );
}
