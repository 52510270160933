import { Fragment } from "react";
import { Link } from "react-router-dom";

const evidenzia = (testo, cosa) => {
    const reg = new RegExp(cosa, 'gi');
    return testo.replace(reg, '<span class="match-ricerca">' + cosa + '</span>');
}
const colori = {
    atleti: 'red',
    eventi: 'blue',
    articoli: 'green'
};

export default function RisultatiRicerca({ dati, filtro }) {
    console.log(dati);
    const categorie = Object.keys(dati);

    return (
        <Fragment>
            <div className='row' style={{ marginTop: 'max(8%, 3rem)', marginBottom: 'max(8%, 3rem)' }}>
                {categorie.length === 0 && (
                    <div className="text-center">
                        <h3>Nessun risultato</h3>
                    </div>
                )}
                {categorie.map(c => {
                    return (
                        <div key={c} className="col-12 mb-3 mb-lg-5">
                            <span className="badge mb-2" style={{ backgroundColor: colori[c], fontWeight: 'normal' }}>
                                {c.toUpperCase()}
                            </span>
                            {dati[c].map(x => {
                                return (
                                    <div key={x.url} className="mb-2">
                                        <Link to={x.url}><span dangerouslySetInnerHTML={{ __html: evidenzia(x.item, filtro) }}></span></Link>
                                        {x.dettagli && (
                                            <p className="mb-0" style={{ fontSize: '1rem', lineHeight: '1.2' }}>
                                                <span dangerouslySetInnerHTML={{ __html: evidenzia(x.dettagli, filtro) }}></span>
                                            </p>
                                        )}
                                    </div>
                                )
                            })}
                        </div>
                    )
                })}
            </div>
        </Fragment>
    );
}
