import { colorCircuito, printData, printDataTime } from "../../utility";

export default function TabellaGestioneTrofei({ dati, onSelectTrofeo }) {


    return (
        <table className="w-100 shadow" style={{ fontSize: '1.1rem', lineHeight: '1.2' }}>
            <thead>
                <tr className="fw-bold">
                    <th className="py-1 text-end" style={{ width: '3rem' }}>ID</th>
                    <th className="text-center py-1" style={{ width: '3rem' }}></th>
                    <th className="py-1">TROFEO</th>
                    <th className="text-center py-1 d-none d-sm-table-cell" style={{ width: '5rem' }}>DATA</th>
                    <th className="text-center py-1 d-none d-md-table-cell" style={{ width: '6rem' }}>LIVELLO</th>
                    <th className="text-center py-1 d-none d-md-table-cell" style={{ width: '3rem' }}>POS</th>
                    <th className="text-center py-1 d-none d-xl-table-cell" style={{ width: '10rem' }}>CREZIONE</th>
                    <th className="text-center py-1 d-none d-lg-table-cell" style={{ width: '10rem' }}>MODIFICA</th>
                    <th className="text-end py-1 pe-2 d-none d-lg-table-cell">NOTE</th>
                    <th className="text-center py-1" style={{ width: '2.5rem' }}></th>
                </tr>
            </thead>
            <tbody>
                {
                    dati.map(x => {
                        return (
                            <tr key={x.idtrofeo}>
                                <td className="py-1 text-end">
                                    {x.idtrofeo}
                                </td>
                                <td className="text-center py-1 px-2">
                                    <span className="badge w-100" style={{ backgroundColor: colorCircuito(x.circuito), fontWeight: 'normal' }}>
                                        {x.circuito.slice(0, 4).toUpperCase()}
                                    </span>
                                </td>
                                <td className="py-1">
                                    {x.descrizione}
                                </td>
                                <td className="text-center py-1 d-none d-sm-table-cell">
                                    {printData(x.data)}
                                </td>

                                <td className="text-center py-1 d-none d-md-table-cell">
                                    {x.livello}
                                </td>
                                <td className="text-center py-1 d-none d-md-table-cell">
                                    {x.posizione}
                                </td>
                                <td className="text-center py-1 d-none d-xl-table-cell">
                                    {printDataTime(x.creazione, 2)}
                                </td>
                                <td className="text-center py-1 d-none d-lg-table-cell">
                                    {printDataTime(x.modifica, 2)}
                                </td>
                                <td className="text-end py-1 pe-2 d-none d-lg-table-cell">
                                    {x.note ?? '-'}
                                </td>
                                <td className="text-center py-1">
                                    <button
                                        onClick={() => onSelectTrofeo(x.idtrofeo)}
                                        className="btn btn-primary px-2 py-0">
                                        ...
                                    </button>
                                </td>
                            </tr>
                        )
                    })
                }
            </tbody>
        </table>
    )

}
