import { Fragment, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import useScrollTop from "../../hooks/useScrollTop";
import { GetRecordSocietariMaster, GetTempiAtletaMaster } from "../../rest/api";
import Caricamento from "../common/Caricamento";
import LegendaRecord from "../common/LegendaRecord";
import ContentTempiAtletaMaster from "./ContentTempiAtletaMaster";


export default function TempiAtletaMaster() {
    let param = useParams();
    const [tempi, setTempi] = useState();
    const [record, setRecord] = useState();
    useScrollTop();

    useEffect(() => {
        let doUpdate = true;
        GetTempiAtletaMaster(param.idatleta)
            .then(result => {
                if (doUpdate)
                    setTempi(result.data);
            })
        return () => doUpdate = false;
    }, [param.idatleta])

    useEffect(() => {
        let doUpdate = true;
        GetRecordSocietariMaster()
            .then(result => {
                if (doUpdate)
                    setRecord(result.data);
            })
        return () => doUpdate = false;
    }, [])

    console.log(tempi)

    return (
        <Fragment>
            <div className="text-center mb-4">
                <h2>Risultati personali</h2>
                <p>
                    La tabella riassume tutte le prestazioni dell'atleta, comprese le staffette. In verde sono evidenziati i tempi realizzati in questa stagione mentre in giallo quelli societari. Nel calcolo dei record sono tenute in considerazione anche le prime frazioni delle staffette.
                </p>
                <LegendaRecord />
            </div>
            {!tempi && <Caricamento messaggio="Caricamento dei risultati in corso" />}
            {tempi && (
                <div className="mx-auto" style={{ maxWidth: '1100px' }}>
                    <ContentTempiAtletaMaster dati={tempi} recordSocietari={record} />
                </div>
            )}
        </Fragment>
    );
}
