import { Fragment, useEffect, useRef, useState } from "react";
import useScrollTop from "../../hooks/useScrollTop";
import useTitle from "../../hooks/useTitle";
import Copertina from "../common/Copertina";
import '../../css/articoli.css';
import Caricamento from "../common/Caricamento";
import RisultatiRicerca from "./RisultatiRicerca";
import { DoRicerca } from "../../rest/api";

const MIN_FILTRO = 3;

export default function RicercaPage() {
    const [filtro, setFiltro] = useState('');
    const [risultati, setRisultati] = useState();
    const requestId = useRef(0);
    useTitle('Ricerca');
    useScrollTop();

    const [isLoading, setIsLoading] = useState(false);
    const [messaggio, setMessaggio] = useState();
    const [errore, setErrore] = useState();

    useEffect(() => {
        let doUpdate = true;
        if (filtro.length >= MIN_FILTRO) {
            requestId.current = requestId.current + 1;
            console.log('parte ricerca di ' + filtro + ' con id ' + requestId.current);
            const dati = {
                filtro: filtro,
                id: requestId.current
            }
            setErrore(null);
            setMessaggio(null);
            setIsLoading(true);
            DoRicerca(dati)
                .then(result => {
                    if (doUpdate && result.data.id === requestId.current) {
                        setRisultati(result.data.risultati);
                    }
                    console.log(result.data);
                })
                .catch(err => setErrore(err))
                .finally(() => setIsLoading(false));
        }
        else {
            setRisultati(null);
        }
        return () => doUpdate = false;
    }, [filtro])


    return (
        <Fragment>
            <Copertina
                titolo="Ricerca"
                sottotitolo={"Ricerca un atleta, un evento o un articolo immettendo il testo nella ricerca (almeno " + MIN_FILTRO + " lettere)"}
                // immagine="cover-login.jpg"
                sfondo="cover-ricerca.jpg"
            />
            <div className='container' style={{ marginTop: 'max(8%, 3rem)', marginBottom: 'max(8%, 3rem)' }}>
                <div className="row">
                    <div className="col">
                        <div className="pannello p-4 p-lg-5 mx-auto" style={{ maxWidth: '700px' }}>
                            <h2 className="text-center mb-4">
                                Ricerca
                            </h2>
                            <input type="search" placeholder="Ricerca tra gli atleti, eventi e articoli" className="form-control mb-3" value={filtro} onChange={e => setFiltro(e.target.value)} style={{ color: filtro.length < MIN_FILTRO ? 'gainsboro' : 'black' }} />
                            {!risultati && isLoading && <Caricamento messaggio="Ricerca in corso" />}
                            {risultati && <RisultatiRicerca dati={risultati} filtro={filtro} />}
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    );
}
