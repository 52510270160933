import { Fragment, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import useScrollTop from "../../hooks/useScrollTop";
import { GetPuntiMaxAtletaMaster, GetRecordSocietariMaster } from "../../rest/api";
import Caricamento from "../common/Caricamento";
import LegendaRecord from "../common/LegendaRecord";
import ContentPuntiMaxAtletaMaster from "./ContentPuntiMaxAtletaMaster";


export default function PuntiMaxAtletaMaster() {
    let param = useParams();
    const [record, setRecord] = useState();
    const [recordSocietari, setRecordSocietari] = useState();
    useScrollTop();

    useEffect(() => {
        let doUpdate = true;
        GetPuntiMaxAtletaMaster(param.idatleta)
            .then(result => {
                if (doUpdate)
                    setRecord(result.data);
            })
        return () => doUpdate = false;
    }, [param.idatleta])

    useEffect(() => {
        let doUpdate = true;
        GetRecordSocietariMaster()
            .then(result => {
                if (doUpdate)
                    setRecordSocietari(result.data);
            })
        return () => doUpdate = false;
    }, [])

    console.log(record)

    return (
        <div className="mx-auto" style={{ maxWidth: '1000px' }}>
            <div className="text-center mb-4">
                <h2>Punteggi massimi</h2>
                <p>
                    La tabella riassume i punteggi FIN dell'atleta per ogni gara individuale.
                </p>
                <LegendaRecord tipo={"stagionale"} />
            </div>
            {!record && <Caricamento messaggio="Caricamento dei record in corso" />}
            {record && (
                <ContentPuntiMaxAtletaMaster dati={record} recordSocietari={recordSocietari} />
            )}
        </div>
    );
}
