import { Fragment, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import useScrollTop from "../../hooks/useScrollTop";
import { GetProgressioneAtletaMaster, GetRecordSocietariMaster } from "../../rest/api";
import Caricamento from "../common/Caricamento";
import ContentProgressioneAtletaMaster from "./ContentProgressioneAtletaMaster";


export default function ProgressioneAtletaMaster() {
    let param = useParams();
    const [tempi, setTempi] = useState();
    const [record, setRecord] = useState();
    useScrollTop();

    useEffect(() => {
        let doUpdate = true;
        GetProgressioneAtletaMaster(param.idatleta)
            .then(result => {
                if (doUpdate)
                    setTempi(result.data);
            })
        return () => doUpdate = false;
    }, [param.idatleta])

    useEffect(() => {
        let doUpdate = true;
        GetRecordSocietariMaster()
            .then(result => {
                if (doUpdate)
                    setRecord(result.data);
            })
        return () => doUpdate = false;
    }, [])

    console.log(tempi)

    return (
        <Fragment>
            <div className="text-center mb-4">
                <h2>Progressione categorie</h2>
                <p className="mb-5">
                    La tabella riassume i record dell'atleta per ogni gara e per ogni categoria. In giallo sono evidenziati i record societari. Nel calcolo dei record sono tenute in considerazione anche le prime frazioni delle staffette.
                </p>
            </div>
            {!tempi && <Caricamento messaggio="Caricamento dei tempi in corso" />}
            {tempi && (
                <div className="mx-auto" style={{ maxWidth: '1000px' }}>
                    <ContentProgressioneAtletaMaster dati={tempi} recordSocietari={record} />
                </div>
            )}
        </Fragment>
    );
}
