import { colorCircuito, colorGara, printData, printTempo } from "../../utility";
import { BsFillSquareFill, BsQuestionCircleFill, BsSearch } from 'react-icons/bs';
import { Link } from "react-router-dom";
import { Fragment, useState } from "react";
import Riepilogo from "../common/Riepilogo";
import IconaSessione from "../common/IconaSessione";
import { GetRecordSocietariMaster } from "../../rest/api";

export default function TabellaRecordAtleta({ dati, recordSocietari, showMore = true }) {
    const [selezionato, setSelezionato] = useState(null);

    if (dati.length === 0) {
        return (
            <div className="text-center my-5">
                <h3>Non ci sono tempi da mostrare</h3>
            </div>
        )
    }

    return (
        <Fragment>
            <table className="w-100 shadow" style={{ fontSize: '1.1rem', lineHeight: '1.2' }}>
                <thead>
                    <tr className="fw-bold">
                        <th className="text-end py-1" style={{ width: '15rem' }}>GARA</th>
                        <th className="text-end py-1 px-2" style={{ width: '7rem' }}>TEMPO</th>
                        <th className="py-1 d-none d-sm-table-cell" style={{ width: '3rem' }}></th>
                        <th className="ps-2 py-1 d-none d-sm-table-cell">EVENTO</th>
                        <th className="text-center py-1 d-none d-lg-table-cell" style={{ width: '6rem' }}>CAT</th>
                        <th className="text-center py-1" style={{ width: '9rem' }}>DATA</th>
                        <th className="text-center py-1 d-none d-lg-table-cell" style={{ width: '4rem' }}>PUNTI</th>
                        <th className="py-1 d-table-cell d-lg-none" style={{ width: '2rem' }}></th>
                    </tr>
                </thead>
                <tbody>
                    {dati.map(x => {
                        const is_societario = recordSocietari && recordSocietari.findIndex(t => t.idtempo === x.idtempo) !== -1;
                        return (
                            <tr key={x.gara} className={x.stagionale && is_societario ? 'row-societario-stagionale' : (x.stagionale ? 'row-active' : (is_societario ? 'row-societario' : ''))}>
                                <td className="text-end py-1">
                                    {x.gara} <span className="d-none d-sm-inline"><BsFillSquareFill style={{ color: colorGara(x.gara) }} /></span>
                                </td>
                                <td className="text-end px-2 py-1">
                                    {x.is_frazione ? <BsQuestionCircleFill className="position-relative" style={{ top: '-2px' }} title="Record realizzato in staffetta" /> : null} {printTempo(x.record, false)}
                                </td>
                                <td className="py-1 text-end d-none d-sm-table-cell">
                                    <span className="badge w-100 shadow" style={{ backgroundColor: colorCircuito(x.circuito), fontWeight: 'normal' }}>
                                        {x.circuito.slice(0, 4).toUpperCase()}
                                    </span>
                                </td>
                                <td className="px-2 py-1 d-none d-sm-table-cell" style={{ minWidth: '17rem' }}>
                                    {x.evento_visibile ?
                                        <Link to={`/master/eventi/${x.idevento}`}>{x.evento} <span className="d-none d-lg-inline"> ({x.piscina})</span></Link> :
                                        <Fragment>
                                            {x.evento} <span className="d-none d-lg-inline"> ({x.piscina})</span>
                                        </Fragment>
                                    }
                                </td>
                                <td className="text-center px-2 py-1 d-none d-lg-table-cell">
                                    {x.categoria.replace('-', '/')}
                                </td>
                                <td className="text-center px-0 px-lg-2 py-1">
                                    {printData(x.data, 2)}
                                    <span className="d-none d-lg-inline">
                                        <IconaSessione sessione={x.sessione} />
                                    </span>
                                </td>
                                <td className="text-center py-1 d-none d-lg-table-cell">
                                    {x.punti ?? '-'}
                                </td>
                                <td className="text-center py-1 d-table-cell d-lg-none">
                                    <button onClick={() => setSelezionato(x)} className="btn btn-dark px-1 py-0">
                                        <BsSearch />
                                    </button>
                                </td>
                            </tr>
                        )
                    }
                    )}
                </tbody>
            </table>
            {
                selezionato && <Riepilogo dati={
                    {
                        gara: selezionato.gara,
                        evento: selezionato.evento,
                        piscina: selezionato.piscina,
                        circuito: selezionato.circuito,
                        data: printData(selezionato.data) + ' ' + (selezionato.sessione === 'M' ? 'Mattina' : 'Pomeriggio'),
                        vasca: selezionato.vasca + 'm',
                        categoria: selezionato.categoria,
                        record: printTempo(selezionato.record),
                        posizione: selezionato.posizione,
                        punti: selezionato.punti
                    }
                } titolo={'Tempo'} onClose={() => setSelezionato(null)} />
            }
        </Fragment >
    );
}
