export default function IconaSessione({ sessione }) {
    const nome_icona = sessione === 'M' ? 'sole' : 'luna';
    return (
        <img
            src={process.env.PUBLIC_URL + '/assets/' + nome_icona + '.png'}
            className="img-fluid position-relative ms-1"
            style={{ "width": "1.4rem", top: '-1px' }}
            alt=''
        />
    );
}
