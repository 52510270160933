import FormAtleta from "./FormAtleta";

export default function ContentAtleta({ dati, onUpdateAtleta }) {

    return (
        <FormAtleta dati={dati} onUpdateAtleta={onUpdateAtleta} />
        // <Tabs
        //     defaultActiveKey="dati"
        //     className="mb-3"
        //     fill
        //     variant="pills"
        // >
        //     <Tab eventKey="dati" title="Informazioni">
        //     <FormAtleta dati={dati} onUpdateAtleta={onUpdateAtleta} />
        //     </Tab>
        //     <Tab eventKey="foto" title="Foto">
        //         <EditorFotoAtleta dati={dati} onUpdateAtleta={onUpdateAtleta} />
        //     </Tab>
        //     <Tab eventKey="thumb" title="Thumb">
        //         sss
        //     </Tab>
        // </Tabs>
    );
}
