import { Fragment, useEffect, useState } from "react";
import { useUser } from "../../hooks/UserContext";
import useScrollTop from "../../hooks/useScrollTop";
import useTitle from "../../hooks/useTitle";
import { GetCircuiti, GetAnagraficheEventiAdmin, GetAnagraficaPiscine } from "../../rest/api";
import Caricamento from "../common/Caricamento";
import Modal from 'react-bootstrap/Modal';
import TabellaGestioneEventi from "./TabellaGestioneEventi";
import FormEvento from "./FormEvento";


export default function GestioneEventiPage() {
    const [eventi, setEventi] = useState();
    const [nomiEventi, setNomiEventi] = useState();
    const [piscine, setPiscine] = useState();
    const [circuiti, setCircuiti] = useState();
    const [ideventoSelezionato, setIdeventoSelezionato] = useState(-1);
    const [counterModifiche, setCounterModifiche] = useState(0);
    const [show, setShow] = useState(false);

    useTitle('Gestione Eventi')
    useScrollTop();
    const [user] = useUser();

    useEffect(() => {
        let doUpdate = true;
        GetAnagraficheEventiAdmin()
            .then(result => {
                if (doUpdate) {
                    setEventi(result.data.eventi);
                    setNomiEventi(result.data.nomi);
                }
            })
        return () => doUpdate = false;
    }, [counterModifiche])

    useEffect(() => {
        let doUpdate = true;
        GetAnagraficaPiscine()
            .then(result => {
                if (doUpdate)
                    setPiscine(result.data);
            })
        return () => doUpdate = false;
    }, [])

    useEffect(() => {
        let doUpdate = true;
        GetCircuiti()
            .then(result => {
                if (doUpdate)
                    setCircuiti(result.data);
            })
        return () => doUpdate = false;
    }, [])

    const selectEvento = a => {
        console.log(a)
        console.log('seleziono evento ' + a);
        setIdeventoSelezionato(a);
        setShow(true);
    };

    const aggiornaEventi = (idevento, azione) => {
        if (azione === 'new') {
            setIdeventoSelezionato(idevento);
        }
        else if (azione === 'delete') {
            setShow(false);
            setIdeventoSelezionato(-1);
        }
        setCounterModifiche(val => val + 1);
        console.log(idevento)
        console.log(azione.toUpperCase());
    };

    const eventoSelezionato = eventi?.find(x => x.idevento === ideventoSelezionato);

    console.log(eventi)
    console.log(eventoSelezionato)
    return (
        <Fragment>
            {!eventi && <Caricamento messaggio="Caricamento eventi in corso" />}
            {eventi && circuiti && piscine && (
                <Fragment>
                    <button className="btn mb-5 w-100 btn-lg btn-dark" onClick={() => {
                        setIdeventoSelezionato(-1);
                        setShow(true);
                    }}>Nuovo evento</button>

                    <h2 className="text-center mb-4">Eventi stagione corrente</h2>
                    <TabellaGestioneEventi dati={eventi} onSelectEvento={selectEvento} />


                    <Modal
                        show={show}
                        onHide={() => setShow(false)}
                        scrollable
                        backdrop="static"
                        keyboard={false}
                        size="lg"
                    >
                        <Modal.Header closeButton closeVariant='white' style={{ backgroundColor: 'rgba(0,0,0,0.85)', color: 'white' }}>
                            <Modal.Title className="w-100 text-center">
                                {eventoSelezionato ? `${eventoSelezionato.idevento} ${eventoSelezionato.nome}` : `Creazione Evento`}
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <FormEvento
                                dati={eventoSelezionato}
                                onUpdateEvento={aggiornaEventi}
                                piscine={piscine}
                                circuiti={circuiti}
                                nomiEventi={nomiEventi}
                            />
                        </Modal.Body>
                    </Modal>
                </Fragment>
            )
            }
        </Fragment>
    );
}
