import { Fragment, useEffect, useState } from "react";
import { useUser } from "../../hooks/UserContext";
import useScrollTop from "../../hooks/useScrollTop";
import useTitle from "../../hooks/useTitle";
import Caricamento from "../common/Caricamento";
import { useNavigate } from "react-router";
import { GetAnagraficaArticoli } from "../../rest/api";
import TabellaGestioneArticoli from "./TabellaGestioneArticoli";


export default function GestioneArticoliPage() {
    const [articoli, setArticoli] = useState();
    const navigate = useNavigate();

    useTitle('Gestione Articoli')
    useScrollTop();
    const [user] = useUser();

    useEffect(() => {
        let doUpdate = true;
        GetAnagraficaArticoli()
            .then(result => {
                if (doUpdate)
                    setArticoli(result.data);
            })
        return () => doUpdate = false;
    }, [])

    console.log(articoli)
    return (
        <Fragment>
            {!articoli && <Caricamento messaggio="Caricamento articoli in corso" />}
            {articoli && (
                <Fragment>
                    <button className="btn mb-5 w-100 btn-lg btn-dark" onClick={() => navigate('/master/gestione/articoli/-1')}>
                        Nuovo articolo
                    </button>

                    <h2 className="text-center mb-4">Articoli</h2>
                    <TabellaGestioneArticoli dati={articoli} />
                </Fragment>
            )
            }
        </Fragment>
    );
}
