import { colorCircuito } from "../../utility";



export default function ContentMedagliere({ dati }) {
    console.log(dati)

    if (!dati)
        return null;

    if (dati.length === 0) {
        return (
            <div className="text-center my-5">
                <h3>Non ci sono medaglie da mostrare</h3>
            </div>
        )
    }

    const circuiti = Array.from(new Set(dati.map(x => x.circuito)));
    const posizioni = [1, 2, 3];

    return (
        <table className="w-100 shadow" style={{ fontSize: '1.1rem', lineHeight: '1.2' }}>
            <thead>
                <tr className="fw-bold">
                    <th className="text-center py-1" style={{ maxWidth: '6rem' }}></th>
                    {circuiti.map(x => (
                        <th key={x} className="text-end pe-2 py-2">
                            <span className="badge fw-bold" style={{ fontSize: '1.2rem', backgroundColor: colorCircuito(x), fontWeight: 'normal' }}>
                                {x.toUpperCase()}
                            </span>
                        </th>
                    ))}
                </tr>
            </thead>
            <tbody style={{ verticalAlign: 'top' }}>
                {posizioni.map(p => {
                    return (
                        <tr key={p}>
                            <td className="text-center p-1" style={{ verticalAlign: 'middle' }}>
                                <img
                                    src={`${process.env.PUBLIC_URL + '/assets/medaglia' + p + '.png'}`}
                                    className="img-fluid"
                                    style={{ "maxHeight": "5rem" }}
                                    alt=''
                                />
                            </td>
                            {circuiti.map(c => {
                                const medaglie_trovate = dati
                                    .filter(x => x.circuito === c && x.posizione === p)
                                    .sort((a, b) => b.individuale - a.individuale)
                                    .sort((a, b) => b.quante - a.quante);
                                return (
                                    <td key={c} className="text-end p-2 p-lg-3">
                                        <h3 className="fw-bold">
                                            {medaglie_trovate.length} medagli{medaglie_trovate.length !== 1 ? 'e' : 'a'}
                                        </h3>
                                        {medaglie_trovate.map(x => (
                                            <p key={x.gara} className="mb-1">
                                                {x.gara} ({x.quante})
                                            </p>
                                        ))}
                                    </td>
                                )
                            })}
                        </tr>
                    )
                }
                )}
            </tbody>
        </table>
    );
}
