import { printDataTime } from "../../utility";
import { BsEyeFill, BsEyeSlashFill } from 'react-icons/bs';

export default function TabellaGestionePiscine({ dati, onSelectPiscina }) {


    return (
        <table className="w-100 shadow" style={{ fontSize: '1.1rem', lineHeight: '1.2' }}>
            <thead>
                <tr className="fw-bold">
                    <th className="py-1 text-end" style={{ width: '3rem' }}>ID</th>
                    <th className="ps-2 py-1" style={{ width: '13rem' }}>NOME</th>
                    <th className="ps-2 py-1 d-none d-sm-table-cell">INDIRIZZO</th>
                    <th className="py-1 d-none d-sm-table-cell" style={{width: '2rem'}}></th>
                    <th className="text-center py-1 d-none d-xl-table-cell" style={{ width: '3rem' }}>GPS</th>
                    <th className="text-center py-1 d-none d-xl-table-cell" style={{ width: '4rem' }}>ZOOM</th>
                    <th className="text-center py-1 d-none d-lg-table-cell" style={{ width: '10rem' }}>CREAZIONE</th>
                    <th className="text-center py-1 d-none d-md-table-cell" style={{ width: '10rem' }}>MODIFICA</th>
                    <th className="text-end py-1 pe-2 d-none d-md-table-cell">NOTE</th>
                    <th className="text-center py-1" style={{ width: '2.5rem' }}></th>
                </tr>
            </thead>
            <tbody>
                {
                    dati.map(x => {
                        return (
                            <tr key={x.idpiscina}>
                                <td className="py-1 text-end">
                                    {x.idpiscina}
                                </td>
                                <td className="py-1 ps-2">
                                    {x.nome}
                                </td>
                                <td className="ps-2 py-1 d-none d-sm-table-cell">
                                    {x.indirizzo ?? '-'}
                                </td>
                                <td className="text-center py-1 d-none d-sm-table-cell">
                                    {x.has_foto == true && (<BsEyeFill style={{ color: 'forestgreen' }} />)}
                                    {x.has_foto == false && (<BsEyeSlashFill style={{ color: 'crimson' }} />)}
                                </td>
                                <td className="text-center py-1 d-none d-xl-table-cell">
                                    {x.latitudine};{x.longitudine}
                                </td>
                                <td className="text-center py-1 d-none d-xl-table-cell">
                                    {x.zoom}
                                </td>
                                <td className="text-center py-1 d-none d-lg-table-cell">
                                    {printDataTime(x.creazione, 2)}
                                </td>
                                <td className="text-center py-1 d-none d-md-table-cell">
                                    {printDataTime(x.modifica, 2)}
                                </td>
                                <td className="text-end py-1 pe-2 d-none d-md-table-cell">
                                    {x.note ?? '-'}
                                </td>
                                <td className="text-center py-1">
                                    <button
                                        onClick={() => onSelectPiscina(x.idpiscina)}
                                        className="btn btn-primary px-2 py-0">
                                        ...
                                    </button>
                                </td>
                            </tr>
                        )
                    })
                }
            </tbody>
        </table>
    )

}
