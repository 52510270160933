import { printDataTime } from "../../utility";
import { BsFillSquareFill } from 'react-icons/bs';
import {FaUserAlt, FaUserCircle} from 'react-icons/fa';
import { Link } from "react-router-dom";

export default function TabellaGestioneAtleti({ dati, onSelectAtleta }) {


    return (
        <table className="w-100 shadow" style={{ fontSize: '1.1rem', lineHeight: '1.2' }}>
            <thead>
                <tr className="fw-bold">
                    <th className="py-1 text-end" style={{ width: '3rem' }}>ID</th>
                    <th className="ps-2 py-1">ATLETA</th>
                    <th className="text-center py-1 d-none d-sm-table-cell" style={{ width: '5rem' }}>ANNO</th>
                    <th className="text-center py-1 d-none d-xl-table-cell" style={{ width: '3rem' }}>CAT</th>
                    <th className="text-center py-1" style={{ width: '3rem' }}>FOTO</th>
                    <th className="text-center py-1 d-none d-xl-table-cell" style={{ width: '6rem' }}>STAGIONI</th>
                    <th className="text-center py-1 d-none d-lg-table-cell" style={{ width: '10rem' }}>CREAZIONE</th>
                    <th className="text-center py-1 d-none d-md-table-cell" style={{ width: '10rem' }}>MODIFICA</th>
                    <th className="text-end py-1 pe-2 d-none d-md-table-cell">NOTE</th>
                    <th className="text-center py-1" style={{ width: '2.5rem' }}></th>
                </tr>
            </thead>
            <tbody>
                {
                    dati.map(x => {
                        return (
                            <tr key={x.idatleta}>
                                <td className="py-1 text-end">
                                    {x.idatleta}
                                </td>
                                <td className="ps-2 py-1">
                                    <BsFillSquareFill className="me-1 position-relative" style={{ top: '-2px', color: x.sesso === 'M' ? 'blue' : 'deeppink' }} />
                                    <Link to={`/master/atleti/${x.idatleta}`}>{x.cognome.toUpperCase()} {x.nome}</Link>
                                </td>
                                <td className="text-center py-1 d-none d-sm-table-cell">
                                    {x.anno}
                                </td>
                                <td className="text-center py-1 d-none d-xl-table-cell">
                                    {x.categoria}
                                </td>
                                <td className="text-center py-1">
                                  <FaUserAlt style={{color: x.has_foto? 'forestgreen': 'crimson'}} />
                                  <FaUserCircle style={{color: x.has_thumb? 'forestgreen': 'crimson'}} />
                                </td>
                                <td className="text-center py-1 d-none d-xl-table-cell">
                                    {x.stagioni > 0 ? x.stagioni : '-'}
                                </td>
                                <td className="text-center py-1 d-none d-lg-table-cell">
                                    {printDataTime(x.creazione, 2)}
                                </td>
                                <td className="text-center py-1 d-none d-md-table-cell">
                                    {printDataTime(x.modifica, 2)}
                                </td>
                                <td className="text-end py-1 pe-2 d-none d-md-table-cell">
                                    {x.note ?? '-'}
                                </td>
                                <td className="text-center py-1">
                                    <button
                                        onClick={() => onSelectAtleta(x.idatleta)}
                                        className="btn btn-primary px-2 py-0">
                                        ...
                                    </button>
                                </td>
                            </tr>
                        )
                    })
                }
            </tbody>
        </table>
    )

}
