import axios from 'axios';

const protocollo = window.location.protocol;
const baseURL = protocollo === 'http:' ? `http://localhost:5000/api`: `https://www.aquareal.eu/master_team/api`; 

const instance = axios.create({
    baseURL: baseURL,
    withCredentials: false
});

instance.defaults.headers.common = {
    "Content-Type": "application/json"
  }

function AddAuthHeader(config) {
    const dati = localStorage.getItem('login_data');
    // config.headers['Content-Type'] = 'application/json';
    // config.headers['Allow-Control-Request-Headers'] = 'X-Extra';
    if (dati) {
        const token = JSON.parse(dati).token;
        config.headers['X-Extra'] = token;
    }
    return config;
}

function gestioneErrore (error) {
    console.log("error", error)
    if (error.code === "ERR_NETWORK") {
        console.log('Cannot establish connection to server!');
    } else if (error.response) {
        // console.log(error.response.data.data.messaggio)
        // if (error.response.status === 401) {
        //     window.location.href = `${baseURL}\master\login`;
        // }
        // if (error.response.data) {
        //     console.log(error.response.data.message);
        // } else {
        //     console.log('Cannot establish connection to server!');
        // }
    }
    const errobj = error.response.data.data;
    return Promise.reject(errobj.messaggio + (errobj.dettagli ? ' (' +errobj.dettagli.toLowerCase() + ')': ''));
}

instance.interceptors.request.use((req) => {
    req = AddAuthHeader(req);
    return req;
})

instance.interceptors.response.use((res) => {
    return res.data;
}, gestioneErrore);

export default instance;