import { colorCircuito, printDataTime } from "../../utility";
import { BsEyeFill, BsEyeSlashFill } from 'react-icons/bs';
import { MdTimer } from 'react-icons/md';
import { Link, useNavigate } from "react-router-dom";

export default function TabellaGestioneEventi({ dati, onSelectEvento }) {
    const navigate = useNavigate();

    return (
        <table className="w-100 shadow" style={{ fontSize: '1.1rem', lineHeight: '1.2' }}>
            <thead>
                <tr className="fw-bold">
                    <th className="py-1 text-end" style={{ width: '3rem' }}>ID</th>
                    <th className="px-1 py-1" style={{ width: '3.3rem' }}></th>
                    <th className="ps-1 py-1" style={{ width: '18rem' }}>EVENTO</th>
                    <th className="text-center py-1 d-none d-md-table-cell" style={{ width: '2rem' }}></th>
                    {/* <th className="text-center py-1" style={{ width: '6rem' }}>SCADENZA</th> */}
                    <th className="text-center py-1 d-none d-sm-table-cell" style={{ width: '3rem' }}>VASCA</th>
                    <th className="text-center py-1 d-none d-xl-table-cell" style={{ width: '5rem' }}>TEMPI</th>
                    <th className="text-center py-1 d-none d-lg-table-cell" style={{ width: '10rem' }}>CREAZIONE</th>
                    <th className="text-center py-1 d-none d-md-table-cell" style={{ width: '10rem' }}>MODIFICA</th>
                    <th className="text-end py-1 pe-2 d-none d-md-table-cell">NOTE</th>
                    <th className="text-center py-1" style={{ width: '4.5rem' }}></th>
                </tr>
            </thead>
            <tbody>
                {
                    dati.map(x => {
                        return (
                            <tr key={x.idevento}>
                                <td className="py-1 text-end">
                                    {x.idevento}
                                </td>
                                <td className="py-1 px-1">
                                    <span className="badge w-100" style={{ backgroundColor: colorCircuito(x.circuito), fontWeight: 'normal' }}>
                                        {x.circuito.slice(0, 4).toUpperCase()}
                                    </span>
                                </td>
                                <td className="ps-1 py-1">
                                    <Link to={`/master/eventi/${x.idevento}`}>{x.nome.toUpperCase()}</Link><br />
                                    {x.piscina}
                                </td>
                                <td className="text-center py-1 d-none d-md-table-cell">
                                    {x.visibile == true && (<BsEyeFill style={{ color: 'forestgreen' }} />)}
                                    {x.visibile == false && (<BsEyeSlashFill style={{ color: 'crimson' }} />)}
                                </td>
                                {/* <td className="text-center py-1 d-none d-xl-table-cell">
                                    {printData(x.scadenza)}
                                </td> */}
                                <td className="text-center py-1 d-none d-sm-table-cell">
                                    {x.vasca}m
                                </td>
                                <td className="text-center py-1 d-none d-xl-table-cell">
                                    {x.iscrizioni}
                                </td>
                                <td className="text-center py-1 d-none d-lg-table-cell">
                                    {printDataTime(x.creazione, 2)}
                                </td>
                                <td className="text-center py-1 d-none d-md-table-cell">
                                    {printDataTime(x.modifica, 2)}
                                </td>
                                <td className="text-end py-1 pe-2 d-none d-md-table-cell">
                                    {x.note ?? '-'}
                                </td>
                                <td className="text-center py-1">
                                    <button
                                        onClick={() => onSelectEvento(x.idevento)}
                                        className="btn btn-primary px-2 py-0 me-1">
                                        ...
                                    </button>
                                    <button
                                        onClick={() => navigate('/master/gestione/programmi/' + x.idevento)}
                                        className="btn btn-dark px-1 py-0">
                                        <MdTimer />
                                    </button>
                                </td>
                            </tr>
                        )
                    })
                }
            </tbody>
        </table>
    )

}
