import { Fragment } from "react";
import { useUser } from "../../hooks/UserContext";
import useScrollTop from "../../hooks/useScrollTop";
import useTitle from "../../hooks/useTitle";
import Copertina from "../common/Copertina";

export default function CollaboratoriPage() {
    useTitle('Collaboratori')
    useScrollTop();
    const [user] = useUser();

    return (
        <Fragment>
            <Copertina
                titolo="Collaboratori Aquareal"
                sottotitolo="Diverse figure specializzare collaborano con gli atleti Aquareal per migliorare i risultati degli allenamenti"
                // immagine="logo-bianco2.png"
                sfondo="cover-collaboratori.jpg"
            />



            <div className="container" style={{ marginTop: 'max(8%, 3rem)', marginBottom: 'max(8%, 3rem)' }}>

                <div className="row justify-content-center row-cols-1 row-cols-lg-3 g-4">

                    <div className="col">
                        <div className="card shadow position-relative h-100">
                            <div className="zoom">
                                <img
                                    src={process.env.PUBLIC_URL + '/assets/giannini.jpg'}
                                    loading="lazy"
                                    className="card-img-top"
                                    alt="..." />
                            </div>
                            <div className="card-body text-center">
                                <h5 className="card-title fw-bold">Giorgio Giannini</h5>
                                <p className="card-text" style={{ fontSize: '1rem' }}>
                                    Specializzato in Osteopatia, Kinesioterapia, Fisioterapia, Massofisioterapia, Idrokinesiterapia
                                </p>
                            </div>
                            {/* <div className="card-footer p-0 d-flex">
                                <button onClick={() => console.log('ok')} className="btn btn-dark w-100" style={{ borderRadius: '0' }}>
                                    Vai al sito del circolo
                                </button>
                            </div> */}
                        </div>
                    </div>

                    <div className="col">
                        <div className="card shadow position-relative h-100">
                            <div className="zoom">
                                <img
                                    src={process.env.PUBLIC_URL + '/assets/torrisi.jpg'}
                                    loading="lazy"
                                    className="card-img-top"
                                    alt="..." />
                            </div>
                            <div className="card-body text-center">
                                <h5 className="card-title fw-bold">Loredana Torrisi</h5>
                                <p className="card-text" style={{ fontSize: '1rem' }}>
                                    Dietista e nutrizionista sportiva
                                </p>
                            </div>
                            {/* <div className="card-footer p-0 d-flex">
                                <button onClick={() => console.log('ok')} className="btn btn-dark w-100" style={{ borderRadius: '0' }}>
                                    Vai al sito del circolo
                                </button>
                            </div> */}
                        </div>
                    </div>

                </div>
            </div>


        </Fragment>
    );
}
