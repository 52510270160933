import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import TabellaTempiAtletaMaster from './TabellaTempiAtletaMaster';

export default function ContentTempiAtletaMaster({ dati, recordSocietari }) {
    if(!dati)
        return null;

    const tempi25 = dati.filter(x => x.vasca === 25)
    const tempi50 = dati.filter(x => x.vasca === 50)

    return (
        <Tabs
            defaultActiveKey="v25"
            id="fill-tab-example"
            className="mb-3"
            fill
            variant="pills"
        >
            <Tab eventKey="v25" title="Vasca 25m">
                <TabellaTempiAtletaMaster dati={tempi25} recordSocietari={recordSocietari} />
            </Tab>
            <Tab eventKey="v50" title="Vasca 50m">
                <TabellaTempiAtletaMaster dati={tempi50} recordSocietari={recordSocietari} />
            </Tab>
        </Tabs>
    );
}
