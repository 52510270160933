import { colorCircuito, iconaGara, printData, printTempo } from "../../utility";
import { BsSearch, BsQuestionCircleFill } from 'react-icons/bs';
import { Link, useParams } from "react-router-dom";
import Riepilogo from "../common/Riepilogo";
import { Fragment, useState } from "react";
import IconaSessione from "../common/IconaSessione";
import { useUser } from "../../hooks/UserContext";

export default function TabellaTempiAtletaMaster({ dati, recordSocietari }) {
    const [selezionato, setSelezionato] = useState(null);
    const params = useParams();
    const [user] = useUser();
    const gare = Array.from(new Set(dati.map(x => x.gara)));
    console.log(selezionato);
    console.log(user)

    if (gare.length === 0) {
        return (
            <div className="text-center my-5">
                <h3>Non ci sono tempi da mostrare</h3>
            </div>
        )
    }

    return (
        <Fragment>
            {gare.map(g => {
                const tempi_gara = dati.filter(x => x.gara === g);
                const num_tempi_gara = tempi_gara.length;
                return (
                    <div key={g}>
                        <h2 className="mt-5 fw-bold">
                            <img src={process.env.PUBLIC_URL + '/assets/' + iconaGara(g)} className="img-fluid me-2" style={{ height: '2.8rem', position: 'relative', top: '-2px' }} alt="" />
                            {g}
                        </h2>
                        <table className="w-100 shadow" style={{ fontSize: '1.1rem', lineHeight: '1.2' }}>
                            <thead>
                                <tr className="fw-bold">
                                    <th className="py-1 d-none d-sm-table-cell" style={{ width: '3rem' }}></th>
                                    <th className="ps-2 py-1" style={{ minWidth: '13rem' }}>EVENTO</th>
                                    <th className="text-center py-1 d-none d-sm-table-cell" style={{ width: '10rem' }}>DATA</th>
                                    <th className="text-center py-1" style={{ width: '5rem' }}>CAT</th>
                                    <th className="text-end py-1 pe-2" style={{ width: '10rem' }}>TEMPO</th>
                                    <th className="text-center py-1 d-none d-md-table-cell" style={{ width: '5rem' }}>POS</th>
                                    <th className="text-center py-1 d-none d-lg-table-cell" style={{ width: '4rem' }}>PUNTI</th>
                                    <th className="py-1 d-table-cell d-lg-none" style={{ width: '2rem' }}></th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    tempi_gara.map(x => {
                                        const is_societario = recordSocietari && recordSocietari.findIndex(t => t.idtempo === x.idtempo) !== -1;
                                        const is_migliorato = x.distacco <= 0 && num_tempi_gara > 1;
                                        return (
                                            <tr key={x.idtempo} className={is_migliorato && is_societario ? 'row-societario-stagionale' : (is_migliorato ? 'row-active' : (is_societario ? 'row-societario' : ''))}>
                                                <td className="ps-1 py-1 text-end d-none d-sm-table-cell">
                                                    <span className="badge w-100 shadow" style={{ backgroundColor: colorCircuito(x.circuito), fontWeight: 'normal' }}>
                                                        {x.circuito.slice(0, 4).toUpperCase()}
                                                    </span>
                                                </td>
                                                <td className="ps-2 py-1">
                                                    {/* <Link to={`/master/eventi/${x.idevento}`}>{x.evento} <span className="d-none d-lg-inline-block"> ({x.piscina})</span></Link> */}
                                                    {x.evento_visibile ?
                                                        <Link to={`/master/eventi/${x.idevento}`}>{x.evento} <span className="d-none d-lg-inline"> ({x.piscina})</span></Link> :
                                                        <Fragment>
                                                            {x.evento} <span className="d-none d-lg-inline"> ({x.piscina})</span>
                                                        </Fragment>
                                                    }
                                                    {
                                                        !x.individuale && !x.is_frazione && (
                                                            <div className="mt-1" style={{ fontSize: '0.9rem' }}>
                                                                <Fragment>
                                                                    {x.associato1 && x.idatleta1 !== 3 ?
                                                                        <Link to={`/master/atleti/${x.idatleta1}`}>{(x.idatleta1 === user?.idatleta ? 'Tu' : x.atleta1)}</Link>
                                                                        : (x.idatleta1 === user?.idatleta ? 'Tu' : x.atleta1)
                                                                    }
                                                                    {x.frazione1 !== null ? ' (' + printTempo(x.frazione1, false) + ') ' : ''}
                                                                    <span>, </span>
                                                                    {x.associato2 && x.idatleta2 !== 3 ?
                                                                        <Link to={`/master/atleti/${x.idatleta2}`}>{(x.idatleta2 === user?.idatleta ? 'Tu' : x.atleta2)}</Link>
                                                                        : (x.idatleta2 === user?.idatleta ? 'Tu' : x.atleta2)
                                                                    }
                                                                    {x.frazione2 !== null ? ' (' + printTempo(x.frazione2, false) + ') ' : ''}
                                                                    <span>, </span>
                                                                    {x.associato3 && x.idatleta3 !== 3 ?
                                                                        <Link to={`/master/atleti/${x.idatleta3}`}>{(x.idatleta3 === user?.idatleta ? 'Tu' : x.atleta3)}</Link>
                                                                        : (x.idatleta3 === user?.idatleta ? 'Tu' : x.atleta3)
                                                                    }
                                                                    {x.frazione3 !== null ? ' (' + printTempo(x.frazione3, false) + ') ' : ''}
                                                                    <span>, </span>
                                                                    {x.associato4 && x.idatleta4 !== 3 ?
                                                                        <Link to={`/master/atleti/${x.idatleta4}`}>{(x.idatleta4 === user?.idatleta ? 'Tu' : x.atleta4)}</Link>
                                                                        : (x.idatleta4 === user?.idatleta ? 'Tu' : x.atleta4)
                                                                    }
                                                                    {x.frazione4 !== null ? ' (' + printTempo(x.frazione4, false) + ') ' : ''}
                                                                </Fragment>
                                                            </div>
                                                        )
                                                    }
                                                </td>
                                                <td className="text-center px-2 py-1 d-none d-sm-table-cell">
                                                    {printData(x.data)}
                                                    <span className="d-none d-lg-inline">
                                                        <IconaSessione sessione={x.sessione} />
                                                    </span>
                                                </td>
                                                <td className="text-center px-2 py-1">
                                                    {x.categoria.replace('-', '/')}
                                                </td>
                                                <td className="text-end py-1 pe-2">
                                                    {x.is_frazione ? <BsQuestionCircleFill className="position-relative me-1" style={{ top: '-2px' }} title="Tempo realizzato in staffetta" /> : null}
                                                    {printTempo(x.tempo, false)} <span className="d-none d-sm-inline-block">{x.distacco !== null ? ' (' + printTempo(x.distacco, false, true) + ')' : ''}</span>
                                                </td>
                                                <td className="text-center py-1 d-none d-md-table-cell">
                                                    {x.posizione === 1 && (<img src={process.env.PUBLIC_URL + '/assets/medal1.gif'} className="img-fluid mx-2" style={{ height: '1.3rem' }} alt="" />)}
                                                    {x.posizione === 2 && (<img src={process.env.PUBLIC_URL + '/assets/medal2.gif'} className="img-fluid mx-2" style={{ height: '1.3rem' }} alt="" />)}
                                                    {x.posizione === 3 && (<img src={process.env.PUBLIC_URL + '/assets/medal3.gif'} className="img-fluid mx-2" style={{ height: '1.3rem' }} alt="" />)}
                                                    {x.posizione > 3 && x.posizione}
                                                    {!x.posizione && '-'}
                                                </td>
                                                <td className="text-center py-1 d-none d-lg-table-cell">
                                                    {x.punti ?? '-'}
                                                </td>
                                                <td className="text-center py-1 d-table-cell d-lg-none">
                                                    <button onClick={() => setSelezionato(x)} className="btn btn-dark px-1 py-0">
                                                        <BsSearch />
                                                    </button>
                                                </td>
                                            </tr>
                                        )
                                    })
                                }
                            </tbody>
                        </table>
                    </div>
                )
            })
            }
            {selezionato && <Riepilogo dati={
                {
                    evento: selezionato.evento,
                    piscina: selezionato.piscina,
                    circuito: selezionato.circuito,
                    vasca: selezionato.vasca + 'm',
                    data: printData(selezionato.data) + ' ' + (selezionato.sessione === 'M' ? 'Mattina' : 'Pomeriggio'),
                    stagione: selezionato.stagione,
                    tempo: printTempo(selezionato.tempo),
                    distacco: printTempo(selezionato.distacco, false, true),
                    gara: selezionato.gara,
                    categoria: selezionato.categoria,
                    posizione: selezionato.posizione,
                    punti: selezionato.punti
                }
            } titolo={'Tempo'} onClose={() => setSelezionato(null)} />}
        </Fragment>
    );
}
