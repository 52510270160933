import API from './fetcher.js';

/**
 * Verifica se il token presente nell'header è valido oppure no
 * @returns true se il token è valido, altrimenti false
 */
export function CheckToken() {
    return API.get('/check_token.php');
}

/**
 * Restituisce la lista degli atleti associati, necessaria per elencare gli atleti nel menu
 * @returns array di atleti associati
 */
export function GetAtletiMasterAssociati() {
    return API.get('/get_associati.php');
}

/**
 *  Restituisce la lista degli atleti associati con i record, necessari per la presentazione della squadra
 */
export function GetSquadraMaster() {
    return API.get('/get_squadra.php')
}

/**
 * Restituisce la lista di eventi raggruppati per stagione. Se l'utente non è un'atleta allora sono mostrati solo quelli che hanno le iscrizioni chiuse
 */
export function GetEventiMaster() {
    return API.get('/get_eventi.php')
}

/**
 * Trova i record per ogni gara, categoria, sesso e vasca
 */
export function GetRecordSocietariMaster() {
    return API.get('/get_record_societari.php')
}

/**
 * Trova le informazioni principali di un evento
 */
export function GetEventoMaster(id) {
    return API.get('/get_evento.php?idevento='+id)
}

/**
 * Recupera i tempi di un evento
 */
export function GetRisultatiEventoMaster(id) {
    return API.get('/get_risultati_evento.php?idevento='+id)
}



export function GetProgrammaEventoMaster2(id) {
    return API.get('/get_programma2.php?idevento='+id)
}

/**
 * Recupera tutti i tempi di un atleta
 */
export function GetTempiAtletaMaster(id) {
    return API.get('/get_tempi_atleta.php?idatleta='+id)
}

/**
 * Trova i record di ogni gara, vasca e categoria per un atleta
 */
export function GetProgressioneAtletaMaster(id) {
    return API.get('/get_progressione.php?idatleta='+id)
}

/**
 * Trova i record di un atleta
 */
export function GetRecordAtletaMaster(id) {
    return API.get('/get_record_atleta.php?idatleta='+id)
}

/**
 * Trova i punteggi massimi per ogni gara e vasca per un atleta
 */
export function GetPuntiMaxAtletaMaster(id) {
    return API.get('/get_puntimax_atleta.php?idatleta='+id)
}

export function GetMedagliereAtleta(id) {
    return API.get('/get_medagliere_atleta.php?idatleta='+id)
}

/**
 * Trova le informazioni principali di un atleta
 */
export function GetAtletaMaster(id) {
    return API.get('/get_atleta.php?idatleta='+id)
}

/**
 * Recupera le informazioni di tutti gli atleti, solo per admin
 */
export function GetAnagraficaAtleti() {
    return API.get('/get_anagrafica_atleti.php')
}

/**
 * Recupera i tipi di gare esistenti
 */
export function GetGare() {
    return API.get('/get_gare.php')
}

export function GetCalcolatrice(dati) {
    return API.post('/get_calcolatrice.php', JSON.stringify(dati));
}

export function GetTrofei() {
    return API.get('/get_trofei.php');
}

export function GetAnagraficaTrofei() {
    return API.get('/get_anagrafica_trofei.php');
}

export function EditTrofeo(trofeo) {
    return API.put('/edit_trofeo.php', JSON.stringify(trofeo))
}

export function AddTrofeo(trofeo) {
    return API.post('/add_trofeo.php', JSON.stringify(trofeo))
}

export function DeleteTrofeo(trofeo) {
    return API.post('/delete_trofeo.php', JSON.stringify(trofeo))
}

/**
 * Recupera tutte le informazioni degli eventi dell'ultima stagione, solo per admin
 */
export function GetAnagraficheEventiAdmin() {
    return API.get('/get_anagrafica_eventi.php')
}

export function GetAnagraficaArticoli() {
    return API.get('/get_anagrafica_articoli.php')
}

/**
 * Recupera le informazioni di tutte le piscine, solo per admin
 */
export function GetAnagraficaPiscine() {
    return API.get('/get_anagrafica_piscine.php')
}

/**
 * Recupera tutte le informazioni sul programma e le iscrizioni di un evento, solo per admin
 */
export function GetAnagraficaProgramma(id) {
    return API.get('/get_anagrafica_programma.php?idevento='+id);
}

export function GetPiscina(idevento) {
    return API.get('/get_piscina.php?idevento='+idevento);
}

export function GetEventiStagione() {
    return API.get('/get_eventi_stagione.php');
}

export function GetEdizionEvento(idevento) {
    return API.get('/get_edizioni_evento.php?idevento='+idevento);
}

export function GetIscrizioniEvento(idevento) {
    return API.get('/get_iscrizioni_evento.php?idevento='+idevento);
}

export function GetArticolo(id) {
    return API.get('/get_articolo.php?idarticolo='+id);
}

export function GetArticoli() {
    return API.get('/get_articoli.php');
}

/**
 * Recupera i tipi di circuiti degli eventi, solo per admin
 */
export function GetCircuiti() {
    return API.get('/get_circuiti.php')
}

export function DoRicerca(criteri) {
    return API.post('/searches.php', JSON.stringify(criteri));
}

/**
 * Effettua il login di un atleta
 */
export function DoLoginMaster(credenziali) {
    return API.post('/login.php', JSON.stringify(credenziali))
}

export function SpostaProgramma(info) {
    return API.post('/sposta_programma.php', JSON.stringify(info))
}

export function AddAtletaMaster(atleta) {    
    return API.post('/add_atleta.php', JSON.stringify(atleta))

    // const dati = localStorage.getItem('login_data');
    // const token = JSON.parse(dati).token;
    // return fetch('http://localhost:5000/api/add_atleta.php', {
    //     method: 'POST',
    //     body: JSON.stringify(atleta),
    //     headers: {
    //         'X-Extra': token
    //     }
    // }).then(resp=>resp.json())
    // .then(resp=> resp.data);
}

export function EditAtletaMaster(atleta) {
    return API.put('/edit_atleta.php', JSON.stringify(atleta))
}

export function DeleteAtletaMaster(atleta) {
    return API.post('/delete_atleta.php', JSON.stringify(atleta))
}

export function UpdateFotoAtletaMaster(dati) {
    // return API.post('/update_atleta2.php', JSON.stringify(dati))
}


export function AddEventoMaster(evento) {    
    return API.post('/add_evento.php', JSON.stringify(evento))
}

export function EditEventoMaster(evento) {
    return API.put('/edit_evento.php', JSON.stringify(evento))
}

export function DeleteEventoMaster(evento) {
    return API.post('/delete_evento.php', JSON.stringify(evento))
}

export function AddArticolo(articolo) {    
    return API.post('/add_articolo.php', JSON.stringify(articolo))
}

export function EditArticolo(articolo) {
    return API.put('/edit_articolo.php', JSON.stringify(articolo))
}

export function DeleteArticolo(articolo) {
    return API.post('/delete_articolo.php', JSON.stringify(articolo))
}



export function AddPiscinaMaster(piscina) {    
    return API.post('/add_piscina.php', JSON.stringify(piscina))
}

export function EditPiscinaMaster(piscina) {
    return API.put('/edit_piscina.php', JSON.stringify(piscina))
}

export function DeletePiscinaMaster(piscina) {
    return API.post('/delete_piscina.php', JSON.stringify(piscina))
}



export function AddTempoMaster(tempo) {    
    return API.post('/add_tempo.php', JSON.stringify(tempo))
}

export function EditTempoMaster(tempo) {
    return API.put('/edit_tempo.php', JSON.stringify(tempo))
}

export function DeleteTempoMaster(tempo) {
    return API.post('/delete_tempo.php', JSON.stringify(tempo))
}

export function AddProgrammaMaster(programma) {    
    return API.post('/add_programma.php', JSON.stringify(programma))
}

export function EditProgrammaMaster(programma) {
    return API.put('/edit_programma.php', JSON.stringify(programma))
}

export function DeleteProgrammaMaster(programma) {
    return API.post('/delete_programma.php', JSON.stringify(programma))
}

export function SendCv(dati) {
    return API.post('/send_cv.php', JSON.stringify(dati))
}