import { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { useUser } from "../../hooks/UserContext";
import '../../css/menu-secondario.css';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';


export default function MenuAdmin() {
    const [expanded, setExpanded] = useState(false);
    const [user, setUser] = useUser();

    const location = useLocation();
    const [url, setUrl] = useState(null);

    useEffect(() => {
        setUrl(location.pathname);
    }, [location]);

    const closeNavbar = () => {
        setExpanded(false);
    };

    return (
        <Navbar collapseOnSelect expand="lg" expanded={expanded} bg="light" variant="light" sticky="top" className="menu-secondario shadow py-0" style={{ lineHeight: '1.8', fontSize: '1.1rem' }}>
            <Container>
                <Navbar.Brand className="ps-0 py-2 py-lg-0">Menù Amministratore</Navbar.Brand>
                <Navbar.Toggle onClick={() => setExpanded(expanded ? false : "expanded")} />
                <Navbar.Collapse id="responsive-navbar-nav">
                    <Nav className="ms-auto pb-3 pb-lg-0">
                        <Nav.Link as={Link} onClick={closeNavbar} to={`/master/gestione/atleti`} className={(url?.endsWith('atleti') ? "subnav-attivo py-1" : 'py-1')}>Atleti</Nav.Link>
                        <Nav.Link as={Link} onClick={closeNavbar} to={`/master/gestione/eventi`} className={(url?.endsWith('eventi') || url?.startsWith('/master/gestione/programmi') ? "subnav-attivo py-1" : 'py-1')}>Eventi</Nav.Link>
                        <Nav.Link as={Link} onClick={closeNavbar} to={`/master/gestione/piscine`} className={(url?.endsWith('piscine') ? "subnav-attivo py-1" : 'py-1')}>Piscine</Nav.Link>
                        <Nav.Link as={Link} onClick={closeNavbar} to={`/master/gestione/trofei`} className={(url?.endsWith('trofei') ? "subnav-attivo py-1" : 'py-1')}>Trofei</Nav.Link>
                        <Nav.Link as={Link} onClick={closeNavbar} to={`/master/gestione/articoli`} className={(url?.startsWith('/master/gestione/articoli') ? "subnav-attivo py-1" : 'py-1')}>Articoli</Nav.Link>
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    );
}
