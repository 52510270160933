export default function GridCircoli() {

    return (
        <div className="container" style={{ marginTop: 'max(8%, 3rem)', marginBottom: 'max(8%, 3rem)' }}>
            <div className="row text-center">
                <div className="col">
                    <h1 className="mb-5">Dove Siamo</h1>
                </div>
            </div>
            <div className="row row-cols-1 row-cols-lg-2 g-4">

                <div className="col">
                    <div className="card shadow position-relative h-100">
                        <div className="zoom">
                            <img
                                src={process.env.PUBLIC_URL + '/assets/realcircolo.jpg'}
                                loading="lazy"
                                className="card-img-top"
                                alt="..." />
                        </div>
                        <div className="card-body text-center">
                            <h5 className="card-title fw-bold">Real Sporting Village</h5>
                            <p className="card-text" style={{ fontSize: '1rem' }}>
                                Il circolo si trova in Via Licio Giorgieri 50, Roma.
                                Situato all'uscita Aurelia del raccordo anulare, la struttura presenta una piscina coperta, una scoperta, una palestra, un'area benessere con sauna, bagno turco e vasca idromassaggio, varie sale per i corsi, un ristorante e molto altro.
                            </p>
                        </div>
                        <div className="card-footer p-0 d-flex">
                            <a href="https://it-it.facebook.com/realsportingvillageroma/" target="_blank" rel="noreferrer" className="btn btn-dark w-100" style={{ borderRadius: '0' }}>
                                Vai al sito del circolo
                            </a>
                        </div>
                    </div>
                </div>

                <div className="col">
                    <div className="card shadow position-relative h-100">
                        <div className="zoom">
                            <img
                                src={process.env.PUBLIC_URL + '/assets/heaven.jpg'}
                                loading="lazy"
                                className="card-img-top"
                                alt="..." />
                        </div>
                        <div className="card-body text-center">
                            <h5 className="card-title fw-bold">Heaven Sporting Club</h5>
                            <p className="card-text" style={{ fontSize: '1rem' }}>
                                Il circolo si trova in Via della Mendola 288, Roma.
                                Centro sportivo che offre 6 strutture dentro Roma dotato di sala fitness, piscina, SPA, benessere. Offre svariati corsi come yoga, pilates, ginnastica, macumba, acquagym e hydrobike.
                            </p>
                        </div>
                        <div className="card-footer p-0 d-flex">
                            <a href="https://heavengroup.it/" target="_blank" rel="noreferrer" className="btn btn-dark w-100" style={{ borderRadius: '0' }}>
                                Vai al sito del circolo
                            </a>
                        </div>
                    </div>
                </div>

            </div>
        </div>


    );
}
