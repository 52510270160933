import { Fragment, useState } from "react";
import { BsFillSquareFill, BsSearch } from "react-icons/bs";
import { Link } from "react-router-dom";
import { colorCircuito, colorGara, printData, printTempo } from "../../utility";
import Riepilogo from "../common/Riepilogo";


export default function TabellaPuntiMaxAtletaMaster({ dati, recordSocietari }) {
    const [selezionato, setSelezionato] = useState(null);
    console.log(dati)

    if (!dati)
        return null;

    if (dati.length === 0) {
        return (
            <div className="text-center my-5">
                <h3>Non ci sono tempi da mostrare</h3>
            </div>
        )
    }

    return (
        <Fragment>
            <table className="w-100 shadow" style={{ fontSize: '1.1rem', lineHeight: '1.2' }}>
                <thead>
                    <tr className="fw-bold">
                        <th className="text-center py-1" style={{ width: '4rem' }}>PUNTI</th>
                        <th className="ps-2 py-1" style={{ maxWidth: '11rem', minWidth: '6rem' }}>GARA</th>
                        <th className="py-1 d-none d-md-table-cell" style={{ width: '3rem' }}></th>
                        <th className="ps-3 py-1 d-none d-sm-table-cell">EVENTO</th>
                        <th className="text-end px-2 py-1" style={{ maxWidth: '7rem' }}>TEMPO</th>
                        <th className="text-center py-1 d-none d-sm-table-cell" style={{ width: '8rem' }}>DATA</th>
                        <th className="text-center py-1" style={{ width: '3rem' }}>CAT</th>
                        <th className="py-1 d-table-cell d-md-none" style={{ width: '2rem' }}></th>
                    </tr>
                </thead>
                <tbody>
                    {dati.map(x => {
                        const is_societario = recordSocietari && recordSocietari.findIndex(t => t.idtempo === x.idtempo) !== -1;
                        return (
                            <tr key={x.idtempo} className={x.stagionale && is_societario ? 'row-societario-stagionale' : (x.stagionale ? 'row-active' : (is_societario ? 'row-societario' : ''))}>
                                <td className="text-center py-1">
                                    {x.punti}
                                </td>
                                <td className="ps-2 py-1">
                                    <BsFillSquareFill style={{ color: colorGara(x.gara) }} /> {x.gara}
                                </td>
                                <td className="text-end py-1 d-none d-md-table-cell">
                                    <span className="badge w-100 shadow" style={{ backgroundColor: colorCircuito(x.circuito), fontWeight: 'normal' }}>
                                        {x.circuito.slice(0, 4).toUpperCase()}
                                    </span>
                                </td>
                                <td className="px-2 py-1 d-none d-sm-table-cell">
                                    {x.evento_visibile ?
                                        <Link to={`/master/eventi/${x.idevento}`}>{x.evento} <span className="d-none d-lg-inline"> ({x.piscina})</span></Link> :
                                        <Fragment>
                                            {x.evento} <span className="d-none d-lg-inline"> ({x.piscina})</span>
                                        </Fragment>
                                    }
                                </td>
                                <td className="text-end px-2 py-1">
                                    {printTempo(x.record, false)}
                                </td>
                                <td className="text-center px-2 py-1 d-none d-sm-table-cell">
                                    {printData(x.data)}
                                </td>
                                <td className="text-center px-2 py-1">
                                    {x.categoria.replace('-', '/')}
                                </td>
                                <td className="text-center py-1 d-table-cell d-md-none">
                                    <button onClick={() => setSelezionato(x)} className="btn btn-dark px-1 py-0">
                                        <BsSearch />
                                    </button>
                                </td>
                            </tr>
                        )
                    }
                    )}
                </tbody>
            </table>
            {selezionato && <Riepilogo dati={
                {
                    gara: selezionato.gara,
                    evento: selezionato.evento,
                    circuito: selezionato.circuito,
                    piscina: selezionato.piscina,
                    data: printData(selezionato.data),
                    punti: selezionato.punti,
                    categoria: selezionato.categoria,
                    vasca: selezionato.vasca + 'm',
                    record: printTempo(selezionato.record)
                }
            } titolo={'Tempo'} onClose={() => setSelezionato(null)} />}
        </Fragment>
    );
}
