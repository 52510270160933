import { Fragment, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import useScrollTop from "../../hooks/useScrollTop";
import { GetRecordSocietariMaster, GetRisultatiEventoMaster } from "../../rest/api";
import Caricamento from "../common/Caricamento";
import LegendaRecord from "../common/LegendaRecord";
import TabellaRisultatiEventoMaster from "./TabellaRisultatiEventoMaster";


export default function RisultatiEventoMaster() {
    let param = useParams();
    const [tempi, setTempi] = useState();
    const [record, setRecord] = useState();
    useScrollTop();

    useEffect(() => {
        let doUpdate = true;
        GetRisultatiEventoMaster(param.idevento)
            .then(result => {
                if (doUpdate)
                    setTempi(result.data);
            })
        return () => doUpdate = false;
    }, [param.idevento])

    useEffect(() => {
        let doUpdate = true;
        GetRecordSocietariMaster()
            .then(result => {
                if (doUpdate)
                    setRecord(result.data);
            })
        return () => doUpdate = false;
    }, [])

    console.log(tempi)

    return (
        <Fragment>
            <div className="text-center mb-4">
                <h2>Risultati</h2>
                <p>
                    Le tabelle che seguono riportano i risultati degli atleti raggruppati per stile di gara. 
                    Le analisi dei tempi e le schede personali sono disponibili solo per gli atleti associati.
                    Le prestazioni di rilievo sono evidenziate da uno sfondo diverso:
                </p>

                <LegendaRecord />
            </div>
            {!tempi && <Caricamento messaggio="Caricamento risultati in corso" />}
            {tempi && tempi.length === 0 && (
                <div className="text-center">
                    <h3>Nessun risultato presente</h3>
                </div>
            )}
            {tempi && <TabellaRisultatiEventoMaster risultati={tempi.filter(x => x.tempi.length > 0)} recordSocietari={record} />}
        </Fragment>
    );
}
