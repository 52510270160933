import { Fragment, useState } from "react";
import { AddTrofeo, DeleteTrofeo, EditTrofeo } from "../../rest/api";


export default function FormTrofeo({ dati, circuiti, onUpdateTrofeo }) {
    console.log(dati);
    if (!dati || dati.idtrofeo < 0) {
        dati = {
            idtrofeo: -1,
            descrizione: '',
            idcircuito: -1,
            livello: '',
            nome_file: '',
            data: new Date().toISOString().slice(0, 10),
            posizione: '',
            note: ''
        }
    }
    const [idtrofeo, setIdtrofeo] = useState(dati.idtrofeo);
    const [descrizione, setDescrizione] = useState(dati.descrizione);
    const [idcircuito, setIdcircuito] = useState(dati.idcircuito);
    const [note, setNote] = useState(dati.note ?? '');
    const [livello, setLivello] = useState(dati.livello);
    const [nomeFile, setNomeFile] = useState(dati.nome_file);
    const [data, setData] = useState(dati.data);
    const [posizione, setPosizione] = useState(dati.posizione);

    const [isLoading, setIsLoading] = useState(false);
    const [messaggio, setMessaggio] = useState();
    const [errore, setErrore] = useState();

    const editTrofeo = e => {
        e.preventDefault();

        const trofeo_update = {
            idtrofeo: idtrofeo,
            descrizione: descrizione !== '' ? descrizione : null,
            idcircuito: idcircuito > 0 ? idcircuito : null,
            livello: livello !== '' ? livello : null,
            nomefile: nomeFile !== '' ? nomeFile : null,
            data: data,
            posizione: posizione > 0 ? posizione : null,
            note: note !== '' ? note : null
        }

        console.log(trofeo_update);
        setErrore(null);
        setMessaggio(null);
        setIsLoading(true);
        if (idtrofeo > 0) {
            EditTrofeo(trofeo_update)
                .then(resp => {
                    setMessaggio('Trofeo modificato correttamente');
                    onUpdateTrofeo(idtrofeo, 'edit');
                })
                .catch(err => setErrore(err))
                .finally(() => setIsLoading(false));
        } else {
            AddTrofeo(trofeo_update)
                .then(resp => {
                    setIdtrofeo(resp.data.idtrofeo);
                    setMessaggio('Trofeo creato correttamente');
                    onUpdateTrofeo(resp.data.idtrofeo, 'new');
                })
                .catch(err => setErrore(err))
                .finally(() => setIsLoading(false));
        }

    }

    const deleteTrofeo = e => {
        e.preventDefault();
        const trofeo_deleted = {
            idtrofeo: idtrofeo
        }
        console.log(trofeo_deleted)
        DeleteTrofeo(trofeo_deleted)
            .then(resp => {
                console.log('Trofeo eliminato')
                onUpdateTrofeo(trofeo_deleted, 'delete');
            })
            .catch(err => setErrore(err))
    }

    return (
        <Fragment>
            {messaggio && (
                <div className="alert alert-success mb-1 py-1 shadow-sm fw-bold" role="alert" style={{ fontSize: '1rem' }}>
                    {messaggio}
                </div>
            )
            }
            {errore && (
                <div className="alert alert-danger mb-1 py-1 shadow-sm fw-bold" role="alert" style={{ fontSize: '1rem' }}>
                    {errore}
                </div>
            )
            }
            <form onSubmit={editTrofeo}>
                <div className="mb-3">
                    <label htmlFor="descrizione" className="form-label mb-0" style={{ fontSize: '1rem' }}>Nome</label>
                    <input type="text" value={descrizione} onChange={e => setDescrizione(e.target.value)} className="form-control form-control-lg" id="descrizione" placeholder="Inserisci il nome del trofeo" />
                </div>

                <div className="row">
                    <div className="col-lg mb-3">
                        <label htmlFor="data" className="w-100 mb-0" style={{ fontSize: '1rem' }}>Data</label>
                        <input type="date" value={data} onChange={e => setData(e.target.value)} className="form-control form-control-lg" placeholder="Inserisci la data" name="data" id="data" required />
                    </div>
                    <div className="col-lg mb-3">
                        <label className="w-100 mb-0" style={{ fontSize: '1rem' }}>Circuito</label>
                        <select value={idcircuito} onChange={e => setIdcircuito(parseInt(e.target.value))} className="form-select form-select-lg">
                            <option value={-1}>Nessun circuito</option>
                            {circuiti.map(x => (
                                <option key={x.idcircuito} value={x.idcircuito}>{x.nome}</option>
                            ))}
                        </select>
                    </div>
                </div>

                <div className="row">
                    <div className="col-lg mb-3">
                        <label htmlFor="posizione" className="w-100 mb-0" style={{ fontSize: '1rem' }}>Posizione</label>
                        <input type="number" value={posizione} onChange={e => setPosizione(parseInt(e.target.value))} className="form-control form-control-lg" placeholder="Inserisci la posizione" id="posizione" required />
                    </div>
                    <div className="col-lg mb-3">
                        <label className="w-100 mb-0" style={{ fontSize: '1rem' }}>Livello</label>
                        <select value={livello} onChange={e => setLivello(e.target.value)} className="form-select form-select-lg">
                            <option value={''}>Nessun livello</option>
                            <option value={'N'}>Nazionale</option>
                            <option value={'R'}>Regionale</option>
                        </select>
                    </div>
                </div>
                <div className="mb-3">
                    <label htmlFor="nome_file" className="form-label mb-0" style={{ fontSize: '1rem' }}>Nome</label>
                    <input type="text" value={nomeFile} onChange={e => setNomeFile(e.target.value)} className="form-control form-control-lg" id="nome_file" placeholder="Inserisci il nome del file" />
                </div>
                <div className="form-group mb-3">
                    <label htmlFor="note" style={{ fontSize: '1rem' }}>Note</label>
                    <textarea rows="3" value={note} onChange={e => setNote(e.target.value)} className="form-control form-control-lg" name="note" id="note"></textarea>
                </div>
                <div className="d-flex">
                    <button type="submit" disabled={isLoading} className="btn btn-lg btn-success w-100" style={{ borderRadius: '0' }}>
                        Salva
                    </button>
                    {idtrofeo > 0 && (
                        <button onClick={deleteTrofeo} disabled={isLoading} className="btn btn-lg btn-danger w-100" style={{ borderRadius: '0' }}>
                            Elimina
                        </button>
                    )}
                </div>
            </form>
        </Fragment>
    );
}
