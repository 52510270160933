import { Fragment, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import { useUser } from "../../hooks/UserContext";
import useScrollTop from "../../hooks/useScrollTop";
import useTitle from "../../hooks/useTitle";
import { AddArticolo, DeleteArticolo, EditArticolo, GetArticolo } from "../../rest/api";
import ContentArticolo from "../articolo/ContentArticolo";
import Caricamento from "../common/Caricamento";


export default function FormArticolo() {
    const params = useParams();
    const navigate = useNavigate();
    const [articolo, setArticolo] = useState();

    useEffect(() => {
        let doUpdate = true;
        if (params.idarticolo < 0) {
            setArticolo({
                idarticolo: -1,
                titolo: '',
                sottotitolo: '',
                copertina: '',
                testo: '',
                visibile: false
            });
        }
        else {
            GetArticolo(params.idarticolo)
                .then(result => {
                    if (doUpdate)
                        setArticolo(result.data);
                })
        }
        return () => doUpdate = false;
    }, [params.idarticolo])


    const [isLoading, setIsLoading] = useState(false);
    const [messaggio, setMessaggio] = useState();
    const [errore, setErrore] = useState();
    const [gare, setGare] = useState();

    useTitle('Articolo')
    useScrollTop();
    const [user] = useUser();


    const editArticolo = e => {
        e.preventDefault();

        const articolo_update = {
            idarticolo: articolo.idarticolo,
            titolo: articolo.titolo !== '' ? articolo.titolo : null,
            sottotitolo: articolo.sottotitolo !== '' ? articolo.sottotitolo : null,
            copertina: articolo.copertina !== '' ? articolo.copertina : null,
            testo: articolo.testo !== '' ? articolo.testo : null,
            visibile: articolo.visibile == true
        }

        console.log(articolo_update);
        setErrore(null);
        setMessaggio(null);
        setIsLoading(true);
        if (articolo.idarticolo > 0) {
            EditArticolo(articolo_update)
                .then(resp => {
                    setMessaggio('Articolo modificato correttamente');
                })
                .catch(err => setErrore(err))
                .finally(() => setIsLoading(false));
        } else {
            AddArticolo(articolo_update)
                .then(resp => {
                    setArticolo({ ...articolo, idarticolo: resp.data.idarticolo });
                    setMessaggio('Articolo creato correttamente');
                })
                .catch(err => setErrore(err))
                .finally(() => setIsLoading(false));
        }
    }

    const deleteArticolo = e => {
        e.preventDefault();
        const articolo_deleted = {
            idarticolo: articolo.idarticolo
        }
        console.log(articolo_deleted)
        DeleteArticolo(articolo_deleted)
            .then(resp => {
                console.log('Articolo eliminato');
                navigate('/master/gestione/articoli');
            })
            .catch(err => setErrore(err))
    }

    return (
        <Fragment>

            <div className="row">
                <div className="col">
                    <div className="pannello p-4 p-lg-5 mx-auto" style={{ maxWidth: '900px' }}>
                        {!articolo && <Caricamento messaggio="Caricamento articolo in corso" />}
                        {articolo && (
                            <form onSubmit={editArticolo}>
                                <div>
                                    <h2 className="mb-4 text-center">
                                        {articolo.idarticolo < 0 ? 'Creazione Articolo' : 'Modifica Articolo ' + articolo.idarticolo}
                                    </h2>
                                </div>
                                <div className="form-group mb-3">
                                    <label htmlFor="titolo" className="w-100 mb-0" style={{ fontSize: '1rem' }}>Titolo</label>
                                    <input type="text" value={articolo.titolo} onChange={e => setArticolo({ ...articolo, titolo: e.target.value })} className="form-control form-control-lg" placeholder="Inserisci il titolo" id="titolo" required />
                                </div>
                                <div className="form-group mb-3">
                                    <label htmlFor="sottotitolo" className="w-100 mb-0" style={{ fontSize: '1rem' }}>Sottotitolo</label>
                                    <input type="text" value={articolo.sottotitolo} onChange={e => setArticolo({ ...articolo, sottotitolo: e.target.value })} className="form-control form-control-lg" placeholder="Inserisci il sottotitolo" id="titolo" required />
                                </div>
                                <div className="row align-items-center">
                                    <div className="col-lg mb-3">
                                        <label htmlFor="copertina" className="w-100 mb-0" style={{ fontSize: '1rem' }}>Copertina</label>
                                        <input type="text" value={articolo.copertina} onChange={e => setArticolo({ ...articolo, copertina: e.target.value })} className="form-control form-control-lg" placeholder="Inserisci la copertina" required />
                                    </div>
                                    <div className="col-lg mb-3">
                                        <input className="form-check-input me-1" type="checkbox" checked={articolo.visibile} onChange={e => setArticolo({ ...articolo, visibile: e.target.checked })} id="visibile" />
                                        <label className="form-check-label" htmlFor="visibile" style={{ fontSize: '1rem' }}>
                                            Visibile
                                        </label>
                                    </div>
                                </div>
                                <div className="form-group mb-3">
                                    <label htmlFor="testo" style={{ fontSize: '1rem' }}>Testo</label>
                                    <textarea rows="20" value={articolo.testo} onChange={e => setArticolo({ ...articolo, testo: e.target.value })} className="form-control form-control-lg"></textarea>
                                </div>
                                <div className="d-flex">
                                    <button type="submit" disabled={isLoading} className="btn btn-lg btn-success w-100" style={{ borderRadius: '0' }}>
                                        Salva
                                    </button>
                                    {articolo.idarticolo > 0 && (
                                        <button onClick={deleteArticolo} disabled={isLoading} className="btn btn-lg btn-danger w-100" style={{ borderRadius: '0' }}>
                                            Elimina
                                        </button>
                                    )}
                                </div>
                            </form>
                        )}
                    </div>
                    {articolo && (
                        <div className="mx-auto" style={{ maxWidth: '900px', marginTop: '14rem' }}>
                            <ContentArticolo articolo={articolo} />
                        </div>
                    )}
                </div>
            </div>
        </Fragment>
    );
}
