import React from 'react';
import '../../css/copertina.css';

export default function Copertina({ titolo, sottotitolo, sfondo, immagine }) {
    return (
        <div id="copertina" className="testo-contornato shadow" style={{
            backgroundImage: `linear-gradient(0deg, rgba(0,0,0,0.8) 20%, rgba(0,0,0,0)), url('${process.env.PUBLIC_URL + '/assets/' + sfondo}')`, 
            backgroundPosition: "center"
        }}>
            <div className="text-center mt-auto">
                <div className="container mt-5 mb-3 mb-lg-5" style={{ 'maxWidth': '1100px' }}>
                    {
                        React.isValidElement(immagine) && immagine
                    }
                    {!React.isValidElement(immagine) && immagine && (
                        <img
                            src={`${process.env.PUBLIC_URL + '/assets/' + immagine}`}
                            className="immagine img-fluid mb-3 mb-lg-4 d-none d-sm-inline"
                            style={{ "maxHeight": "10rem" }}
                            alt=''
                        />
                    )}
                    {titolo && <h1 className="mb-2">{titolo}</h1>}
                    <p style={{whiteSpace: 'break-spaces'}}>
                        {sottotitolo}
                    </p>
                </div>
            </div>
        </div>
    )
}