import { Fragment, useEffect, useState } from "react";
import useScrollTop from "../../hooks/useScrollTop";
import useTitle from "../../hooks/useTitle";
import Copertina from "../common/Copertina";
import '../../css/articoli.css';
import Caricamento from "../common/Caricamento";
import { GetArticoli } from "../../rest/api";
import GridArticoli from "./GridArticoli";
import PaginazioneArticoli from "./PaginazioneArticoli";

const SHOW_PER_PAGE = 12;

export default function ArticoliPage() {
    const [pagina, setPagina] = useState(1);
    const [articoli, setArticoli] = useState();
    useTitle('Articoli');
    useScrollTop();

    useEffect(() => {
        let doUpdate = true;
        GetArticoli()
            .then(result => {
                if (doUpdate) {
                    setArticoli(result.data);
                }
                console.log(result.data);
            })
        return () => doUpdate = false;
    }, [])

    const idx1 = (pagina - 1) * SHOW_PER_PAGE;
    const idx2 = idx1 + SHOW_PER_PAGE;
    console.log('articoli da '+idx1+' a '+idx2);

    return (
        <Fragment>
            <Copertina
                titolo="Articoli"
                sottotitolo="Leggi gli articoli della squadra per restare aggiornato sull'associazione"
                // immagine="cover-login.jpg"
                sfondo="cover-news.jpg"
            />
            <div className='container' style={{ marginTop: 'max(8%, 3rem)', marginBottom: 'max(8%, 3rem)' }}>
                {!articoli && <Caricamento messaggio="Caricamento articolo in corso" />}
                {articoli && (
                    <Fragment>
                        <PaginazioneArticoli
                            size={articoli.length}
                            pagina={pagina}
                            per_page={SHOW_PER_PAGE}
                            onChangePage={n => setPagina(n)}
                        />
                        <GridArticoli articoli={articoli.slice(idx1, idx2)} />
                    </Fragment>
                )}
            </div>
        </Fragment>
    );
}
