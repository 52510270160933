import { Fragment, useEffect, useState } from "react";
import useScrollTop from "../../hooks/useScrollTop";
import useTitle from "../../hooks/useTitle";
import { GetSquadraMaster } from "../../rest/api";
import Copertina from "../common/Copertina";
import GridAtleti from "./GridAtleti";
import ContentRecord from "./ContentRecord";
import Modal from 'react-bootstrap/Modal';
import Caricamento from "../common/Caricamento";
import LegendaRecord from "../common/LegendaRecord";
import { BsQuestionCircleFill } from "react-icons/bs";

export default function SquadraMasterPage() {
    const [atleti, setAtleti] = useState();
    const [selezionato, setSelezionato] = useState();
    useTitle('Squadra Master')
    useScrollTop();

    useEffect(() => {
        let doUpdate = true;
        GetSquadraMaster()
            .then(result => {
                if (doUpdate)
                    setAtleti(result.data)
            })
        return () => doUpdate = false;
    }, [])


    return (

        <Fragment>
            <Copertina
                titolo="Squadra Master"
                // sottotitolo="Area riservata agli atleti della squadra master ASD Aquareal"
                // immagine="cover-login.jpg"
                sfondo="sfondobis.jpg"
            />
            {!atleti && <Caricamento messaggio="Caricamento della squadra in corso" />}
            {atleti && (
                <div className='container' style={{ marginTop: 'max(8%, 3rem)', marginBottom: 'max(8%, 3rem)' }}>

                    <GridAtleti
                        dati={atleti}
                        onSelect={setSelezionato}
                    />

                </div>
            )}
            {/* <ModalRecord dati={selezionato} onClose={()=>setSelezionato(null)} /> */}
            {selezionato && (
                <Modal
                    show={true}
                    onHide={() => setSelezionato(null)}
                    scrollable
                    dialogClassName="modal-100"
                    style={{ overflowX: 'hidden' }}
                >
                    <Modal.Header closeButton>
                        <Modal.Title className="w-100 text-center">
                            Record {selezionato.nome} {selezionato.cognome}
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="mb-3 text-center">
                            <p className="mb-2">
                                La tabella riassume i migliori tempi dell'atleta per ogni gara. I record contrassegnati con <BsQuestionCircleFill /> sono stati realizzati nella prima frazione di una staffetta.
                            </p>
                            <LegendaRecord tipo={"stagionale"} />
                        </div>
                        <ContentRecord dati={selezionato} />
                    </Modal.Body>
                </Modal>
            )}
        </Fragment>
    );
}
