import { Fragment, useEffect, useState } from "react";
import useScrollTop from "../../hooks/useScrollTop";
import useTitle from "../../hooks/useTitle";
import { GetRecordSocietariMaster } from "../../rest/api";
import Copertina from "../common/Copertina";
import ContentRecordSocietariMaster from "./ContentRecordSocietariMaster";
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import ToggleButton from 'react-bootstrap/ToggleButton';
import Caricamento from "../common/Caricamento";

export default function RecordSocietariMaster() {
    const [tempi, setTempi] = useState();
    const [sesso, setSesso] = useState('M');
    useTitle('Record Societari');
    useScrollTop();

    useEffect(() => {
        let doUpdate = true;
        GetRecordSocietariMaster()
            .then(result => {
                if (doUpdate)
                    setTempi(result.data);
            })
        return () => doUpdate = false;
    }, [])

    console.log(tempi)


    const radios = [
        { name: 'Maschi', value: 'M' },
        { name: 'Femmine', value: 'F' }
    ];

    return (
        <Fragment>
            <Copertina
                titolo="Record Societari"
                sottotitolo="I migliori tempi assoluti per ogni categoria e gara, distinguendo i due sessi. Nel calcolo dei record sono tenute in considerazione anche le prime frazioni delle staffette. In verde sono evidenziati i tempi realizzati in questa stagione."
                // immagine="cover-login.jpg"
                sfondo="sfondobis.jpg"
            />
            <div className="container-lg mx-auto" style={{maxWidth: '1100px', marginTop: 'max(8%, 3rem)', marginBottom: 'max(8%, 3rem)' }}>
                <div className="row">
                    <div className="col">
                        {/* <div className="text-center mb-4">
                            <h2>Record Societari</h2>
                            <p className="mb-5">
                                I record societari sono i migliori tempi assoluti per ogni categoria e gara, distinguendo i due sessi. Usa i tasti sopra la tabella per scegliere la lunghezza della vasca e il sesso da mostrare. Nel calcolo dei record sono tenute in considerazione anche le prime frazioni delle staffette se hanno disponibili i parziali.
                            </p>
                        </div> */}
                        {!tempi && <Caricamento messaggio="Caricamento dei record in corso" />}
                        {tempi && (
                            <Fragment>
                                <ButtonGroup className="d-flex">
                                    {radios.map((radio, idx) => (
                                        <ToggleButton
                                            key={idx}
                                            id={`radio-${idx}`}
                                            type="radio"
                                            variant={radio.value === 'M' ? 'outline-primary' : 'outline-danger'}
                                            name="radio"
                                            value={radio.value}
                                            checked={sesso === radio.value}
                                            onChange={(e) => setSesso(e.currentTarget.value)}
                                            className="w-100"
                                            style={{ borderRadius: '0', fontSize: '1.2rem' }}
                                        >
                                            {radio.name}
                                        </ToggleButton>
                                    ))}
                                </ButtonGroup>
                                <ContentRecordSocietariMaster dati={tempi} sesso={sesso} />
                            </Fragment>
                        )}
                    </div>
                </div>
            </div>



        </Fragment>
    );
}
