import Spinner from 'react-bootstrap/Spinner';

export default function Caricamento({ messaggio }) {
    return (
        <div className="text-center my-5" style={{color: 'gray', height: '80vh'}}>
            <Spinner size="lg" animation="grow" role="status" ></Spinner>
            <h1 className="mt-1" style={{fontWeight: 'normal', lineHeight: '1.6'}}>{messaggio}</h1>
        </div>
    );
}
