import { Fragment } from "react";
import { Link } from "react-router-dom";
import { useUser } from "../../hooks/UserContext";
import useScrollTop from "../../hooks/useScrollTop";
import useTitle from "../../hooks/useTitle";
import Copertina from "../common/Copertina";
import GridCircoli from "./GridCircoli";

export default function HomePage() {
  useTitle('Home')
  useScrollTop();
  const [user] = useUser();

  return (
    <Fragment>
      <Copertina
        titolo="ASD Aquareal"
        sottotitolo="AQUAREAL è un'associazione sportiva dilettantistica che ha come scopo l'esercizio dell'attività natatoria mediante la partecipazione a competizioni regionali, nazionali e internazionali."
        immagine="logo-bianco2.png"
        sfondo="sfondo6.jpg"
      />

      <div className="container" style={{ marginTop: 'max(8%, 3rem)', marginBottom: 'max(8%, 3rem)' }}>
        <div className="row align-items-center">
          <div className="col-lg">
            <h1 className="mb-3 mb-lg-5">Chi Siamo</h1>
            <p>
              ASD Aquareal è un'associazione nata nel 2016 con lo scopo di promuovere il nuoto attraverso la partecipazione agli eventi del circuito Master.
            </p>
            <p>
              Più di 60 atleti master sono passati sotto i colori di Aquareal disputando più di 120 eventi, sia in Italia che all'estero.
            </p>
          </div>
          <div className="col-lg">
            <img
              src={`${process.env.PUBLIC_URL + '/assets/cuffie.png'}`}
              className="img-fluid mb-3 mb-lg-4"
              alt=''
            />
          </div>
        </div>
      </div>

      {/* <img
        src={`${process.env.PUBLIC_URL + '/assets/cornicereal.png'}`}
        className="img-fluid w-100"
        alt=''
      /> */}

      <img
        src={`${process.env.PUBLIC_URL + '/assets/sfondobis.jpg'}`}
        className="img-fluid w-100"
        alt=''
      />

      <GridCircoli />

      <div className="container" style={{ marginTop: 'max(8%, 3rem)', marginBottom: 'max(8%, 3rem)' }}>
        <div className="row">
          <div className="col">
            <h1 className="mb-5">I vantaggi di essere associati</h1>
            <p>
              Per essere associati ad Aquareal è necessario pagare una piccola quota annuale. <br />
              Oltre a benefici economici, i vantaggi per gli associati sono numerosi per quanto riguarda al preparazione atletica e il proprio benessere:
            </p>
            <ul>
              <li>registrazione di tutte le prestazioni Master e analisi automatica dei tempi tramite il sito sviluppato in Aquareal</li>
              <li>piano di allenamento personalizzato, sia in palestra che in acqua, per tenere conto delle proprie esigenze</li>
              <li>possibilità di essere seguiti da figure specializzate, come nutrizionisti, osteopati e fisioterapisti</li>
              <li>sconti e promozioni sulle migliori attrezzature e materiale per l'allenamento</li>
              <li>sconti e convenzioni durante le trasferte su hotel, trasporti e gadget</li>
            </ul>
            <p>
              Per ogni altra informazione non esitare a contattarci. <br />
              Visita la pagina dei <Link to={"/contatti"}>contatti</Link> e scegli il modo più conveniente per richiedere assistenza.
            </p>
          </div>
        </div>
      </div>

      {/* <div className="containe-fluid">
        <div className="row g-0">
          <div className="col">
            <iframe title="real" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3557.980480054833!2d12.38440748361963!3d41.8849306738572!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x132f5f7ebe58bf13%3A0x254b1cc6322a4a82!2sReal%20Village%20Roma!5e0!3m2!1sit!2sit!4v1658390377850!5m2!1sit!2sit" width="100%" height="400" style={{ border: '0' }} loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
          </div>
          <div className="col">
            <iframe title="heaven" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2967.5852376558787!2d12.442419015403996!3d41.94476307921694!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x132f61fbf32ebd0b%3A0xffa8370b86ef64d7!2sHeaven%20Sporting%20Club%20-%20Cortina!5e0!3m2!1sit!2sit!4v1658390514187!5m2!1sit!2sit" width="100%" height="400" style={{ border: '0' }} loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
          </div>
        </div>
      </div> */}



    </Fragment>
  );
}
