import { Fragment, useEffect, useState } from "react";
import { BsFillSquareFill } from "react-icons/bs";
import { Link, useParams } from "react-router-dom";
import useScrollTop from "../../hooks/useScrollTop";
import { GetPiscina } from "../../rest/api";
import { colorCircuito, colorGara, iconaGara, nomiGiorni, printData } from "../../utility";
import Caricamento from "../common/Caricamento";


export default function Piscina() {
    let param = useParams();
    const [piscina, setPiscina] = useState();
    useScrollTop();

    useEffect(() => {
        let doUpdate = true;
        GetPiscina(param.idevento)
            .then(result => {
                if (doUpdate) {
                    result.data.eventi = result.data.eventi.filter(x => x.idevento != param.idevento);
                    setPiscina(result.data);
                }
            })
        return () => doUpdate = false;
    }, [param.idevento])

    console.log(piscina)

    if (!piscina)
        return <Caricamento messaggio="Caricamento piscina in corso" />

    const has_tempi = piscina.eventi.tempi !== null;

    return (
        <Fragment>
            <div className="text-center">
                <h2>Piscina {piscina.nome}</h2>
                <p className="mb-5">
                    {piscina.indirizzo}
                </p>
                {piscina.has_foto && (
                    <img
                        src={`${process.env.PUBLIC_URL + '/assets/piscine/piscina' + piscina.idpiscina + '.jpg'}`}
                        className="img-fluid mb-3 mb-lg-4"
                        style={{ maxWidth: '100%' }}
                        alt=''
                    />
                )}
            </div>
            {piscina.eventi.length > 0 && (
                <Fragment>
                    <h2 className="text-center mt-5">Eventi passati</h2>
                    <p className="text-center mb-5">
                        Eventi delle passate stagioni che si sono svolti nella stessa piscina. Clicca su un evento per aprire la scheda con i risultati.
                        {has_tempi === true ? ' Nella tabella sono indicate anche il numero di gare a cui hai partecipato.' : ''}
                    </p>
                    <table className="w-100 shadow mx-auto mt-5" style={{ fontSize: '1.1rem', lineHeight: '1.2', maxWidth: '800px' }}>
                        <thead>
                            <tr className="fw-bold">
                                <th className="py-1 ps-1" style={{ width: '3.3rem' }}></th>
                                <th className="ps-2 py-1">EVENTO</th>
                                <th className="text-center py-1" style={{ width: '7rem' }}>STAGIONE</th>
                                <th className="text-center py-1 d-none d-sm-table-cell" style={{ width: '5rem' }}>VASCA</th>
                                {has_tempi && <th className="text-center py-1 d-none d-md-table-cell" style={{ width: '6rem' }}>TUE GARE</th>}
                            </tr>
                        </thead>
                        <tbody>
                            {piscina.eventi.map(x => {
                                return (
                                    <tr key={x.idevento}>
                                        <td className="text-end py-1 ps-1">
                                            <span className="badge w-100 shadow" style={{ backgroundColor: colorCircuito(x.circuito), fontWeight: 'normal' }}>
                                                {x.circuito.slice(0, 4).toUpperCase()}
                                            </span>
                                        </td>
                                        <td className="ps-2">
                                            {x.visibile ?
                                                <Link to={`/master/eventi/${x.idevento}`}>{x.evento}</Link> :
                                                x.evento
                                            }
                                        </td>
                                        <td className="text-center py-1">
                                            {x.stagione.replace('-', '/')}
                                        </td>
                                        <td className="text-center py-1 d-none d-sm-table-cell">
                                            {x.vasca}m
                                        </td>
                                        {has_tempi && (
                                            <td className="text-center d-none d-md-table-cell">
                                                {x.tempi > 0 ? x.tempi : '-'}
                                            </td>
                                        )}
                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>
                </Fragment>
            )}
        </Fragment>
    )

}
