import { Fragment } from "react";
import ItemTrofeo from "./ItemTrofeo";


export default function GridTrofei({ dati }) {
    console.log(dati)

    return (
        <Fragment>
            <h1 className="text-center mb-5">Trofei Nazionali</h1>
            {dati.filter(x => x.livello==='Nazionale').map(x => <ItemTrofeo key={x.idtrofeo} dati={x} />)}

            <h1 className="text-center my-5">Trofei Regionali</h1>
            {dati.filter(x => x.livello==='Regionale').map(x => <ItemTrofeo key={x.idtrofeo} dati={x} />)} 
        </Fragment>
    );
}
