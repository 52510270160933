import { useEffect, useState } from "react";
import Modal from 'react-bootstrap/Modal';


export default function Riepilogo({ dati, titolo, onClose }) {
    const [show, setShow] = useState(true);

    useEffect(()=> {
        setShow(true);
    }, [dati]);

    const chiavi = Object.keys(dati);

    return (

        <Modal
            show={show}
            onHide={() => {
                onClose();
                setShow(false);
            }}
            centered
            scrollable
            backdrop="static"
            keyboard={false}
        >
            <Modal.Header closeButton closeVariant='white' style={{ backgroundColor: 'rgba(0,0,0,0.85)', color: 'white' }}>
                <Modal.Title className="w-100 text-center">
                    {titolo}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {chiavi.map(k => {
                    return (
                        <div key={k}>
                            <p className="mb-0" style={{fontSize: '1rem', color: 'gray'}}>{k.toUpperCase()}</p>
                            <p className="fw-bold mb-2">{dati[k]}</p>
                        </div>
                    )
                })}
            </Modal.Body>
        </Modal>
    );
}
