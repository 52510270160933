import { Fragment, useState } from "react";
import { AddAtletaMaster, DeleteAtletaMaster, EditAtletaMaster } from "../../rest/api";


export default function FormAtleta({ dati, onUpdateAtleta }) {
    const [idatleta, setIdatleta] = useState(dati?.idatleta ?? -1);
    const [nome, setNome] = useState(dati?.nome ?? '');
    const [cognome, setCognome] = useState(dati?.cognome ?? '');
    const [email, setEmail] = useState(dati?.email ?? '');
    const [anno, setAnno] = useState(dati?.anno ?? new Date().getFullYear());
    const [note, setNote] = useState(dati?.note ?? '');
    const [associato, setAssociato] = useState(dati?.associato == true ? true : false);
    const [sesso, setSesso] = useState(dati?.sesso ?? 'M');

    const [isLoading, setIsLoading] = useState(false);
    const [messaggio, setMessaggio] = useState();
    const [errore, setErrore] = useState();

    const editAtleta = e => {
        e.preventDefault();

        const atleta_update = {
            idatleta: idatleta,
            nome: nome !== '' ? nome : null,
            cognome: cognome !== '' ? cognome : null,
            email: email !== '' ? email : null,
            anno: anno,
            note: note !== '' ? note : null,
            associato: associato,
            sesso: sesso
        }

        console.log(atleta_update);
        setErrore(null);
        setMessaggio(null);
        setIsLoading(true);
        if (idatleta > 0) {
            EditAtletaMaster(atleta_update)
                .then(resp => {
                    // atleta_update.creazione = resp.data.creazione;
                    // atleta_update.modifica = resp.data.modifica;
                    setMessaggio('Atleta modificato correttamente');
                    onUpdateAtleta(idatleta, 'edit');
                })
                .catch(err => setErrore(err))
                .finally(()=> setIsLoading(false));
        } else {
            AddAtletaMaster(atleta_update)
                .then(resp => {
                    // atleta_update.idatleta = resp.data.idatleta;
                    setIdatleta(resp.data.idatleta);
                    // atleta_update.creazione = resp.data.creazione;
                    setMessaggio('Atleta creato correttamente');
                    onUpdateAtleta(resp.data.idatleta, 'new');
                })
                .catch(err => setErrore(err))
                .finally(()=> setIsLoading(false));
        }

    }

    const deleteAtleta = e => {
        e.preventDefault();
        const atleta_deleted = {
            idatleta: idatleta
        }
        console.log(atleta_deleted)
        DeleteAtletaMaster(atleta_deleted)
            .then(resp => {
                console.log('Atleta eliminato')
                onUpdateAtleta(atleta_deleted, 'delete');
            })
            .catch(err => setErrore(err))
    }

    return (
        <Fragment>
            {messaggio && (
                <div className="alert alert-success mb-1 py-1 shadow-sm fw-bold" role="alert" style={{ fontSize: '1rem' }}>
                    {messaggio}
                </div>
            )
            }
            {errore && (
                <div className="alert alert-danger mb-1 py-1 shadow-sm fw-bold" role="alert" style={{ fontSize: '1rem' }}>
                    {errore}
                </div>
            )
            }
            <form onSubmit={editAtleta}>
                <div className="row">
                    <div className="col-lg mb-3">
                        <label htmlFor="nome" className="w-100 mb-0" style={{ fontSize: '1rem' }}>Nome</label>
                        <input type="text" value={nome} onChange={e => setNome(e.target.value)} className="form-control form-control-lg" placeholder="Inserisci il nome" name="nome" id="nome" required />
                    </div>
                    <div className="col-lg mb-3">
                        <label htmlFor="cognome" className="w-100 mb-0" style={{ fontSize: '1rem' }}>Cognome</label>
                        <input type="text" value={cognome} onChange={e => setCognome(e.target.value)} className="form-control form-control-lg" placeholder="Inserisci il nome" name="cognome" id="cognome" required />
                    </div>
                </div>

                <div className="row">
                    <div className="col-lg mb-3">
                        <label htmlFor="email" className="w-100 mb-0" style={{ fontSize: '1rem' }}>Indirizzo email</label>
                        <input type="email" value={email} onChange={e => setEmail(e.target.value)} className="form-control form-control-lg" placeholder="Inserisci l'indirizzo email" name="email" id="email" />
                    </div>
                    <div className="col-lg mb-3">
                        <label className="w-100 mb-0" style={{ fontSize: '1rem' }}>Sesso</label>
                        <select value={sesso} onChange={e => setSesso(e.target.value)} className="form-select form-select-lg">
                            <option value="M">Maschio</option>
                            <option value="F">Femmina</option>
                        </select>
                    </div>
                </div>
                <div className="row align-items-center">
                    <div className="col-lg mb-3">
                        <label className="form-check-label" htmlFor="anno" style={{ fontSize: '1rem' }}>Anno nascita</label>
                        <input className="form-control form-control-lg" type="number" value={anno} onChange={e => setAnno(parseInt(e.target.value))} id="anno" />
                    </div>
                    <div className="col-lg mb-3">
                        <input className="form-check-input me-1" type="checkbox" checked={associato} onChange={e => setAssociato(e.target.checked)} id="associato" />
                        <label className="form-check-label" htmlFor="associato" style={{ fontSize: '1rem' }}>
                            Associato Aquareal
                        </label>
                    </div>
                </div>

                <div className="form-group mb-3">
                    <label htmlFor="note" style={{ fontSize: '1rem' }}>Note</label>
                    <textarea rows="3" value={note} onChange={e => setNote(e.target.value)} className="form-control form-control-lg" name="note" id="note"></textarea>
                </div>
                <div className="d-flex">
                    <button type="submit" disabled={isLoading} className="btn btn-lg btn-success w-100" style={{ borderRadius: '0' }}>
                        Salva
                    </button>
                    {idatleta > 0 && (
                        <button onClick={deleteAtleta} disabled={isLoading} className="btn btn-lg btn-danger w-100" style={{ borderRadius: '0' }}>
                            Elimina
                        </button>
                    )}
                </div>
            </form>
        </Fragment>
    );
}
