import { Fragment, useEffect, useState } from "react";
import { useUser } from "../../hooks/UserContext";
import useScrollTop from "../../hooks/useScrollTop";
import useTitle from "../../hooks/useTitle";
import { GetAnagraficaAtleti } from "../../rest/api";
import Caricamento from "../common/Caricamento";
import TabellaGestioneAtleti from "./TabellaGestioneAtleti";
import Modal from 'react-bootstrap/Modal';
import ContentAtleta from "./ContentAtleta";


export default function GestioneAtletiPage() {
    const [atleti, setAtleti] = useState();
    const [idatletaSelezionato, setIdatletaSelezionato] = useState(-1);
    const [counterModifiche, setCounterModifiche] = useState(0);
    const [show, setShow] = useState(false);

    useTitle('Gestione Atleti')
    useScrollTop();
    const [user] = useUser();

    useEffect(() => {
        let doUpdate = true;
        GetAnagraficaAtleti()
            .then(result => {
                if (doUpdate)
                    setAtleti(result.data);
            })
        return () => doUpdate = false;
    }, [counterModifiche])

    const selectAtleta = a => {
        console.log(a)
        console.log('seleziono atleta ' + a);
        setIdatletaSelezionato(a);
        setShow(true);
    };

    const aggiornaAtleti = (idatleta, azione) => {
        //if (azione === 'new') {
        //    setIdatletaSelezionato(idatleta);
        //}
        //else if (azione === 'delete') {
            setShow(false);
            setIdatletaSelezionato(-1);
        //}
        setCounterModifiche(val => val + 1);
        console.log(idatleta)
        console.log(azione.toUpperCase());
    };

    const atletaSelezionato = atleti?.find(x => x.idatleta === idatletaSelezionato);

    console.log(atleti)
    return (
        <Fragment>
            {!atleti && <Caricamento messaggio="Caricamento atleti in corso" />}
            {atleti && (
                <Fragment>
                    <p className="text-center mb-4">
                        Le foto profilo degli atleti devono essere nel rapporto di forma 1.41
                    </p>
                    <button className="btn mb-5 w-100 btn-lg btn-dark" onClick={() => {
                        setIdatletaSelezionato(-1);
                        setShow(true);
                    }}>Nuovo atleta</button>

                    <h2 className="text-center mb-4">Atleti associati</h2>
                    <TabellaGestioneAtleti dati={atleti.filter(x => x.associato)} onSelectAtleta={selectAtleta} />

                    <h2 className="text-center mb-4 mt-5">Atleti non associati</h2>
                    <TabellaGestioneAtleti dati={atleti.filter(x => !x.associato)} onSelectAtleta={selectAtleta} />

                    <Modal
                        show={show}
                        onHide={() => setShow(false)}
                        scrollable
                        backdrop="static"
                        keyboard={false}
                        size="lg"
                    >
                        <Modal.Header closeButton closeVariant='white' style={{ backgroundColor: 'rgba(0,0,0,0.85)', color: 'white' }}>
                            <Modal.Title className="w-100 text-center">
                                {atletaSelezionato ? `${atletaSelezionato.idatleta} ${atletaSelezionato.cognome.toUpperCase()} ${atletaSelezionato.nome}` : `Creazione Atleta`}
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <ContentAtleta dati={atletaSelezionato} onUpdateAtleta={aggiornaAtleti} />
                        </Modal.Body>
                    </Modal>
                </Fragment>
            )
            }
        </Fragment>
    );
}
