import { BsEnvelope, BsFacebook, BsInstagram } from 'react-icons/bs';



export default function Footer() {
    return (
        <div className="mt-5">
            <img
                src={`${process.env.PUBLIC_URL + '/assets/cornicereal.png'}`}
                className="img-fluid w-100"
                alt=''
            />
            <div id="footer" className="py-5 text-center" style={{ color: 'white' }}>
                <img src={process.env.PUBLIC_URL + '/assets/logo-bianco.png'} className="img-fluid d-block mx-auto mb-3" style={{ height: '8rem' }} alt="" />
                <p>ASD Aquareal | {new Date().getFullYear()}</p>
                <div className='text-center'>
                    <a href={'mailto:' + process.env.REACT_APP_EMAIL}>
                        <BsEnvelope className="me-2" style={{ fontSize: '2rem' }} />
                    </a>
                    <a href={process.env.REACT_APP_FACEBOOK}>
                        <BsFacebook className="me-2" style={{ fontSize: '2rem' }} />
                    </a>
                    <a href={process.env.REACT_APP_INSTAGRAM} >
                        <BsInstagram style={{ fontSize: '2rem' }} />
                    </a>
                </div>
            </div >
        </div >
    )
}