import { Fragment, useState } from "react";
import { useUser } from "../../hooks/UserContext";
import useScrollTop from "../../hooks/useScrollTop";
import useTitle from "../../hooks/useTitle";
import { SendCv } from "../../rest/api";
import Copertina from "../common/Copertina";


const annunci = [

];

export default function LavoraConNoiPage() {
    const [nome, setNome] = useState('');
    const [email, setEmail] = useState('');
    const [messaggio, setMessaggio] = useState('');
    const [cv, setCv] = useState(null);

    const [isLoading, setIsloading] = useState(false);
    const [esito, setEsito] = useState('');
    const [errore, setErrore] = useState('');

    useTitle('Lavora con noi')
    useScrollTop();
    const [user] = useUser();

    const onFileChange = e => {
        let files = e.target.files;
        let fileReader = new FileReader();
        fileReader.readAsDataURL(files[0]);

        fileReader.onload = (event) => {
            setCv(event.target.result)
        }
    }

    const sendEmail = e => {
        e.preventDefault();
        const dati = {
            nome: nome,
            email: email,
            messaggio: messaggio,
            cv: cv
        }
        console.log(dati);

        setIsloading(true);
        setEsito('');
        setErrore('');
        let doUpdate = true;
        SendCv(dati).then(result => {
            if (doUpdate) {
                console.log(result)
                setEsito('Grazie! Email inviata');
            }
        }).catch(error => {
            if (doUpdate) {
                console.warn(error)
                setErrore('Si è verificato un errore, email non inviata');
            }
        })
            .finally(() => {
                if (doUpdate)
                    setIsloading(false)
            })

        return () => doUpdate = false;
    }

    return (
        <Fragment>
            <Copertina
                titolo="Lavora con Aquareal"
                sottotitolo="Segui questa pagina per restare al corrente delle figure richieste oppure inviaci il tuo CV per entrare nello staff Aquareal"
                // immagine="logo-bianco2.png"
                sfondo="cover-lavoraconnoi.jpg"
            />


            {annunci.length > 0 && (
                <div className="container" style={{ marginTop: 'max(8%, 3rem)', marginBottom: 'max(8%, 3rem)' }}>
                    <div className="row text-center">
                        <div className="col">
                            <h1 className="mb-3">Annunci</h1>
                            <p className="mb-4">Ci sono figure richieste da Aquareal</p>
                            {annunci.map((x, idx) => (
                                <div key={idx} className="alert alert-primary mb-2" role="alert">
                                    {x.descrizione}
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            )}


            <div className="container" style={{ marginTop: 'max(8%, 3rem)', marginBottom: 'max(8%, 3rem)' }}>
                <div className="row">
                    <div className="col">
                        <h1 className="mb-5 text-center">Invia il tuo CV</h1>

                        <form onSubmit={sendEmail} className="pannello p-4 p-lg-5 mx-auto" method="post" encType="multipart/form-data" style={{ maxWidth: '600px' }}>
                            {esito && (
                                <div className="alert alert-success mb-2 py-1 shadow-sm fw-bold" role="alert" style={{ fontSize: '1rem' }}>
                                    {esito}
                                </div>
                            )
                            }
                            {errore && (
                                <div className="alert alert-danger mb-2 py-1 shadow-sm fw-bold" role="alert" style={{ fontSize: '1rem' }}>
                                    {errore}
                                </div>
                            )
                            }
                            <div className="form-group mb-3">
                                <label htmlFor="nome" className="w-100 mb-1" style={{ fontSize: '1rem' }}>Nome (obbligatorio)</label>
                                <input type="text" value={nome} onChange={e => setNome(e.target.value)} className="form-control" placeholder="Inserisci il tuo nome" name="nome" id="nome" required />
                            </div>
                            <div className="form-group mb-3">
                                <label htmlFor="email" className="w-100 mb-1" style={{ fontSize: '1rem' }}>Indirizzo email (obbligatorio)</label>
                                <input type="email" value={email} onChange={e => setEmail(e.target.value)} className="form-control" placeholder="Inserisci il tuo indirizzo email" name="email" id="email" required />
                            </div>
                            <div className="form-group mb-3">
                                <label htmlFor="cv" className="w-100 mb-1" style={{ fontSize: '1rem' }}>Curriculum (obbligatorio)</label>
                                <div className="input-group">
                                    <div className="custom-file">
                                        <input type="file" onChange={onFileChange} accept="application/pdf" className="" name="cv" id="cv" aria-describedby="inputGroupFileAddon04" required />
                                    </div>
                                </div>
                            </div>
                            <div className="form-group">
                                <label htmlFor="info" style={{ fontSize: '1rem' }}>Messaggio:</label>
                                <textarea rows="5" value={messaggio} onChange={e => setMessaggio(e.target.value)} className="form-control" name="info" id="info"></textarea>
                                <p className="mt-2" style={{ fontSize: '1rem', lineHeight: '1.4' }}>Tutte le informazioni inserite serviranno esclusivamente per contattarti e non saranno salvate in alcun modo per altri fini o diffuse a terzi.</p>
                            </div>
                            <button type="submit" disabled={isLoading} className="btn btn-lg btn-success w-100">
                                Invia
                            </button>
                        </form>
                    </div>
                </div>
            </div>


        </Fragment>
    );
}
