import { colorCircuito, printData } from "../../utility";


export default function ItemTrofeo({ dati }) {

    return (
        <div className="pannello mx-auto mb-4 p-3 p-lg-3" style={{ maxWidth: '900px' }}>
            <div className="row align-items-center">
                <div className="col-lg-3 text-center mb-3 mb-lg-0">
                    <img
                        onError={({ currentTarget }) => {
                            currentTarget.onerror = null; // prevents looping
                            currentTarget.src = process.env.PUBLIC_URL + '/assets/trofei/trofeo0.png';
                        }}
                        src={process.env.PUBLIC_URL + '/assets/trofei/' + dati.nome_file}
                        loading="lazy"
                        style={{ maxHeight: '12rem', maxWidth: '100%' }}
                        alt="..." />
                </div>
                <div className="col-lg text-center text-lg-start">
                    <span className="d-inline-block mb-2 badge" style={{ backgroundColor: colorCircuito(dati.circuito) }}>
                        {dati.circuito.toUpperCase()}
                    </span>
                    <h3 className="fw-bold">{dati.descrizione}</h3>
                    <p className="mb-0">
                        Trofeo consegnato in data {printData(dati.data)}<br />
                        {dati.posizione}° posizione
                    </p>
                </div>
            </div>
        </div>
    );
}
