
export default function LegendaRecord({ tipo = "personale" }) {
    return (
        <div className="mx-auto" style={{ maxWidth: '320px', fontSize: '1rem' }}>
            <div className="d-flex align-items-center">
                <span className="d-inline-block me-1" style={{ width: '1.3rem', height: '1.3rem', border: '1px solid forestgreen', backgroundImage: 'linear-gradient(0deg, chartreuse, lime)', borderRadius: '4px' }}></span>
                Migliore prestazione {tipo}
            </div>

            <div className="d-flex align-items-center">
                <span className="d-inline-block me-1" style={{ width: '1.3rem', height: '1.3rem', border: '1px solid goldenrod', backgroundImage: 'linear-gradient(0deg, yellow, gold)', borderRadius: '4px' }}></span>
                Migliore prestazione societaria
            </div>

            <div className="d-flex align-items-center">
                <span className="d-inline-block me-1" style={{ width: '1.3rem', height: '1.3rem', border: '1px solid limegreen', backgroundImage: 'linear-gradient(0, aqua, darkturquoise)', borderRadius: '4px' }}></span>
                Migliore prestazione {tipo} e societaria
            </div>
        </div>
    )
}