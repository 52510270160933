import { colorCircuito, iconaGara, printData, printTempo } from "../../utility";
import { BsSearch } from 'react-icons/bs';
import { Link } from "react-router-dom";
import { Fragment, useState } from "react";
import Riepilogo from "../common/Riepilogo";

export default function TabellaRecordSocietariMaster({ dati }) {
    const [selezionato, setSelezionato] = useState(null);
    const gare = Array.from(new Set(dati.map(x => x.gara)));
    console.log(gare);

    return (
        <Fragment>
            {gare.map(g => {
                return (
                    <div key={g}>
                        <h2 className="mt-5 fw-bold">
                            <img src={process.env.PUBLIC_URL + '/assets/' + iconaGara(g)} className="img-fluid me-2" style={{ height: '2.8rem', position: 'relative', top: '-2px' }} alt="" />
                            {g}
                        </h2>
                        <table className="w-100 shadow" style={{ fontSize: '1.1rem', lineHeight: '1.2' }}>
                            <thead>
                                <tr className="fw-bold">
                                    <th className="text-center py-1" style={{ width: '4rem' }}>CAT</th>
                                    <th className="py-1" style={{ width: '15rem' }}>ATLETA</th>
                                    <th className="py-1 d-none d-xl-table-cell" style={{ width: '3rem' }}></th>
                                    <th className="py-1 px-2 d-none d-md-table-cell">EVENTO</th>
                                    <th className="text-center py-1 d-none d-lg-table-cell" style={{ width: '10rem' }}>DATA</th>
                                    <th className="text-end py-1 pe-2" style={{ width: '6rem' }}>TEMPO</th>
                                    <th className="text-center py-1 d-none d-xl-table-cell" style={{ width: '5rem' }}>PUNTI</th>
                                    <th className="text-center py-1 d-none d-xl-table-cell" style={{ width: '5rem' }}>TEMPI</th>
                                    <th className="py-1 d-table-cell d-xl-none" style={{ width: '2rem' }}></th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    dati
                                        .filter(x => x.gara === g)
                                        .map(x => {
                                            let atletaX = x.cognome.toUpperCase() + ' ' + x.nome;
                                            if (x.associato)
                                                atletaX = <Link to={`/master/atleti/${x.idatleta}`}>{atletaX}</Link>;

                                            return (
                                                <tr key={x.idtempo} className={x.stagionale && x.associato ? 'row-active' : ''}>
                                                    <td className="text-center py-1">
                                                        {x.categoria.replace('-', '/')}
                                                    </td>
                                                    <td className="py-1">
                                                        {atletaX}
                                                    </td>
                                                    <td className="py-1 text-end d-none d-xl-table-cell">
                                                        <span className="badge w-100 shadow" style={{ backgroundColor: colorCircuito(x.circuito), fontWeight: 'normal' }}>
                                                            {x.circuito.slice(0, 4).toUpperCase()}
                                                        </span>
                                                    </td>
                                                    <td className="px-2 py-1 d-none d-md-table-cell" style={{ minWidth: '17rem' }}>
                                                        {x.evento_visibile ?
                                                            <Link to={`/master/eventi/${x.idevento}`}>{x.evento}</Link> :
                                                            x.evento
                                                        }
                                                    </td>
                                                    <td className="text-center px-2 py-1 d-none d-lg-table-cell">
                                                        {printData(x.data)}
                                                    </td>
                                                    <td className="text-end py-1 pe-2">
                                                        {printTempo(x.tempo, false)}
                                                    </td>
                                                    <td className="text-center py-1 d-none d-xl-table-cell">
                                                        {x.punti ?? '-'}
                                                    </td>
                                                    <td className="text-center py-1 d-none d-xl-table-cell">
                                                        {x.quanti}
                                                    </td>
                                                    <td className="text-center py-1 d-table-cell d-xl-none">
                                                        <button onClick={() => setSelezionato(x)} className="btn btn-dark px-1 py-0">
                                                            <BsSearch />
                                                        </button>
                                                    </td>
                                                </tr>
                                            )
                                        })
                                }
                            </tbody>
                        </table>
                    </div>
                )
            })}
            {selezionato && <Riepilogo dati={
                {
                    gara: selezionato.gara,
                    atleta: selezionato.cognome + ' ' + selezionato.nome,
                    data: printData(selezionato.data),
                    circuito: selezionato.circuito,
                    evento: selezionato.evento,
                    categoria: selezionato.categoria,
                    tempo: printTempo(selezionato.tempo),
                    punti: selezionato.punti,
                    tentativi: selezionato.quanti
                }
            } titolo={'Tempo'} onClose={() => setSelezionato(null)} />}
        </Fragment>
    );
}
