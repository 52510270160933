import { Link } from "react-router-dom";
import { printData, printDataTime } from "../../utility";


export default function GridArticoli({ articoli }) {

    return (
        <div className="row row-cols-1 row-cols-md-2 row-cols-lg-2 row-cols-xl-3 g-4">
            {articoli.map(x => (
                <div key={x.idarticolo} className="col">
                    <div className="card shadow h-100" style={{ borderTopLeftRadius: '1rem', borderTopRightRadius: '1rem', overflow: 'hidden' }}>
                        <div className="zoom position-relative">
                            <span className="badge bg-dark position-absolute" style={{ fontWeight: 'normal', bottom: '5px', left: '5px', zIndex: '1' }}>
                                {printData(x.data)}
                            </span>
                            <img
                                onError={({ currentTarget }) => {
                                    currentTarget.onerror = null; // prevents looping
                                    currentTarget.src = process.env.PUBLIC_URL + '/assets/articoli/' + x.copertina;
                                }}
                                src={process.env.PUBLIC_URL + '/assets/atleti/atleta' + x.idatleta + '.jpg'}
                                loading="lazy"
                                className="card-img-top"
                                style={{ width: '100%', height: 'max(min(26vw, 31vh), 15rem)', objectFit: 'cover' }}
                                alt="..." />
                            {/* <div className="position-absolute py-1 text-center w-100" style={{ bottom: '0', left: '0', color: 'white', backgroundColor: 'rgba(0,0,0,0.8)' }}>
                                {x.titolo.toUpperCase()} {x.nome}
                            </div>  */}
                        </div>
                        <div className="card-body">
                            <h5 className="card-title fw-bold mb-1">
                                {x.titolo.toUpperCase()}
                            </h5>
                            <p className="card-text mb-3" style={{ fontSize: '1rem' }}>
                                {x.sottotitolo}
                            </p>
                            <p className="mb-0" style={{ fontSize: '1rem', fontStyle: 'italic', lineHeight: '1.3' }}>
                                {x.testo.substr(0, 100)}...
                            </p>
                        </div>
                        <div className="card-footer">
                            <Link to={`/articoli/${x.idarticolo}`} className="w-100">
                                <button className="btn btn-outline-dark w-100">
                                    Continua a leggere
                                </button>
                            </Link>
                        </div>
                    </div>
                </div>
            ))}
        </div>
    );
}
