import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Offcanvas from 'react-bootstrap/Offcanvas';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { BsSearch } from 'react-icons/bs';
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useUser } from "../../hooks/UserContext";
import { Fragment, useCallback, useEffect, useState } from 'react';
import { GetAtletiMasterAssociati, GetEventiStagione } from '../../rest/api';
import '../../css/menu-principale.css';
import { colorCircuito } from '../../utility';
import { BsFillBriefcaseFill, BsFillCalculatorFill,BsFillHddRackFill,BsStopwatchFill,BsStopwatch,BsUnlockFill,BsFillBarChartFill,BsAwardFill,BsBookFill, BsPeopleFill, BsFillEnvelopeFill,BsFillTrophyFill, BsFillHouseFill, BsCalendar3Week } from 'react-icons/bs';

export default function MenuPrincipale2() {
    const [eventi, setEventi] = useState();
    const [atletiMaster, setAtletiMaster] = useState();
    const [user, setUser] = useUser();
    const [expanded, setExpanded] = useState(false);
    const navigate = useNavigate();

    const location = useLocation();
    const [url, setUrl] = useState(null);
    useEffect(() => {
        setUrl(location.pathname);
    }, [location]);

    useEffect(() => {
        let doUpdate = true;
        GetAtletiMasterAssociati()
            .then(result => {
                if (doUpdate)
                    setAtletiMaster(result.data)
            })
        return () => doUpdate = false;
    }, [])

    useEffect(() => {
        let doUpdate = true;
        GetEventiStagione()
            .then(result => {
                if (doUpdate)
                    setEventi(result.data)
            })
        return () => doUpdate = false;
    }, [])

    console.log(atletiMaster)

    const logout = useCallback(() => {
        setUser(null);
        setExpanded(false);
        localStorage.removeItem('login_data');
        navigate('/master/login');
    }, [])

    console.log(url)

    const closeOffcanvas = () => {
        setExpanded(false);
    };

    return (
        <Navbar id="menu-principale" expanded={expanded} bg="dark" variant="dark" collapseOnSelect={true} expand="lg" sticky="top" style={{ 'fontSize': '1rem' }}>
            <Container>
                <Navbar.Brand as={Link} to="/">
                    <img
                        src={process.env.PUBLIC_URL + '/assets/logo-bianco2.png'}
                        loading="lazy"
                        className='me-2 position-relative'
                        style={{ maxHeight: '1.8rem', top: '-2px' }}
                        alt="..." />
                    ASD Aquareal
                </Navbar.Brand>
                <Navbar.Toggle onClick={() => setExpanded(expanded ? false : "expanded")} />
                <Navbar.Offcanvas id={`offcanvas-navbar`} placement="start">
                    <Offcanvas.Header closeButton closeVariant='white' onClick={closeOffcanvas}>
                        <Offcanvas.Title id={`offcanvasNavbarLabel-expand-lg`} className="fw-bold">
                            Menù Aquareal
                        </Offcanvas.Title>
                    </Offcanvas.Header>
                    <Offcanvas.Body>
                        <Nav className="justify-content-end flex-grow-1 pe-3">
                            <Nav.Link as={Link} to="/" className={(url === '/' ? "nav-attivo" : null)} onClick={closeOffcanvas}>Associazione</Nav.Link>
                            {/* <Nav.Link as={Link} to="/collaboratori" className={(url === '/collaboratori' ? "nav-attivo" : null)} onClick={closeOffcanvas}>Collaboratori</Nav.Link> */}
                            <Nav.Link as={Link} to="/lavoro" className={(url === '/lavoro' ? "nav-attivo" : null)} onClick={closeOffcanvas}>Lavora con noi</Nav.Link>
                            <Nav.Link as={Link} to="/contatti" className={(url === '/contatti' ? "nav-attivo" : null)} onClick={closeOffcanvas}>Contatti</Nav.Link>
                            <Nav.Link as={Link} to="/articoli" className={(url?.startsWith('/articoli') ? "nav-attivo" : null)} onClick={closeOffcanvas}>Articoli</Nav.Link>

                            <NavDropdown title="Master" align="end" className={(url?.startsWith('/master') && !url?.startsWith('/master/atleti') && !url?.startsWith('/master/eventi') ? "nav-attivo" : null)}>
                                <NavDropdown.Item as={Link} to={`master/squadra`} onClick={closeOffcanvas}>
                                    Squadra
                                </NavDropdown.Item>
                                <NavDropdown.Item as={Link} to={`master/eventi`} onClick={closeOffcanvas}>
                                    Eventi
                                </NavDropdown.Item>
                                <NavDropdown.Item as={Link} to={`master/recordsocietari`} onClick={closeOffcanvas}>
                                    Record Societari
                                </NavDropdown.Item>
                                <NavDropdown.Item as={Link} to={`master/calcolatrice`} onClick={closeOffcanvas}>
                                    Calcolatrice
                                </NavDropdown.Item>
                                <NavDropdown.Item as={Link} to={`master/trofei`} onClick={closeOffcanvas}>
                                    Trofei
                                </NavDropdown.Item>
                                {!user && (
                                    <NavDropdown.Item as={Link} to="master/login" onClick={closeOffcanvas}>Login</NavDropdown.Item>
                                )}
                                {/*<NavDropdown.Divider />
                                 <NavDropdown.Header>Atleti</NavDropdown.Header>
                                {
                                    atletiMaster && atletiMaster.map(x => (
                                        <NavDropdown.Item key={x.idatleta} as={Link} to={`master/atleti/${x.idatleta}`} onClick={closeOffcanvas}>
                                            <span className="badge me-1 px-1" style={{ backgroundColor: x.sesso === 'M' ? 'blue' : 'deeppink', fontWeight: 'normal' }}>
                                                {x.categoria}
                                            </span>
                                            {x.cognome.toUpperCase()} {x.nome}
                                        </NavDropdown.Item>
                                    ))
                                } */}
                            </NavDropdown>
                            {
                                atletiMaster && (
                                    <NavDropdown title="Atleti" align="end" className={(url?.startsWith('/master/atleti') ? "nav-attivo" : null)}>
                                        {
                                            atletiMaster.map(x => (
                                                <NavDropdown.Item key={x.idatleta} as={Link} to={`master/atleti/${x.idatleta}`} onClick={closeOffcanvas}>
                                                    <span className="badge position-relative px-1 me-1" style={{ backgroundColor: x.sesso === 'M' ? 'blue' : 'deeppink', fontWeight: 'normal', left: '-4px', top: '-1px' }}>
                                                        {x.categoria}
                                                    </span>
                                                    {x.cognome.toUpperCase()} {x.nome}
                                                </NavDropdown.Item>
                                            ))
                                        }
                                    </NavDropdown>
                                )
                            }
                            {
                                eventi && eventi.length > 0 && (
                                    <NavDropdown title="Eventi" align="end" className={(url?.startsWith('/master/eventi') ? "nav-attivo" : null)}>
                                        {
                                            eventi.map(x => (
                                                <NavDropdown.Item key={x.idevento} as={Link} to={`master/eventi/${x.idevento}`} onClick={closeOffcanvas}>
                                                    <span className="badge position-relative px-1 me-1" style={{ backgroundColor: colorCircuito(x.circuito), fontWeight: 'normal', left: '-4px', top: '-1px', width: '2.5rem' }}>
                                                        {x.circuito.slice(0, 4).toUpperCase()}
                                                    </span>
                                                    {x.nome.toUpperCase()}<span className='d-none d-lg-inline'>, {x.piscina}</span>
                                                </NavDropdown.Item>
                                            ))
                                        }
                                    </NavDropdown>
                                )
                            }
                            <Nav.Link as={Link} to="/cerca" className={(url?.startsWith('/cerca') ? "nav-attivo" : null)} onClick={closeOffcanvas}>
                                <BsSearch /> <span className='ps-1 d-inline d-lg-none'>Cerca</span>
                            </Nav.Link>
                            {user && (
                                <NavDropdown title={
                                    <Fragment>
                                        <img className="thumbnail-image position-relative me-2"
                                            src={process.env.PUBLIC_URL + '/assets/thumbs/atleta' + user.idatleta + '.png'}
                                            alt="user pic"
                                            style={{ maxWidth: '2rem', top: '-1px' }}
                                        />
                                        <span className='ps-1 d-inline d-lg-none'>{user.cognome.toUpperCase()} {user.nome}</span>
                                    </Fragment>
                                } align="end">
                                    {/* <NavDropdown.Item as={Link} to={`master/atleti/${user.idatleta}`}>Scheda personale</NavDropdown.Item> */}
                                    <NavDropdown.Item as={Link} to={`master/atleti/${user.idatleta}`} onClick={closeOffcanvas}>Record</NavDropdown.Item>
                                    <NavDropdown.Item as={Link} to={`master/atleti/${user.idatleta}/puntimax`} onClick={closeOffcanvas}>Punteggi massimi</NavDropdown.Item>
                                    <NavDropdown.Item as={Link} to={`master/atleti/${user.idatleta}/tempi`} onClick={closeOffcanvas}>Tempi</NavDropdown.Item>
                                    <NavDropdown.Item as={Link} to={`master/atleti/${user.idatleta}/progressione`} onClick={closeOffcanvas}>Progressione</NavDropdown.Item>
                                    <NavDropdown.Item as={Link} to={`master/atleti/${user.idatleta}/medagliere`} onClick={closeOffcanvas}>Medagliere</NavDropdown.Item>
                                    <NavDropdown.Item as={Link} to="master/login" onClick={logout}>Logout</NavDropdown.Item>

                                    {user.idatleta === 1 && (
                                        <Fragment>
                                            <NavDropdown.Divider />
                                            <NavDropdown.Header>Amministratore</NavDropdown.Header>
                                            <NavDropdown.Item as={Link} to={`master/gestione/atleti`} onClick={closeOffcanvas}>Atleti</NavDropdown.Item>
                                            <NavDropdown.Item as={Link} to={`master/gestione/eventi`} onClick={closeOffcanvas}>Eventi</NavDropdown.Item>
                                            <NavDropdown.Item as={Link} to={`master/gestione/piscine`} onClick={closeOffcanvas}>Piscine</NavDropdown.Item>
                                            <NavDropdown.Item as={Link} to={`master/gestione/trofei`} onClick={closeOffcanvas}>Trofei</NavDropdown.Item>
                                            <NavDropdown.Item as={Link} to={`master/gestione/articoli`} onClick={closeOffcanvas}>Articoli</NavDropdown.Item>
                                        </Fragment>
                                    )}
                                </NavDropdown>
                            )}

                        </Nav>
                    </Offcanvas.Body>
                </Navbar.Offcanvas>
            </Container>
        </Navbar>
    );
}
