import { Fragment, useEffect, useState } from "react";
import { Outlet, useParams } from "react-router-dom";
import useTitle from "../../hooks/useTitle";
import { GetAtletaMaster } from "../../rest/api";
import Copertina from "../common/Copertina";
import MenuAtleta from "./MenuAtleta";


export default function AtletaMasterPage() {
    let param = useParams();
    const [atleta, setAtleta] = useState();
    useTitle(atleta ? atleta.cognome.toUpperCase() + ' ' + atleta.nome : null);

    useEffect(() => {
        console.log('RESET SCROLL')
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        let doUpdate = true;
        GetAtletaMaster(param.idatleta)
            .then(result => {
                if (doUpdate)
                    setAtleta(result.data);
            })
        return () => doUpdate = false;
    }, [param.idatleta])


    console.log(atleta)

    if (!atleta)
        return null;

    let descrizioneAtleta = 'Categoria ' + atleta.categoria;
    if (atleta.stagioni > 0)
        descrizioneAtleta += ' | ' + atleta.stagioni + ` stagion${atleta.stagioni !== 1 ? 'i' : 'e'} in Aquareal`;
    if (atleta.eventi > 0) {
        descrizioneAtleta += '\n';
        if (atleta.gare > 0)
            descrizioneAtleta += `${atleta.gare} gar${atleta.gare !== 1 ? 'e' : 'a'}`
        if (atleta.staffette > 0) {
            if (atleta.gare > 0)
                descrizioneAtleta += ' e ';
            descrizioneAtleta += `${atleta.staffette} staffett${atleta.staffette !== 1 ? 'e' : 'a'}`
        }
        descrizioneAtleta += ` in ${atleta.eventi} event${atleta.eventi !== 1 ? 'i' : 'o'}`
        if ((atleta.ori + atleta.argenti + atleta.bronzi) > 0) {
            descrizioneAtleta += ' con ';
            let infoMedaglie = [];
            if (atleta.ori > 0)
                infoMedaglie.push(`${atleta.ori} or${atleta.ori !== 1 ? 'i' : 'o'}`);
            if (atleta.argenti > 0)
                infoMedaglie.push(`${atleta.argenti} argent${atleta.argenti !== 1 ? 'i' : 'o'}`);
            if (atleta.bronzi > 0)
                infoMedaglie.push(`${atleta.bronzi} bronz${atleta.bronzi !== 1 ? 'i' : 'o'}`)
            descrizioneAtleta += infoMedaglie.join(', ');
        }
    }

    return (
        <Fragment>
            <Copertina
                titolo={atleta.nome + ' ' + atleta.cognome.toUpperCase()}
                sottotitolo={descrizioneAtleta}
                immagine={"thumbs/atleta" + atleta.idatleta + '.png'}
                sfondo="cover-atleta.jpg"
            />
            <MenuAtleta idatleta={param.idatleta} />
            <div className="container-lg my-0 my-md-5 py-5">
                <div className="row">
                    <div className="col">
                        <Outlet />
                    </div>
                </div>
            </div>
        </Fragment>
    );
}
