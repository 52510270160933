import { Fragment, useEffect, useState } from "react";
import { BsFillSquareFill } from "react-icons/bs";
import { Link, useParams } from "react-router-dom";
import useScrollTop from "../../hooks/useScrollTop";
import { GetIscrizioniEvento } from "../../rest/api";
import { colorGara } from "../../utility";
import Caricamento from "../common/Caricamento";


export default function Iscrizioni() {
    let param = useParams();
    const [gare, setGare] = useState();
    useScrollTop();

    useEffect(() => {
        let doUpdate = true;
        GetIscrizioniEvento(param.idevento)
            .then(result => {
                if (doUpdate) {
                    setGare(result.data);
                }
            })
        return () => doUpdate = false;
    }, [param.idevento])

    console.log(gare)

    if (!gare)
        return <Caricamento messaggio="Caricamento iscrizioni in corso" />

    let partecipanti = [];
    gare.forEach(x => {
        const num_atleti = x.individuale ? 1 : 4;
        for (let i = 1; i <= num_atleti; i++) {
            const idx = partecipanti.findIndex(a => a.idatleta === x['idatleta' + i]);
            if (idx === -1) {
                partecipanti.push({
                    idatleta: x['idatleta' + i],
                    nome: x['nome' + i],
                    cognome: x['cognome' + i],
                    sesso: x['sesso' + i],
                    associato: x['associato' + i]
                })
            }
        }
    })

    partecipanti = partecipanti.filter(x => x.idatleta !== 3);
    partecipanti.sort((a, b) => a.cognome.toUpperCase() > b.cognome.toUpperCase() ? 1 : -1);
    console.log('ISCRITTI')
    console.log(partecipanti)

    const num_gare_individuali = gare.filter(x => x.individuale).length;
    const num_gare_staffette = gare.filter(x => !x.individuale).length;
    const num_partecipanti = partecipanti.length;

    return (
        <Fragment>
            <div className="text-center">
                <h2>Iscrizioni gare</h2>
                <p className="mb-0">
                    {num_partecipanti} {num_partecipanti > 1 ? 'atleti iscritti' : 'atleta iscritto'} a {num_gare_individuali > 0 ? num_gare_individuali + (num_gare_individuali > 1 ? ' gare individuali' : ' gara individuale') : ''} {num_gare_staffette > 0 ? (num_gare_individuali > 0 ? ' e ' : '') + num_gare_staffette + (num_gare_staffette > 1 ? ' staffette' : ' staffetta') : ''}
                </p>
            </div>
            <div className="row row-cols-1 row-cols-sm-2 row-cols-lg-3 row-cols-xl-4 justify-content-center g-4 text-center mt-5">
                {partecipanti.map(x => {

                    const gare_atleta = gare.filter(g => g.individuale ? g.idatleta1 === x.idatleta : g.idatleta1 === x.idatleta || g.idatleta2 === x.idatleta || g.idatleta3 === x.idatleta || g.idatleta4 === x.idatleta)

                    return (
                        <div key={x.idatleta}>
                            <img
                                src={`${process.env.PUBLIC_URL + '/assets/thumbs/atleta' + x.idatleta + '.png'}`}
                                className="img-fluid mb-3 shadow"
                                onError={({ currentTarget }) => {
                                    currentTarget.onerror = null; // prevents looping
                                    currentTarget.src = process.env.PUBLIC_URL + '/assets/thumbs/atleta0.png';
                                }}
                                style={{ maxWidth: "10rem", outline: '4px solid ' + (x.sesso === 'M' ? 'blue' : 'deeppink'), outlineOffset: '-1px', borderRadius: '1000px' }}
                                alt=''
                            />
                            <h4>
                                {x.associato ?
                                    <Link to={`/master/atleti/${x.idatleta}`}>{x.cognome.toUpperCase()} {x.nome}</Link> :
                                    <Fragment>
                                        {x.cognome.toUpperCase()} {x.nome}
                                    </Fragment>
                                }
                            </h4>
                            {gare_atleta.map(g => {
                                return (
                                    <p key={x.idatleta + '-' + g.gara} className="mb-0" style={{ lineHeight: '1.2' }}>
                                        {/* <BsFillSquareFill className="me-1" style={{ color: colorGara(g.gara) }} /> */}
                                        {g.gara}
                                    </p>
                                )
                            })}
                        </div>
                    )
                })}


            </div>


        </Fragment>
    )

}
