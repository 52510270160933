import { Fragment, useEffect, useState } from "react";
import { BsCalendar3, BsFillSquareFill } from "react-icons/bs";
import { Link, useParams } from "react-router-dom";
import useScrollTop from "../../hooks/useScrollTop";
import { GetProgrammaEventoMaster2 } from "../../rest/api";
import { colorGara, iconaGara, nomiGiorni, printData } from "../../utility";
import Caricamento from "../common/Caricamento";
import IconaSessione from "../common/IconaSessione";


export default function Programma() {
    let param = useParams();
    const [programma, setProgramma] = useState();
    useScrollTop();

    useEffect(() => {
        let doUpdate = true;
        GetProgrammaEventoMaster2(param.idevento)
            .then(result => {
                if (doUpdate) {
                    setProgramma(result.data);
                }
            })
        return () => doUpdate = false;
    }, [param.idevento])

    console.log(programma)

    if (!programma)
        return <Caricamento messaggio="Caricamento programma in corso" />

    const date = Array.from(new Set(programma.map(x => x.data)));
    console.log('DATE')
    console.log(date)
    return (
        <Fragment>
            <div className="text-center mb-5">
                <h2>Programma gare</h2>
                <p className="mb-0">
                    Gare previste per l'evento
                </p>
            </div>
            <div className="mx-auto pannello px-4 px-md-5" style={{ maxWidth: '700px' }}>
                {
                    date.map(d => {
                        const sessioni = Array.from(new Set(programma.filter(x => x.data === d).map(x => x.sessione)));
                        console.log('SESSIONI')
                        console.log(sessioni)
                        return (
                            <div key={d} className="my-4 my-md-5">
                                <h3 className="fw-bold">
                                    <BsCalendar3 className="position-relative me-2" style={{ top: '-2px' }} />
                                    {nomiGiorni[new Date(d).getDay()].toLowerCase()} {printData(d)}
                                </h3>
                                {
                                    sessioni.map(s => {
                                        const gare_sessione = programma.filter(x => x.data === d && x.sessione === s);
                                        console.log('GARE IN SESSIONE')
                                        console.log(gare_sessione)
                                        return (
                                            <div key={s} className="mb-3">
                                                <i style={{fontSize: '1rem'}}>
                                                    <IconaSessione sessione={s} /> {s === 'M' ? 'Mattina' : 'Pomeriggio'}
                                                </i>
                                                {
                                                    gare_sessione.map(gs => {
                                                        const iscrizioni = gs.iscrizioni;
                                                        return (
                                                            <div key={gs.idprogramma}>
                                                                <div className="ms-3 ms-md-4 mt-2">
                                                                    <h5 className="d-flex mb-2 shadow align-items-center" style={{ outline: '1px solid whitesmoke', borderRadius: '1000px' }}>
                                                                        <img src={process.env.PUBLIC_URL + '/assets/' + iconaGara(gs.gara)} className="img-fluid mx-auto d-inline me-1" style={{ height: '2.2rem', position: 'relative', left: '-1px' }} alt="" />
                                                                        <span className="w-100">{gs.gara}</span>
                                                                    </h5>
                                                                    {
                                                                        iscrizioni.map(i => {

                                                                            const num_atleti = gs.individuale ? 1 : 4;
                                                                            let elem_atleti = [];
                                                                            let indice = 0;
                                                                            for (let a = 1; a <= num_atleti; a++) {
                                                                                let atletaX = i['cognome' + a].toUpperCase() + ' ' + i['nome' + a];
                                                                                if (i['associato' + a] && i['idatleta' + a] !== 3)
                                                                                    atletaX = <Link to={`/master/atleti/${i['idatleta' + a]}`}>{atletaX}</Link>;
                                                                                elem_atleti[indice] = (
                                                                                    <Fragment>
                                                                                        <span className="badge shadow px-1 me-2 position-relative" style={{ top: '-2px', backgroundColor: i['sesso' + a] === 'M' ? 'blue' : 'deeppink', fontWeight: 'normal' }}>
                                                                                            {i.categoria.replace('-', '/')}
                                                                                        </span>
                                                                                        {atletaX}
                                                                                    </Fragment>
                                                                                );
                                                                                if (a < num_atleti) {
                                                                                    indice++;
                                                                                    elem_atleti[indice] = <br />
                                                                                }
                                                                                indice++;
                                                                            }

                                                                            return (
                                                                                <div key={i.idtempo} className="ms-4 ms-md-5" style={{fontSize: '1.1rem'}}>
                                                                                    {elem_atleti.map((e, idx3) => <span key={idx3}>{e}</span>)}
                                                                                </div>
                                                                            )
                                                                        })
                                                                    }
                                                                </div>
                                                            </div>
                                                        )
                                                    })
                                                }
                                            </div>
                                        )


                                    })
                                }
                            </div>
                        )
                    })
                }
            </div>
        </Fragment>
    )

}
