import { Fragment } from "react";
import useScrollTop from "../../hooks/useScrollTop";
import useTitle from "../../hooks/useTitle";
import Copertina from "../common/Copertina";
import GridCircoli from "./GridCircoli";
import {BsEnvelope, BsFacebook, BsInstagram} from  'react-icons/bs';

export default function ContattiPage() {
    useTitle('Contatti')
    useScrollTop();

    return (
        <Fragment>
            <Copertina
                titolo="Contatti"
                sottotitolo="Segui i nostri canali social, per restare aggiornato sulle attività o per richiedere informazioni e curiosità, oppure vieni a trovarci in uno dei nostri circoli"
                // immagine="logo-bianco2.png"
                sfondo="cover-contatti.jpg"
            />

            <div className="container" style={{ marginTop: 'max(8%, 3rem)', marginBottom: 'max(8%, 3rem)' }}>
                <div className="row text-center" style={{fontSize: '2rem'}}>
                    <div className="col-lg mb-4 mb-lg-0">
                        <BsEnvelope className="d-block mx-auto mb-0 mb-lg-3" style={{fontSize: '6rem'}} />
                        <a href={'mailto:' + process.env.REACT_APP_EMAIL}>{process.env.REACT_APP_EMAIL}</a>
                    </div>
                    <div className="col-lg mb-4 mb-lg-0">
                        <BsFacebook className="d-block mx-auto mb-0 mb-lg-3" style={{fontSize: '6rem'}} />
                        <a href={process.env.REACT_APP_FACEBOOK}>Pagina Facebook</a>
                    </div>
                    <div className="col-lg mb-4 mb-lg-0">
                        <BsInstagram className="d-block mx-auto mb-0 mb-lg-3" style={{fontSize: '6rem'}} />
                        <a href={process.env.REACT_APP_INSTAGRAM}>Pagina Instagram</a>
                    </div>
                </div>
            </div>

            {/* <img
        src={`${process.env.PUBLIC_URL + '/assets/cornicereal.png'}`}
        className="img-fluid w-100"
        alt=''
      /> */}


            <GridCircoli />



            <div className="containe-fluid" style={{ marginTop: 'max(8%, 3rem)', marginBottom: 'max(8%, 3rem)' }}>
                <div className="row g-0">
                    <div className="col-lg mb-3 mb-lg-0">
                        <iframe title="real" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3557.980480054833!2d12.38440748361963!3d41.8849306738572!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x132f5f7ebe58bf13%3A0x254b1cc6322a4a82!2sReal%20Village%20Roma!5e0!3m2!1sit!2sit!4v1658390377850!5m2!1sit!2sit" width="100%" height="400" style={{ border: '0' }} loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
                    </div>
                    <div className="col-lg">
                        <iframe title="heaven" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2967.5852376558787!2d12.442419015403996!3d41.94476307921694!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x132f61fbf32ebd0b%3A0xffa8370b86ef64d7!2sHeaven%20Sporting%20Club%20-%20Cortina!5e0!3m2!1sit!2sit!4v1658390514187!5m2!1sit!2sit" width="100%" height="400" style={{ border: '0' }} loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
                    </div>
                </div>
            </div>



        </Fragment>
    );
}
