import { Fragment, useState } from "react";
import { AddEventoMaster, DeleteEventoMaster, EditEventoMaster } from "../../rest/api";


export default function FormEvento({ dati, piscine, circuiti, nomiEventi, onUpdateEvento }) {
    console.log(dati);
    const [idevento, setIdevento] = useState(dati?.idevento ?? -1);
    const [nome, setNome] = useState(dati?.nome ?? '');
    const [scadenza, setScadenza] = useState(dati?.scadenza ?? new Date().toISOString().slice(0, 10));
    const [idpiscina, setIdpiscina] = useState(dati?.idpiscina ?? 1);
    const [visibile, setVisibile] = useState(dati?.visibile == true ? true : false);
    const [note, setNote] = useState(dati?.note ?? '');
    const [idcircuito, setIdcircuito] = useState(dati?.idcircuito ?? 1);
    const [vasca, setVasca] = useState(dati?.vasca ?? 25);

    const [isLoading, setIsLoading] = useState(false);
    const [messaggio, setMessaggio] = useState();
    const [errore, setErrore] = useState();

    const editEvento = e => {
        e.preventDefault();

        const evento_update = {
            idevento: idevento,
            nome: nome !== '' ? nome : null,
            scadenza: scadenza,
            idpiscina: idpiscina,
            visibile: visibile,
            note: note !== '' ? note : null,
            idcircuito: idcircuito,
            vasca: vasca
        }

        console.log(evento_update);
        setErrore(null);
        setMessaggio(null);
        setIsLoading(true);
        if (idevento > 0) {
            EditEventoMaster(evento_update)
                .then(resp => {
                    setMessaggio('Evento modificato correttamente');
                    onUpdateEvento(idevento, 'edit');
                })
                .catch(err => setErrore(err))
                .finally(() => setIsLoading(false));
        } else {
            AddEventoMaster(evento_update)
                .then(resp => {
                    setIdevento(resp.data.idevento);
                    setMessaggio('Evento creato correttamente');
                    onUpdateEvento(resp.data.idevento, 'new');
                })
                .catch(err => setErrore(err))
                .finally(() => setIsLoading(false));
        }

    }

    const deleteEvento = e => {
        e.preventDefault();
        const evento_deleted = {
            idevento: idevento
        }
        console.log(evento_deleted)
        DeleteEventoMaster(evento_deleted)
            .then(resp => {
                console.log('Evento eliminato')
                onUpdateEvento(evento_deleted, 'delete');
            })
            .catch(err => setErrore(err))
    }

    return (
        <Fragment>
            {messaggio && (
                <div className="alert alert-success mb-1 py-1 shadow-sm fw-bold" role="alert" style={{ fontSize: '1rem' }}>
                    {messaggio}
                </div>
            )
            }
            {errore && (
                <div className="alert alert-danger mb-1 py-1 shadow-sm fw-bold" role="alert" style={{ fontSize: '1rem' }}>
                    {errore}
                </div>
            )
            }
            <form onSubmit={editEvento}>
                <div className="mb-3">
                    <label htmlFor="exampleDataList" className="form-label">Nome</label>
                    <input value={nome} onChange={e => setNome(e.target.value)} className="form-control form-control-lg" list="datalistOptions" id="exampleDataList" placeholder="Inserisci il nome dell'evento" />
                    <datalist id="datalistOptions">
                        {nomiEventi.map(e => (
                            <option key={e} value={e}></option>
                        ))}
                    </datalist>
                </div>

                <div className="row">
                    <div className="col-lg mb-3">
                        <label className="w-100 mb-0" style={{ fontSize: '1rem' }}>Piscina</label>
                        <select value={idpiscina} onChange={e => setIdpiscina(parseInt(e.target.value))} className="form-select form-select-lg">
                            {piscine.map(x => (
                                <option key={x.idpiscina} value={x.idpiscina}>{x.nome}</option>
                            ))}
                        </select>
                    </div>
                    <div className="col-lg mb-3">
                        <label className="w-100 mb-0" style={{ fontSize: '1rem' }}>Vasca</label>
                        <select value={vasca} onChange={e => setVasca(parseInt(e.target.value))} className="form-select form-select-lg">
                            <option value="25">25m</option>
                            <option value="50">50m</option>
                        </select>
                    </div>
                </div>

                <div className="row">
                    <div className="col-lg mb-3">
                        <label htmlFor="scadenza" className="w-100 mb-0" style={{ fontSize: '1rem' }}>Scadenza</label>
                        <input type="date" value={scadenza} onChange={e => setScadenza(e.target.value)} className="form-control form-control-lg" placeholder="Inserisci la data di scadenza" name="scadenza" id="scadenza" required />
                    </div>
                    <div className="col-lg mb-3">
                        <label className="w-100 mb-0" style={{ fontSize: '1rem' }}>Circuito</label>
                        <select value={idcircuito} onChange={e => setIdcircuito(parseInt(e.target.value))} className="form-select form-select-lg">
                            {circuiti.map(x => (
                                <option key={x.idcircuito} value={x.idcircuito}>{x.nome}</option>
                            ))}
                        </select>
                    </div>
                </div>
                <div className="mb-3">
                    <input className="form-check-input me-1" type="checkbox" checked={visibile} onChange={e => setVisibile(e.target.checked)} id="visibile" />
                    <label className="form-check-label" htmlFor="visibile" style={{ fontSize: '1rem' }}>
                        Visibile
                    </label>
                </div>
                <div className="form-group mb-3">
                    <label htmlFor="note" style={{ fontSize: '1rem' }}>Note</label>
                    <textarea rows="3" value={note} onChange={e => setNote(e.target.value)} className="form-control form-control-lg" name="note" id="note"></textarea>
                </div>
                <div className="d-flex">
                    <button type="submit" disabled={isLoading} className="btn btn-lg btn-success w-100" style={{ borderRadius: '0' }}>
                        Salva
                    </button>
                    {idevento > 0 && (
                        <button onClick={deleteEvento} disabled={isLoading || dati?.iscrizioni > 0} className="btn btn-lg btn-danger w-100" style={{ borderRadius: '0' }}>
                            Elimina
                        </button>
                    )}
                </div>
            </form>
        </Fragment>
    );
}
