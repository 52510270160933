import { Fragment, useEffect, useState } from "react";
import useScrollTop from "../../hooks/useScrollTop";
import useTitle from "../../hooks/useTitle";
import Copertina from "../common/Copertina";
import { GetArticolo } from "../../rest/api";
import '../../css/articoli.css';
import Caricamento from "../common/Caricamento";
import ContentArticolo from "./ContentArticolo";
import { useParams } from "react-router";

export default function ArticoloPage() {
    const params = useParams();
    const [articolo, setArticolo] = useState();
    useTitle(articolo ? articolo.Titolo : 'Articolo');
    useScrollTop();

    useEffect(() => {
        let doUpdate = true;
        GetArticolo(params.idarticolo)
            .then(result => {
                if (doUpdate) {
                    setArticolo(result.data);
                }
                console.log(result.data);
            })
        return () => doUpdate = false;
    }, [params.idarticolo])

    return (
        <Fragment>
            <Copertina
                // titolo="Articoli"
                // sottotitolo="Area riservata agli atleti della squadra master ASD Aquareal"
                // immagine="cover-login.jpg"
                sfondo="cover-news.jpg"
            />
            <div className='mx-3'>
                <div className="mx-auto" style={{ maxWidth: '1000px', marginTop: 'max(8%, 3rem)', marginBottom:'-5rem' }}>
                    {!articolo && <Caricamento messaggio="Caricamento articolo in corso" />}
                    {articolo && <ContentArticolo articolo={articolo} />}
                </div>
            </div>
        </Fragment>
    );
}
