import { colorCircuito, iconaGara, printData, printTempo } from "../../utility";
import { Link } from "react-router-dom";
import { Fragment, useState } from "react";
import { BsSearch, BsQuestionCircleFill } from 'react-icons/bs';
import Riepilogo from "../common/Riepilogo";

export default function TabellaProgressioneAtletaMaster({ dati, recordSocietari }) {
    const [selezionato, setSelezionato] = useState(null);
    const gare = Array.from(new Set(dati.map(x => x.gara)));
    console.log(gare);

    if (dati.length === 0) {
        return (
            <div className="text-center my-5">
                <h3>Non ci sono tempi da mostrare</h3>
            </div>
        )
    }

    return (
        <Fragment>
            {gare.map(g => {
                return (
                    <div key={g}>
                        <h2 className="mt-5 fw-bold">
                            <img src={process.env.PUBLIC_URL + '/assets/' + iconaGara(g)} className="img-fluid me-2" style={{ height: '2.8rem', position: 'relative', top: '-2px' }} alt="" />
                            {g}
                        </h2>
                        <table className="w-100 shadow" style={{ fontSize: '1.1rem', lineHeight: '1.2' }}>
                            <thead>
                                <tr className="fw-bold">
                                    <th className="text-center py-1" style={{ width: '5rem' }}>CAT</th>
                                    <th className="py-1 d-none d-sm-table-cell" style={{ width: '3rem' }}></th>
                                    <th className="ps-2 py-1">EVENTO</th>
                                    <th className="text-center py-1 d-none d-sm-table-cell" style={{ width: '10rem' }}>DATA</th>
                                    <th className="text-end py-1 pe-2" style={{ width: '7rem' }}>TEMPO</th>
                                    <th className="text-center py-1 d-none d-md-table-cell" style={{ width: '5rem' }}>POS</th>
                                    <th className="text-center py-1 d-none d-md-table-cell" style={{ width: '5rem' }}>PUNTI</th>
                                    <th className="py-1 d-table-cell d-md-none" style={{ width: '2rem' }}></th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    dati
                                        .filter(x => x.gara === g)
                                        .map(x => {
                                            const is_societario = recordSocietari && recordSocietari.findIndex(t => t.idtempo === x.idtempo) !== -1;
                                            return (
                                                <tr key={g + x.categoria} className={is_societario ? 'row-societario' : ''}>
                                                    <td className="text-center px-2 py-1">
                                                        {x.categoria.replace('-', '/')}
                                                    </td>
                                                    <td className="py-1 text-end d-none d-sm-table-cell">
                                                        <span className="badge w-100 shadow" style={{ backgroundColor: colorCircuito(x.circuito), fontWeight: 'normal' }}>
                                                            {x.circuito.slice(0, 4).toUpperCase()}
                                                        </span>
                                                    </td>
                                                    <td className="ps-2 py-1">
                                                        {x.evento_visibile ?
                                                            <Link to={`/master/eventi/${x.idevento}`}>{x.evento} <span className="d-none d-sm-inline"> ({x.piscina})</span></Link> :
                                                            <Fragment>
                                                                {x.evento} <span className="d-none d-lg-inline"> ({x.piscina})</span>
                                                            </Fragment>
                                                        }
                                                    </td>
                                                    <td className="text-center px-2 py-1 d-none d-sm-table-cell">
                                                        {printData(x.data)}
                                                    </td>
                                                    <td className="text-end py-1 pe-2">
                                                        {x.is_frazione ? <BsQuestionCircleFill className="position-relative" style={{ top: '-2px' }} title="Record realizzato in staffetta" /> : null} {printTempo(x.record, false)}
                                                    </td>
                                                    <td className="text-center py-1 d-none d-md-table-cell">
                                                        {x.posizione === 1 && (<img src={process.env.PUBLIC_URL + '/assets/medal1.gif'} className="img-fluid mx-2" style={{ height: '1.3rem' }} alt="" />)}
                                                        {x.posizione === 2 && (<img src={process.env.PUBLIC_URL + '/assets/medal2.gif'} className="img-fluid mx-2" style={{ height: '1.3rem' }} alt="" />)}
                                                        {x.posizione === 3 && (<img src={process.env.PUBLIC_URL + '/assets/medal3.gif'} className="img-fluid mx-2" style={{ height: '1.3rem' }} alt="" />)}
                                                        {x.posizione > 3 && x.posizione}
                                                        {!x.posizione && '-'}
                                                    </td>
                                                    <td className="text-center py-1 d-none d-md-table-cell">
                                                        {x.punti ?? '-'}
                                                    </td>
                                                    <td className="text-center py-1 d-table-cell d-md-none">
                                                        <button onClick={() => setSelezionato(x)} className="btn btn-dark px-1 py-0">
                                                            <BsSearch />
                                                        </button>
                                                    </td>
                                                </tr>
                                            )
                                        })
                                }
                            </tbody>
                        </table>
                    </div>
                )
            })}
            {selezionato && <Riepilogo dati={
                {
                    gara: selezionato.gara,
                    evento: selezionato.evento,
                    piscina: selezionato.piscina,
                    circuito: selezionato.circuito,
                    data: printData(selezionato.data) + ' ' + (selezionato.sessione === 'M' ? 'Mattina' : 'Pomeriggio'),
                    vasca: selezionato.vasca + 'm',
                    categoria: selezionato.categoria,
                    record: printTempo(selezionato.record),
                    posizione: selezionato.posizione,
                    punti: selezionato.punti
                }
            } titolo={'Tempo'} onClose={() => setSelezionato(null)} />}
        </Fragment>
    );
}
