import '../../css/articoli.css';
import Pagination from 'react-bootstrap/Pagination';

export default function PaginazioneArticoli({ size, pagina, per_page, onChangePage }) {

    const numero_pagine = Math.ceil(size / per_page);
    console.log('Numero pagine: ' + numero_pagine);
    if (numero_pagine === 1)
        return null;
        
    return (

        <Pagination className="justify-content-end">
            {Array.from({ length: numero_pagine }, (v, i) => i + 1)
                .map(x => {
                    if (x !== pagina)
                        return <Pagination.Item key={x} onClick={() => onChangePage(x)}>{x}</Pagination.Item>;
                    else
                        return <Pagination.Item key={x} active onClick={() => onChangePage(x)}>{x}</Pagination.Item>;
                })}
        </Pagination>

    );
}
