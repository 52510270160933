import { printDataTime } from "../../utility";
import { BsEyeFill, BsEyeSlashFill } from 'react-icons/bs';
import { useNavigate } from "react-router-dom";

export default function TabellaGestioneArticoli({ dati }) {
    const navigate = useNavigate();

    return (
        <table className="w-100 shadow" style={{ fontSize: '1.1rem', lineHeight: '1.2' }}>
            <thead>
                <tr className="fw-bold">
                    <th className="py-1 text-end" style={{ width: '3rem' }}>ID</th>
                    <th className="text-center py-1" style={{ width: '2rem' }}></th>
                    <th className="ps-2 py-1">TITOLO</th>
                    <th className="py-1 d-none d-sm-table-cell" style={{ width: '18rem' }}>SOTTOTITOLO</th>
                    <th className="text-center py-1 d-none d-xl-table-cell" style={{ width: '8rem' }}>COPERTINA</th>
                    <th className="text-center py-1 d-none d-lg-table-cell" style={{ width: '10rem' }}>CREAZIONE</th>
                    <th className="text-center py-1 d-none d-md-table-cell" style={{ width: '10rem' }}>MODIFICA</th>
                    <th className="text-center py-1" style={{ width: '2.5rem' }}></th>
                </tr>
            </thead>
            <tbody>
                {
                    dati.map(x => {
                        return (
                            <tr key={x.idarticolo}>
                                <td className="py-1 text-end">
                                    {x.idarticolo}
                                </td>
                                <td className="text-center py-1">
                                    {x.visibile == true && (<BsEyeFill style={{ color: 'forestgreen' }} />)}
                                    {x.visibile == false && (<BsEyeSlashFill style={{ color: 'crimson' }} />)}
                                </td>
                                <td className="ps-2 py-1">
                                    {x.titolo}
                                </td>
                                <td className="py-1 d-none d-sm-table-cell">
                                    {x.sottotitolo}
                                </td>
                                <td className="text-center py-1 d-none d-xl-table-cell">
                                    {x.copertina}
                                </td>                                
                                <td className="text-center py-1 d-none d-lg-table-cell">
                                    {printDataTime(x.creazione, 2)}
                                </td>
                                <td className="text-center py-1 d-none d-md-table-cell">
                                    {printDataTime(x.modifica, 2)}
                                </td>
                                <td className="text-center py-1">
                                    <button
                                        onClick={() => navigate('/master/gestione/articoli/'+x.idarticolo)}
                                        className="btn btn-primary px-2 py-0">
                                        ...
                                    </button>
                                </td>
                            </tr>
                        )
                    })
                }
            </tbody>
        </table>
    )

}
