import { Fragment } from "react";
import { useNavigate } from "react-router-dom";
import { printData, printDataTime } from "../../utility";
import '../../css/articoli.css';

export default function ContentArticolo({ articolo }) {
    const navigate = useNavigate();
    if (!articolo)
        return null;

    return (
        <div id="articolo" className='pannello shadow position-relative px-3 py-4 p-lg-5' style={{ borderTop: '4px solid', top: '-10rem' }}>
            <h1 className="mb-1">{articolo.titolo}</h1>
            <h4>{printData(articolo.data)} | {articolo.sottotitolo}</h4>
            {/* <p className="mt-3 pt-2" style={{ color: 'gray', borderTop: '1px solid', lineHeight: '1.2' }}>
                Data: {printData(articolo.data)}
            </p> */}
            <img
                src={`${process.env.PUBLIC_URL + '/assets/articoli/' + articolo.copertina}`}
                className="img-fluid mt-3 mb-3 mb-md-5"
                alt=''
            />
            <p dangerouslySetInnerHTML={{ __html: articolo.testo }}></p>
            <button className="btn btn-dark mt-5 d-block ms-auto" onClick={()=> navigate('/articoli')}>
                Torna agli articoli
            </button>
        </div>
    );
}
