import { Fragment, useEffect, useState } from "react";
import useScrollTop from "../../hooks/useScrollTop";
import useTitle from "../../hooks/useTitle";
import { GetEventiMaster } from "../../rest/api";
import Caricamento from "../common/Caricamento";
import Copertina from "../common/Copertina";
import TabellaEventiMaster from "./TabellaEventiMaster";

export default function EventiMasterPage() {
    const [stagione, setStagione] = useState();
    const [eventi, setEventi] = useState();
    const [selezionato, setSelezionato] = useState();
    useTitle('Eventi Master')
    useScrollTop();

    useEffect(() => {
        let doUpdate = true;
        GetEventiMaster()
            .then(result => {
                if (doUpdate) {
                    setEventi(result.data);
                    setStagione(result.data.map(x => x.stagione)[0])
                }
            })
        return () => doUpdate = false;
    }, [])


    console.log(eventi)
    console.log(stagione)

    return (
        <Fragment>
            <Copertina
                titolo="Eventi Master"
                sottotitolo="Gli eventi sono raggruppati per stagione. Clicca sul nome per aprire la scheda con i dettagli."
                // immagine="cover-login.jpg"
                sfondo="sfondo-eventi.jpg"
            />

            {(!eventi || !stagione) && <Caricamento messaggio="Caricamento degli eventi in corso" />}

            {
                eventi?.map(x => x.stagione).map(s => {
                    const eventi_stagione = eventi.find(x => x.stagione === s).eventi;
                    const ori_tot = eventi_stagione.reduce((partialSum, a) => partialSum + a.ori, 0);
                    const arg_tot = eventi_stagione.reduce((partialSum, a) => partialSum + a.argenti, 0);
                    const bro_tot = eventi_stagione.reduce((partialSum, a) => partialSum + a.bronzi, 0);
                    const gare_tot = eventi_stagione.reduce((partialSum, a) => partialSum + a.iscrizioni_individuali + a.staffette, 0);
                    const medaglie_tot = ori_tot + arg_tot + bro_tot;

                    return (
                        <div key={s} className="container-lg mx-auto" style={{maxWidth: '1100px', marginTop: 'max(8%, 3rem)', marginBottom: 'max(8%, 3rem)' }}>
                            <div className="row text-center">
                                <div className="col">
                                    <h2 className="fw-bold mb-1">
                                        Stagione {s.replace('-', '/')}
                                    </h2>
                                    <p className="mb-1" style={{ fontStyle: 'italic' }}>
                                        {medaglie_tot} medagli{medaglie_tot !== 1 ? 'e' : 'a'} totali in {gare_tot} gar{gare_tot !== 1 ? 'e' : 'a'} {medaglie_tot > 0 ? ' - 1 medaglia ogni ' + Math.round(gare_tot / medaglie_tot * 10) / 10 + ' gare' : ''}
                                    </p>
                                    <p style={{ fontSize: '1.4rem' }}>
                                        {ori_tot > 0 && (
                                            <Fragment>
                                                <img src={process.env.PUBLIC_URL + '/assets/medal1.gif'} className="img-fluid mx-2" style={{ width: '1.5rem' }} alt="" />
                                                {ori_tot}
                                            </Fragment>
                                        )}
                                        {arg_tot > 0 && (
                                            <Fragment>
                                                <img src={process.env.PUBLIC_URL + '/assets/medal2.gif'} className="img-fluid mx-2" style={{ width: '1.5rem' }} alt="" />
                                                {arg_tot}
                                            </Fragment>
                                        )}
                                        {bro_tot > 0 && (
                                            <Fragment>
                                                <img src={process.env.PUBLIC_URL + '/assets/medal3.gif'} className="img-fluid mx-2" style={{ width: '1.5rem' }} alt="" />
                                                {bro_tot}
                                            </Fragment>
                                        )}
                                    </p>

                                </div>
                            </div>
                            <div className="row">
                                <div className="col">
                                    <TabellaEventiMaster eventi={eventi_stagione} stagione={s} />
                                </div>
                            </div>
                        </div>
                    )
                })
            }



        </Fragment >
    );
}
