import { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { useUser } from "../../hooks/UserContext";
import '../../css/menu-secondario.css';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';


export default function MenuAtleta({ idatleta }) {
    const [expanded, setExpanded] = useState(false);
    const [user, setUser] = useUser();

    const location = useLocation();
    const [url, setUrl] = useState(null);
    useEffect(() => {
        setUrl(location.pathname);
    }, [location]);

    const closeNavbar = () => {
        setExpanded(false);
    };

    return (
        <Navbar collapseOnSelect={true} expanded={expanded} expand="lg" bg="light" variant="light" sticky="top" className="menu-secondario shadow py-0" style={{ lineHeight: '1.8', fontSize: '1.1rem' }}>
            <Container>
                <Navbar.Brand className="ps-0 py-2 py-lg-0">Menù Atleta</Navbar.Brand>
                <Navbar.Toggle onClick={() => setExpanded(expanded ? false : "expanded")} />
                <Navbar.Collapse id="responsive-navbar-nav">
                    <Nav className="ms-auto pb-3 pb-lg-0">
                        <Nav.Link as={Link} onClick={closeNavbar} to={`/master/atleti/${idatleta}/record`} className={(url?.endsWith('record') ? "subnav-attivo py-1" : 'py-1')}>Record</Nav.Link>
                        <Nav.Link as={Link} onClick={closeNavbar} to={`/master/atleti/${idatleta}/puntimax`} className={(url?.endsWith('puntimax') ? "subnav-attivo py-1" : 'py-1')}>Punteggi massimi</Nav.Link>
                        <Nav.Link as={Link} onClick={closeNavbar} to={`/master/atleti/${idatleta}/tempi`} className={(url?.endsWith('tempi') ? "subnav-attivo py-1" : 'py-1')}>Tempi</Nav.Link>
                        <Nav.Link as={Link} onClick={closeNavbar} to={`/master/atleti/${idatleta}/progressione`} className={(url?.endsWith('progressione') ? "subnav-attivo py-1" : 'py-1')}>Progressione</Nav.Link>
                        <Nav.Link as={Link} onClick={closeNavbar} to={`/master/atleti/${idatleta}/medagliere`} className={(url?.endsWith('medagliere') ? "subnav-attivo py-1" : 'py-1')}>Medagliere</Nav.Link>
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    );
}
