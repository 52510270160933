import { Fragment, useState } from "react";
import { AddPiscinaMaster, DeletePiscinaMaster, EditPiscinaMaster } from "../../rest/api";


export default function FormPiscina({ dati, onUpdatePiscina }) {
    console.log(dati);
    if (!dati || dati.idprogramma < 0) {
        dati = {
            idpiscina: -1,
            indirizzo: '',
            latitudine: '',
            longitudine: '',
            zoom: '',
            note: ''
        }
    }

    const [idpiscina, setIdpiscina] = useState(dati.idpiscina);
    const [nome, setNome] = useState(dati.nome);
    const [indirizzo, setIndirizzo] = useState(dati.indirizzo);
    const [latitudine, setLatitudine] = useState(dati.latitudine);
    const [longitudine, setLongitudine] = useState(dati.longitudine);
    const [zoom, setZoom] = useState(dati.zoom);
    const [note, setNote] = useState(dati?.note ?? '');

    const [isLoading, setIsLoading] = useState(false);
    const [messaggio, setMessaggio] = useState();
    const [errore, setErrore] = useState();

    const editPiscina = e => {
        e.preventDefault();

        const piscina_update = {
            idpiscina: idpiscina,
            nome: nome !== '' ? nome : null,
            indirizzo: indirizzo !== '' ? indirizzo : null,
            latitudine: latitudine !== '' ? latitudine : null,
            longitudine: longitudine !== '' ? longitudine : null,
            zoom: zoom !== '' ? zoom : null,
            note: note !== '' ? note : null
        }

        console.log(piscina_update);
        setErrore(null);
        setMessaggio(null);
        setIsLoading(true);
        if (idpiscina > 0) {
            EditPiscinaMaster(piscina_update)
                .then(resp => {
                    setMessaggio('Piscina modificata correttamente');
                    onUpdatePiscina(idpiscina, 'edit');
                })
                .catch(err => setErrore(err))
                .finally(() => setIsLoading(false));
        } else {
            AddPiscinaMaster(piscina_update)
                .then(resp => {
                    setIdpiscina(resp.data.idpiscina);
                    setMessaggio('Piscina creata correttamente');
                    onUpdatePiscina(resp.data.idpiscina, 'new');
                })
                .catch(err => setErrore(err))
                .finally(() => setIsLoading(false));
        }

    }

    const deletePiscina = e => {
        e.preventDefault();
        const piscina_deleted = {
            idpiscina: idpiscina
        }
        console.log(piscina_deleted)
        DeletePiscinaMaster(piscina_deleted)
            .then(resp => {
                console.log('Piscina eliminata')
                onUpdatePiscina(piscina_deleted, 'delete');
            })
            .catch(err => setErrore(err))
    }

    return (
        <Fragment>
            {messaggio && (
                <div className="alert alert-success mb-1 py-1 shadow-sm fw-bold" role="alert" style={{ fontSize: '1rem' }}>
                    {messaggio}
                </div>
            )
            }
            {errore && (
                <div className="alert alert-danger mb-1 py-1 shadow-sm fw-bold" role="alert" style={{ fontSize: '1rem' }}>
                    {errore}
                </div>
            )
            }
            <form onSubmit={editPiscina}>
                <div className="mb-3">
                    <label htmlFor="nome" className="form-label mb-0" style={{ fontSize: '1rem' }}>Nome</label>
                    <input type="text" value={nome} onChange={e => setNome(e.target.value)} className="form-control form-control-lg" id="nome" placeholder="Inserisci il nome della piscina" />
                </div>
                <div className="mb-3">
                    <label htmlFor="indirizzo" className="form-label mb-0" style={{ fontSize: '1rem' }}>Indirizzo</label>
                    <input type="text" value={indirizzo} onChange={e => setIndirizzo(e.target.value)} className="form-control form-control-lg" id="nome" placeholder="Inserisci l'indirizzo" />
                </div>
                <div className="row">
                    <div className="col-lg mb-3">
                        <label htmlFor={'latitudine'} className="w-100 mb-0" style={{ fontSize: '1rem' }}>Latitudine</label>
                        <input type="number" value={latitudine} onChange={e => setLatitudine(parseFloat(e.target.value))} step="any" className="form-control form-control-lg" placeholder="Inserisci la latitudine" name={'frazione4'} id={'frazione4'} min="0" />
                    </div>
                    <div className="col-lg mb-3">
                        <label htmlFor={'longitudine'} className="w-100 mb-0" style={{ fontSize: '1rem' }}>Longitudine</label>
                        <input type="number" value={longitudine} onChange={e => setLongitudine(parseFloat(e.target.value))} step="any" className="form-control form-control-lg" placeholder="Inserisci la longitudine" name={'frazione4'} id={'frazione4'} min="0" />
                    </div>
                    <div className="col-lg mb-3">
                        <label htmlFor={'zoom'} className="w-100 mb-0" style={{ fontSize: '1rem' }}>Zoom</label>
                        <input type="number" value={zoom} onChange={e => setZoom(parseFloat(e.target.value))} step="any" className="form-control form-control-lg" placeholder="Inserisci lo zoom" name={'frazione4'} id={'frazione4'} min="0" />
                    </div>
                </div>                
                <div className="form-group mb-3">
                    <label htmlFor="note" style={{ fontSize: '1rem' }}>Note</label>
                    <textarea rows="3" value={note} onChange={e => setNote(e.target.value)} className="form-control form-control-lg" name="note" id="note"></textarea>
                </div>
                <div className="d-flex">
                    <button type="submit" disabled={isLoading} className="btn btn-lg btn-success w-100" style={{ borderRadius: '0' }}>
                        Salva
                    </button>
                    {idpiscina > 0 && (
                        <button onClick={deletePiscina} disabled={isLoading} className="btn btn-lg btn-danger w-100" style={{ borderRadius: '0' }}>
                            Elimina
                        </button>
                    )}
                </div>
            </form>
        </Fragment>
    );
}
