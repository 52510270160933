import { Fragment, useState } from "react";
import { useParams } from "react-router";
import { AddProgrammaMaster, DeleteProgrammaMaster, EditProgrammaMaster } from "../../rest/api";


export default function FormProgramma({ dati, gare, maxdata, onUpdateProgramma }) {
    console.log(dati);
    if (!dati || dati.idprogramma < 0) {
        dati = {
            idprogramma: -1,
            idgara: -1,
            data: maxdata ? maxdata : new Date().toISOString().slice(0, 10),
            sessione: 'M',
            note: ''
        }
    }
    const params = useParams();
    const [idprogramma, setIdprogramma] = useState(dati.idprogramma);
    const [idgara, setIdgara] = useState(dati.idgara);
    const [data, setData] = useState(dati.data);
    const [sessione, setSessione] = useState(dati.sessione);
    const [note, setNote] = useState(dati.note_programma);
    const [forzaDuplicati, setForzaDuplicati] = useState(false);

    const [isLoading, setIsLoading] = useState(false);
    const [messaggio, setMessaggio] = useState();
    const [errore, setErrore] = useState();

    const editProgramma = e => {
        e.preventDefault();

        if (idgara < 0) {
            setErrore('Nessuna gara selezionata');
            return;
        }
        const programma_update = {
            idprogramma: idprogramma,
            idgara: idgara,
            idevento: parseInt(params.idevento),
            data: data,
            sessione: sessione,
            note: note !== '' ? note : null,
            forza_duplicati: forzaDuplicati
        }

        console.log(programma_update);

        setErrore(null);
        setMessaggio(null);
        setIsLoading(true);
        if (idprogramma > 0) {
            EditProgrammaMaster(programma_update)
                .then(resp => {
                    setMessaggio('Programma modificato correttamente');
                    onUpdateProgramma(idprogramma, 'edit');
                })
                .catch(err => setErrore(err))
                .finally(() => setIsLoading(false));
        } else {
            AddProgrammaMaster(programma_update)
                .then(resp => {
                    setIdprogramma(resp.data.idprogramma);
                    setMessaggio('Programma creato correttamente');
                    onUpdateProgramma(resp.data.idprogramma, 'new');
                })
                .catch(err => setErrore(err))
                .finally(() => setIsLoading(false));
        }

    }

    const deleteProgramma = e => {
        e.preventDefault();
        const programma_deleted = {
            idprogramma: idprogramma
        }
        console.log(programma_deleted)
        DeleteProgrammaMaster(programma_deleted)
            .then(resp => {
                console.log('Programma eliminato')
                onUpdateProgramma(programma_deleted, 'delete');
            })
            .catch(err => setErrore(err))
    }

    return (
        <Fragment>
            {messaggio && (
                <div className="alert alert-success mb-1 py-1 shadow-sm fw-bold" role="alert" style={{ fontSize: '1rem' }}>
                    {messaggio}
                </div>
            )
            }
            {errore && (
                <div className="alert alert-danger mb-1 py-1 shadow-sm fw-bold" role="alert" style={{ fontSize: '1rem' }}>
                    {errore}
                </div>
            )
            }
            <form onSubmit={editProgramma}>
                <div className="mb-3">
                    <label className="w-100 mb-0" style={{ fontSize: '1rem' }}>Gara</label>
                    <select value={idgara} onChange={e => setIdgara(parseInt(e.target.value))} className="form-select form-select-lg">
                        <option value={-1} disabled={true}>Nessuna selezione</option>
                        {gare.map(x => (
                            <option key={x.idgara} value={x.idgara}>{x.nome}</option>
                        ))}
                    </select>
                </div>
                <div className="row">
                    <div className="col-lg mb-3">
                        <label htmlFor="giorno" className="w-100 mb-0" style={{ fontSize: '1rem' }}>Giorno</label>
                        <input type="date" value={data} onChange={e => setData(e.target.value)} className="form-control form-control-lg" placeholder="Inserisci la data della gara" name="giorno" id="giorno" required />
                    </div>
                    <div className="col-lg mb-3">
                        <label className="w-100 mb-0" style={{ fontSize: '1rem' }}>Sessione</label>
                        <select value={sessione} onChange={e => setSessione(e.target.value)} className="form-select form-select-lg">
                            <option value="M">Mattina</option>
                            <option value="P">Pomeriggio</option>
                        </select>
                    </div>
                </div>
                <div className="form-group mb-3">
                    <input className="form-check-input me-1" type="checkbox" checked={forzaDuplicati} onChange={e => setForzaDuplicati(e.target.checked)} id="forza" />
                    <label className="form-check-label ms-1" htmlFor="forza" style={{ fontSize: '1rem' }}>
                        Forza duplicati
                    </label>
                </div>
                <div className="form-group mb-3">
                    <label htmlFor="note" style={{ fontSize: '1rem' }}>Note</label>
                    <textarea rows="3" value={note} onChange={e => setNote(e.target.value)} className="form-control form-control-lg" name="note" id="note"></textarea>
                </div>
                <div className="d-flex">
                    <button type="submit" disabled={isLoading} className="btn btn-lg btn-success w-100" style={{ borderRadius: '0' }}>
                        Salva
                    </button>
                    {idprogramma > 0 && (
                        <button onClick={deleteProgramma} disabled={isLoading || dati?.iscrizioni?.length > 0} className="btn btn-lg btn-danger w-100" style={{ borderRadius: '0' }}>
                            Elimina
                        </button>
                    )}
                </div>
            </form>
        </Fragment>
    );
}
