import { Fragment, useEffect, useState } from "react";
import { useUser } from "../../hooks/UserContext";
import useScrollTop from "../../hooks/useScrollTop";
import useTitle from "../../hooks/useTitle";
import { GetAnagraficaTrofei, GetCircuiti } from "../../rest/api";
import Caricamento from "../common/Caricamento";
import Modal from 'react-bootstrap/Modal';
import TabellaGestioneTrofei from "./TabellaGestioneTrofei";
import FormTrofeo from "./FormTrofeo";


export default function GestioneTrofeiPage() {
    const [circuiti, setCircuiti] = useState();
    const [trofei, setTrofei] = useState();
    const [idtrofeoSelezionato, setIdtrofeoSelezionato] = useState(-1);
    const [counterModifiche, setCounterModifiche] = useState(0);
    const [show, setShow] = useState(false);

    useTitle('Gestione Trofei')
    useScrollTop();
    const [user] = useUser();

    useEffect(() => {
        let doUpdate = true;
        GetAnagraficaTrofei()
            .then(result => {
                if (doUpdate)
                    setTrofei(result.data);
            })
        return () => doUpdate = false;
    }, [counterModifiche])

    useEffect(() => {
        let doUpdate = true;
        GetCircuiti()
            .then(result => {
                if (doUpdate)
                    setCircuiti(result.data);
            })
        return () => doUpdate = false;
    }, [])

    const selectTrofeo = a => {
        console.log(a)
        console.log('seleziono trofeo ' + a);
        setIdtrofeoSelezionato(a);
        setShow(true);
    };

    const aggiornaTrofei = (idtrofeo, azione) => {
        if (azione === 'new') {
            setIdtrofeoSelezionato(idtrofeo);
        }
        else if (azione === 'delete') {
            setShow(false);
            setIdtrofeoSelezionato(-1);
        }
        setCounterModifiche(val => val + 1);
        console.log(idtrofeo)
        console.log(azione.toUpperCase());
    };

    const trofeoSelezionato = trofei?.find(x => x.idtrofeo === idtrofeoSelezionato);

    console.log(trofei)
    return (
        <Fragment>
            {!trofei && <Caricamento messaggio="Caricamento trofei in corso" />}
            {trofei && circuiti && (
                <Fragment>
                    <button className="btn mb-5 w-100 btn-lg btn-dark" onClick={() => {
                        setIdtrofeoSelezionato(-1);
                        setShow(true);
                    }}>Nuovo trofeo</button>

                    <h2 className="text-center mb-4">Trofei</h2>
                    <TabellaGestioneTrofei dati={trofei} onSelectTrofeo={selectTrofeo} />

                    <Modal
                        show={show}
                        onHide={() => setShow(false)}
                        scrollable
                        backdrop="static"
                        keyboard={false}
                        size="lg"
                    >
                        <Modal.Header closeButton closeVariant='white' style={{ backgroundColor: 'rgba(0,0,0,0.85)', color: 'white' }}>
                            <Modal.Title className="w-100 text-center">
                                {trofeoSelezionato ? `Modifica Trofeo ${trofeoSelezionato.idtrofeo}` : `Creazione Trofeo`}
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <FormTrofeo dati={trofeoSelezionato} circuiti={circuiti} onUpdateTrofeo={aggiornaTrofei} />
                        </Modal.Body>
                    </Modal>
                </Fragment>
            )
            }
        </Fragment>
    );
}
